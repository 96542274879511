/**
 * We do this to get a copy of moment w/timezones without polluting the global scope
 */
let moment = require('moment');

export default moment;

// moment-timezone makes changes to existing moment object, returning adjusted
moment = require('moment-timezone');

// in case you need the raw moment
// export const moment;

export const currentTimezone = moment.tz.guess();
/**
 * This will create a 'moment' object that *is* moment.tz(), and automatically use the
 * 'timezone' used when you called 'getMoment()'
 */
export const getMoment = (timezone = currentTimezone) => {
  const m = moment.tz.setDefault(timezone);
  m.localeData = moment.localeData;
  return m;
};

/**
 * 'datetime' is a JS Date object
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const convertDateTimeToDate = (datetime, tzMoment) => {
  return new Date(tzMoment(datetime).format()); // sets Date using ISO 8601 format
};

/**
 * 'hour' is an integer from 0 - 23 specifying the hour to set on the Date
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getTimeAsDate = (hour, tzMoment) => {
  const m = tzMoment('1970-01-01');
  return new Date(
    m
      .hour(hour)
      .minute(0)
      .format()
  );
};

/*
 * 'now' is your 'getNow' method
 * 'tzMoment is the 'moment' object you got from 'getMoment()'
 */
export const getNow = (now, tzMoment) => convertDateTimeToDate(now(), tzMoment);