import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from 'utils/moment'
import 'moment-timezone'
import "react-big-calendar/lib/css/react-big-calendar.css";
const momentTZ = require('moment-timezone');

// icn
import { ReactComponent as SettingIcn } from "../../../../../Assets/Images/icn/setting.svg";
import { ReactComponent as PlusIcn } from "../../../../../Assets/Images/icn/plus.svg";
import { Button, Dropdown } from "react-bootstrap";
import BookAppointmentPop from "pages/SideTabs/MyClients/Component/Modal/BookAppointmentPop";
import AddBlockPop from "./Modals/AddBlockPop";
import AppointmentDetailPopup from "./Modals/AppointmentDetailPopup";
import BlockDetailsPopup from "./Modals/BlockDetailsPopup";
import { FULL_NAME, utcTimestamp_, timeZoneFormat_ } from "helpers/common";
import { updateSchedulerSetting_ } from "store/services/appointmentService";



const AppointmentCalender = ({
  scheduleSec,
  setScheduleSec,
  setSchedulerType,
  handleChangeBlock_,
  blockfields,
  submitBlockTimeData,
  BlockAdd,
  setBlockAdd,
  accountList,
  validationErrors,
  buttonDisable_,
  appointmentTypeList,
  timeZoneListing,
  appointmentBookingList,
  setEditValueSetting,
  setStepType,
  setBookApp,
  calendarBlocks,
  schedulerTimezone,
  schedulerBlockDatesSetting,
  accountSettingList
}) => {
  

  const [bookAppointment, setBookAppointment] = useState("");
  const [showAppointmentDetailPopup, setShowAppointmentDetailPopup] = useState(false);
  const [showBlockDetailPopup, setShowBlockDetailPopup] = useState(false);
  const [currentAppointmentDetails, setCurrentAppointmentDetails] = useState(null);
  const [currentBlockDetails, setCurrentBlockDetails] = useState(null);
  const [events, setEvents] = useState([]);

  let timezone = schedulerTimezone;

  // moment.tz.setDefault(timezone)
  // const momentObj = getMoment(timezone);
  const localizer = momentLocalizer(moment);
  // const localizer = momentLocalizer(moment);

  // open appointment details popup
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    let appointmentId = searchParams.get("apptId");
    if(appointmentId && appointmentBookingList) {
      openAppointmentDetailsPopup(appointmentId);
    }
  }, [appointmentBookingList])

  useEffect(() => {
    let appointments = [];
    if(appointmentBookingList) {
      appointments = appointmentBookingList.map(appointment => {
        let clientFullName = `${FULL_NAME(appointment.first_name, appointment.last_name)}`;
        const appointmentTimezone = appointment.appointment_timezone;
        const startTime = momentTZ(appointment.start_time).toDate();
        const endTime = momentTZ(appointment.end_time).toDate();

        const startTimeTz = momentTZ(appointment.start_time).tz(appointmentTimezone).toString();
        const endTimeTz = momentTZ(appointment.end_time).tz(appointmentTimezone).toString();
        return {
          id: appointment.id,
          title: `${appointment.title}: ${clientFullName}, ${startTimeTz} - ${endTimeTz}`,
          start:  startTime,
          end: endTime,
          type: "appointment",
        }
      })
    }

    let blockedTimes = [];
    if(calendarBlocks) {
      blockedTimes = calendarBlocks.map((block, i) => {
        let blockTimezone = block.timezone;
        let startTime;
        let endTime;

        if(block.full_day_block) {
          // if the block is full day block
          startTime = moment(block.from_date).set('hour', 0).set("minute", 1).toDate();
          endTime = moment(block.to_date).set('hour', 23).set("minute", 59).toDate();
        } else {
          // if the block is not full day block, then set the block timezone to date object
          startTime = momentTZ(block.from_date).tz(blockTimezone, true).toDate();
          endTime = momentTZ(block.to_date).tz(blockTimezone, true).toDate();
        }

        return {
          title: "Block",
          start:  startTime,
          end: endTime,
          type: "block",
          isAllDay: block.full_day_block,
          key: i,
        }
      })
    }

    const events = [
      ...appointments,
      ...blockedTimes,
    ]

    // console.log(events, "events");
    setEvents(events);
  }, [appointmentBookingList, calendarBlocks])

  const handleScheduleSec = () => {
    setScheduleSec(!scheduleSec);
    setSchedulerType(true)
    setStepType(true)
  };
 

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      width: "100%",
      padding: 0,
    };
    return {
      style,
    };
  };

  const handleBookAppointment = () => {
    setBookAppointment(!bookAppointment);
  };

  const handleBlockAdd = () => setBlockAdd(!BlockAdd);

  const handleEventSelect = (event) => {
    if(event.type == "appointment") {
      openAppointmentDetailsPopup(event.id);
    } else if(event.type == "block") {
      openBlockDetailsPopup(event);
    }
  }

  const openAppointmentDetailsPopup = (apptId) => {
    const appointment = appointmentBookingList.find(appointment => appointment.id == apptId);
    if(appointment) {
      setCurrentAppointmentDetails(appointment);
      setShowAppointmentDetailPopup(true);
    }
  }

  const openBlockDetailsPopup = (eventData) => {
    setCurrentBlockDetails(eventData);
    setShowBlockDetailPopup(true);
  }

  const Event = (props) => {
    // console.log("props", props)
    const {event} = props
    return (
      <div className={`calendar-event ${event.type}`}>
        {event.title}
      </div>
    )
  }

  return (
    <>
      <BookAppointmentPop
        bookAppointment={bookAppointment}
        setBookAppointment={setBookAppointment}
        appointmentTypeList={appointmentTypeList}
        accountList={accountList}
        timeZoneListing={timeZoneListing}
        setBookApp={setBookApp}
        schedulerTimezone={schedulerTimezone}
      />
      <AddBlockPop
        BlockAdd={BlockAdd}
        setBlockAdd={setBlockAdd}
        blockfields={blockfields}
        handleChangeBlock_={handleChangeBlock_}
        submitBlockTimeData={submitBlockTimeData}
        accountList={accountList}
        validationErrors={validationErrors}
        buttonDisable_={buttonDisable_}
      />
      <AppointmentDetailPopup
        showPopup={showAppointmentDetailPopup}
        setShowPopup={setShowAppointmentDetailPopup}
        details={currentAppointmentDetails}
        setBookApp={setBookApp}
      />
      <BlockDetailsPopup
        showPopup={showBlockDetailPopup}
        setShowPopup={setShowBlockDetailPopup}
        details={currentBlockDetails}
        calendarBlocks={calendarBlocks}
        schedulerBlockDatesSetting={schedulerBlockDatesSetting}
        timezone={timezone}
        accountSettingList={accountSettingList}
      />
      <div className="CalenderWrpper">
        <div className="top d-flex align-items-center justify-content-between gap-10 mb-3">
          {/* <Button className="d-flex align-items-center justify-content-center commonBtn btnSm">
            <span className="icn me-2">
              <PlusIcn />
            </span>{" "}
            Add
          </Button> */}
          <Dropdown>
            <Dropdown.Toggle
              className="d-flex align-items-center justify-content-center commonBtn btnSm noAfter"
              id="dropdown-basic"
            >
              <span className="icn me-2">
                <PlusIcn />
              </span>{" "}
              Add
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={handleBlockAdd}>New Block</Dropdown.Item>
              <Dropdown.Item onClick={handleBookAppointment}>
                New Appointment
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Button
            onClick={handleScheduleSec}
            className="d-flex align-items-center fw-sbold justify-content-center commonBtn btnSm"
          >
            <span className="icn me-2">
              <SettingIcn />
            </span>{" "}
            Scheduler Settings
          </Button>
        </div>
        <Calendar
          localizer={localizer}
          events={events}
          eventPropGetter={eventStyleGetter}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          formats={{
            timeGutterFormat: 'HH:mm', // Adjust the time format as needed
          }}
          views={['month', 'week', 'day']}
          defaultView="month" 
          onSelectEvent={handleEventSelect}
          components={{
            event: Event,
            // month: { event: Event },
          }}
          // showAllEvents
        />
      </div>
    </>
  );
};

export default AppointmentCalender;
