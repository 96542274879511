import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import AuthLayout from "../../../layout/AuthLayout/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
// import { PinField } from "react-pin-field";
import OtpInput from 'react-otp-input';
import { dispatch } from "d3";
import { connect } from "react-redux";
import { Formik } from "formik";
import validation from "./validation";
import * as Path from 'Routes/paths';
import * as actions from 'store/actions';
import { register_, resendOtp } from "store/services/authService";
import OTPTimer from "components/shared/OTPTimer";
import { SUCCESS_MESSAGE } from "common/constantMessage";
import { hasActivePlan } from "helpers/common";

const VerifyOTP = ({ dispatch, registrationDetails }) => {
  const navigate = useNavigate();

  const initialValues = {
    otp: ""
  }

  useEffect(() => {
    if(!registrationDetails) {
      navigate(Path.login);
    }
  }, [registrationDetails])

  const verifyOtp = async (values, { setSubmitting }) => {
    try {
      const callback = (response) => {
        const { body } = response;
        if (body.terms_accepted === 'no') {
          navigate(Path.dashboardTermsCondition)
        }
        if (!hasActivePlan(body)) {
          navigate(Path.dashboardSubscription)
        }
        if (body.is_plan_active === 'yes' || body.is_free_account === 'yes') {
          navigate(Path.dashboard)
        }
      }

      registrationDetails.otp = values.otp;
      let resposne = await dispatch(register_(registrationDetails, callback));
      setSubmitting(false);
    } catch(err) {
      console.log("Error verifying Otp", err);
      setSubmitting(false);
    }
  }

  const handleResendOtp = async (callback) => {
    try {
      if(!registrationDetails?.email) {
        throw "Email not found";
      }
      dispatch(actions.persist_store({ loader: true }));
      let resposne = await resendOtp(registrationDetails?.email, "register");
      if(resposne?.success === true) {
        dispatch(actions.persist_store({ loader: false }));
        callback();
        SUCCESS_MESSAGE("OTP sent successfully");
      }
    } catch(err) {
      console.log("Error resending Otp", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  return (
    <>
      {/* <AuthLayout> */}
      <div className="FormInner py-5 bg-white px-sm-5 px-4">
        <div className="top mb-3 text-center">
          <h2 className="m-0 fw-bold">Enter Your OTP Here</h2>
          <p className="m-0">
            {registrationDetails?.email && `OTP is sent to your email ${registrationDetails?.email}`}
          </p>
        </div>
        <div className="FormBody">
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validation}
            onSubmit={verifyOtp}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => (
          <Form onSubmit={handleSubmit}>
            {/* {console.log("values", values, validation.validate(values).then(result => console.log("result", result)))} */}
            <Row>
              <Col lg="12" className="py-2 text-center">
                {/* <label
                  htmlFor=""
                  className="form-label m-0 py-1 fw-sbold d-block text-center"
                >
                  Enter OTP
                </label> */}
                <div className="OtpCstm d-flex align-items-center gap-10 justify-content-center text-center">
                  <OtpInput
                    containerStyle="otp-container"
                    inputStyle="otp-input-field"
                    value={values.otp}
                    onChange={(value) => {
                      setFieldValue("otp", value);
                    }}
                    numInputs={4}
                    renderSeparator={<span className="otp-seperator">-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                {(errors.otp && touched.otp) &&
                  <p className="invalid-feedback d-block m-0 py-2">{errors.otp}</p>
                }
                <div className="py-2">
                  <OTPTimer
                    onResend={handleResendOtp}
                  />
                </div>
              </Col>
              <Col lg="12" className="my-2">
                <Button
                  type="submit"
                  className="d-flex align-items-center fw-bold justify-content-center commonBtn w-100"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "Verify OTP"}
                </Button>
                {/* <Link
                  to="/reset-password"
                  className="d-flex btn text-white align-items-center fw-bold justify-content-center commonBtn w-100"
                >
                  Submit
                </Link> */}
              </Col>
            </Row>
          </Form>
          )}
          </Formik>
        </div>
      </div>
      {/* </AuthLayout> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    registrationDetails: state.Auth.registrationDetails,
  }
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP);