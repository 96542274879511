import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";

import { getClientActionPlanDetail } from "store/services/clientService";
import * as actions from "store/actions";

// icons
import { ReactComponent as CopyIcn } from "Assets/Images/icn/Copy.svg";
import { ReactComponent as EditIcon } from "Assets/Images/icn/pencil.svg";
import { ReactComponent as DownloadIcon } from "Assets/Images/icn/download.svg";
import AllQuestionAnswersPDF from "components/shared/PDFComponents/AllQuestionAnswers/Index";
import { decode } from "html-entities";
import { RenderAnswerField } from "components/shared/ToDoQuestionsAnswers/RenderAnswer/Index";

const SingleActionPlan = ({
  dispatch,
  actionPlan,
  handleEdit,
  togglePublish,
  client
}) => {
  const [actionPlanDetails, setActionPlanDetails] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen && !actionPlanDetails) {
      fetchActionPlanDetails();
    }
  }, [isOpen])

  const fetchActionPlanDetails = async () => {
    try {
      if (!actionPlan.id) {
        throw new Error("Invalid Action Plan ID");
      }
      setLoading(true);
      let response = await getClientActionPlanDetail(actionPlan.id);
      if (response.success === true && response.body) {
        setActionPlanDetails(response.body);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error occurred while fetching action plan details", err);
      setLoading(false);
    }
  }

  const showLoader = () => {
    dispatch(actions.persist_store({ loader: true }));
  }

  const hideLoader = () => {
    dispatch(actions.persist_store({ loader: false }));
  }

  if(!actionPlan) {
    return "";
  }

  return (
    <>
      <Accordion.Item eventKey={actionPlan.id} className="recommendation-report border-0 my-2">
        <Accordion.Header
          className=""
          style={{
            background: "#FFFDFA",
            border: "1px solid #405C40",
            color: "#405C40",
          }}
        >
          <span className="toggleBtn position-absolute"></span>
          <div className="d-flex align-items-center gap-10">
            <span className="icn me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                  fill="currentColor"
                />
                <path
                  d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                  fill="currentColor"
                />
                <path
                  d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Client Action Plan
            {actionPlan?.additional_data?.isPublished && ` (Published)`}
          </div>
          <div className="right d-flex align-items-center gap-10">
            <p className="m-0 text-dark text-lowercase fw-sbold">
              {`from ${moment(actionPlan.created_at).format("MMM DD, YYYY")}`}
            </p>
          </div>
        </Accordion.Header>
        <Accordion.Body
          onEnter={() => {
            setOpen(true);
          }}
          onExit={() => {
            setOpen(false);
          }}
        >
          {isLoading ?
            <p>Loading...</p>
            :
            <div className="py-2 AccordionInner">
              <div className="inner">
                <div className="top d-flex align-items-start justify-content-between gap-10">
                  <div className="left">
                    {actionPlan?.additional_data?.isPublished &&
                      <p>Published at {moment(actionPlan.created_at).format("MMM DD, YYYY")}</p>
                    }
                  </div>
                  <div className="right">
                    <div className="d-flex gap-10">
                      <Button
                        onClick={() => {
                          handleEdit(actionPlan.id)
                        }}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        <span className="icn me-2">
                          <EditIcon />
                        </span>
                        Edit Form
                      </Button>
                      <Button
                        onClick={() => {
                          togglePublish(actionPlan.id);
                        }}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        <span className="icn me-2">
                          <CopyIcn />
                        </span>
                        {actionPlan?.additional_data?.isPublished ? "Unpublish" : "Publish"}
                      </Button>
                      <Button
                        className="d-flex align-items-center justify-content-center commonBtn btnSm pdf-download-btn"
                      >
                        <span className="icn me-2">
                          <DownloadIcon />
                        </span>
                        <AllQuestionAnswersPDF
                          date={actionPlan.updated_at}
                          client={client}
                          todoId={actionPlan.id}
                          formName={"Action Plan"}
                          label="Download Report"
                          showColumns={false}
                          showLoader={showLoader}
                          hideLoader={hideLoader}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
                {actionPlanDetails && actionPlanDetails.map(page => {
                  return (
                    <div className="py-2">
                      <div className="top py-2 commonContent">
                        <h3 className="m-0 py-1 text-uppercase">
                          {decode(page.page_title)}
                        </h3>
                      </div>
                      <div className="commonCardBox">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              {page.questions && page.questions.length > 0 &&
                                page.questions.map(question => {
                                  return (
                                    <tr>
                                      <th className="p-3 ws-normal">
                                        <span className="text-dark fw-sbold">
                                          {decode(question.question)}
                                        </span>
                                      </th>
                                      <td className="p-3 ws-normal">
                                        {/* {question.answer || "Nothing entered"} */}
                                        <RenderAnswerField 
                                          question={question}
                                        />
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          }

        </Accordion.Body>
      </Accordion.Item>
    </>
  )
}

const TableRows = ({ rows }) => {
  return (
    <>
      {rows && rows.length > 0 &&
        rows.map((row, index) => {
          return (
            <tr key={index}>
              <td className="p-3 border-0">
                <p className="m-0">{row.recommendation}</p>
              </td>
              <td className="p-3 border-0 ws-normal">
                <p className="m-0">
                  {row.benefit}
                </p>
              </td>
              <td className="p-3 border-0">{row.per_day}</td>
              <td className="p-3 border-0">{row.upon_waking}</td>
              <td className="p-3 border-0">{row.with_breakfast}</td>
              <td className="p-3 border-0">{row.midmorning}</td>
              <td className="p-3 border-0">{row.with_lunch}</td>
              <td className="p-3 border-0">{row.midafternoon}</td>
              <td className="p-3 border-0">{row.with_dinner}</td>
              <td className="p-3 border-0">{row.before_bed}</td>
            </tr>
          )
        })
      }
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    clients: state.Client.clients,
    client: state.Client.client.details,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleActionPlan);