import React, { useContext, useState } from "react";
import {
  Accordion,
  Button
} from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';
// import SingleSubmittedTodo from "./Component/SingleSubmittedTodo";
import NutritionalAssessmentV1 from "./Component/AccordionTabs/NutritionalAssessmentV1/Index";
import NutritionalAssessmentV2 from "./Component/AccordionTabs/NutritionalAssessmentV2/Index";
import HealthWellnessAssessment from "./Component/AccordionTabs/HealthWellnessAssessment/Index";
import AllQuestionAnswers from "./Component/AccordionTabs/NutritionalAssessmentV2/Component/AllQuestionAnswers";
import ToDoAllQuestionAnswers from "./Component/AccordionTabs/ToDoAllQuestionAnswers/Index";
import { FULL_NAME } from "helpers/common";
import MultiReportDownloadOptions from "./Component/MultiReportDownload/Index";
import { Link } from "react-router-dom";
import { getAccountSettingList_ } from "store/services/accountSettingService";
import FoodMoodJournal from "./Component/AccordionTabs/Food&MoodJournal/Index";

const SubmittedData = ({ completedTodoItems, client, multiReportDownload }) => {
  const [showMultiReport, setShowMultiReport] = useState(false);

  return (
    <>
      <div className="pt-2">
        <div className="top d-flex align-items-start justify-content-between gap-10">
          <div className="left">
            <h6 className="m-0 fw-bold">
              {client && `${FULL_NAME(client.first_name, client.last_name)}'s Submitted Data`}
            </h6>
            {client && (
              <p className="m-0">
                Below is all of {FULL_NAME(client.first_name, client.last_name)}'s previously submitted data. {` `}
                {multiReportDownload === "enable" &&
                  <Link
                    onClick={() => {
                      setShowMultiReport(!showMultiReport);
                    }}
                  >
                    {showMultiReport ?
                      "Hide the Multi-Report Download options."
                      :
                      "View the Multi-Report Download options."
                    }
                  </Link>
                }
              </p>
            )}
          </div>
        </div>
        {showMultiReport &&
          <MultiReportDownloadOptions
            completedToDos={completedTodoItems}
            client={client}
          />
        }
      </div>
      <Accordion className="my-3 ColoredAcorrdion">
        {completedTodoItems && completedTodoItems.length > 0 &&
          completedTodoItems.map(todo => {
            switch(todo.form_id){
              case 4:
                return (
                  <NutritionalAssessmentV2
                    key={todo.id}
                    todoId={todo.id}
                    formName={todo.form_name}
                    formId={todo.form_id}
                    submittedOn={todo.updated_at}
                    todoDetails={todo}
                    // color={todo.color}
                  />
                )

              case 11:
                return (
                  <HealthWellnessAssessment
                    key={todo.id}
                    todoId={todo.id}
                    formName={todo.form_name}
                    formId={todo.form_id}
                    submittedOn={todo.updated_at}
                    todoDetails={todo}
                    // color={todo.color}
                  />
                )

              case 12:
                return (
                  <NutritionalAssessmentV1
                    key={todo.id}
                    todoId={todo.id}
                    formName={todo.form_name}
                    formId={todo.form_id}
                    submittedOn={todo.updated_at}
                    todoDetails={todo}
                    // color={todo.color}
                  />
                )

              case 21:
                return (
                  <FoodMoodJournal
                    key={todo.id}
                    todoId={todo.id}
                    formName={todo.form_name}
                    formId={todo.form_id}
                    submittedOn={todo.updated_at}
                    todoDetails={todo}
                    // color={todo.color}
                  />
                )
              default:
                return (
                  <ToDoAllQuestionAnswers
                    key={todo.id}
                    todoId={todo.id}
                    formName={todo.form_name}
                    formId={todo.form_id}
                    submittedOn={todo.updated_at}
                    todoDetails={todo}
                    // color={todo.color}
                  />
                )
            }
          })
        }
      </Accordion>
    </>
  )
}

export default SubmittedData;