import { postData, getData, putData, deleteData } from "helpers/apiHelper";
import {
  MAIN_PATH,
  CLIENT_PATH,
  CLIENT_LIST,
  CLIENT_ADD,
  CLIENT_CATEGORY_LIST,
  ADD_FAVORITE_DATA,
  ASSIGN_TAGS,
  GET_TODO_DETAILS,
  SAVE_TODO_RESPONSE,
  ADD_CLIENT_TODO,
  DELETE_CLIENT_TODO,
  GET_TODO_LIST,
  VIEW_CLIENT,
  GET_TODO_RESPONSE,
  UPDATE_CLIENT,
  GET_CLIENT_SETTINGS,
  REMOVE_CLIENT,
  GET_CLIENT_PRIORITIES,
  SAVE_CLIENT_RECOMMENDATION,
  GET_CLIENT_RECOMMENDATION_LIST,
  ADD_CLIENT_RECOMMENDATION,
  GET_CLIENT_RECOMMENDATION_DETAILS,
  UPDATE_CLIENT_RECOMMENDATION_DETAILS,
  DUPLICATE_CLIENT_RECOMMENDATION,
  DELETE_CLIENT_RECOMMENDATION,
  CREATE_RECOMMENDATION_TEMPLATE,
  GET_RECOMMENDATION_TEMPLATE_LIST,
  GET_GRAPH_DATA,
  RESEND_INVITATION_EMAIL,
  GET_PACKAGE_INVITATION_LIST,
  RESEND_PACKAGE_INVITATION,
  CANCEL_PACKAGE_INVITATION,
  DELETE_PACKAGE_INVITATION,
  GET_RECOMMENDATION_REPORTS_LIST,
  GET_RECOMMENDATION_DETAIL,
  VIEW_RECOMMENDATION_REPORT_DETAIL,
  VIEW_TODO_RECOMMENDATION_DETAIL,
  REMOVE_TAGS,
  SEND_TODO_REMINDER,
  DOWNLOAD_RECOMMENDATION_REPORT,
  DOWNLOAD_TODO_RECOMMENDATION_REPORT,
  UPDATE_RECOMMENDATION_TEMPLATE,
  DELETE_RECOMMENDATION_TEMPLATE,
  GET_CLIENT_ACTION_PLAN_LIST,
  VIEW_CLIENT_ACTION_PLAN_DETAILS,
  TOGGLE_PUBLISH_ACTION_PLAN,
  DELETE_ACTION_PLAN,
  SAVE_FOOD_MOOD_JOURNAL_DATA,
  GET_FOOD_MOOD_JOURNAL_DATA,
  GET_FOOD_MOOD_JOURNAL_PROGRESS,
  SUBMIT_FOOD_MOOD_JOURNAL,
  VIEW_FOOD_MOOD_SUBMITTED_DATA,
  SAVE_FOOD_MOOD_NOTES,
  GET_CLIENT_APPOINTMENTS,
  POST_APPOINTMENTS_ORDER,
  UPDATE_CLIENT_SETTINGS,
  POST_FORM_SORT_ORDER,
  GET_FOOD_MOOD_LOGS,
  ADD_COMMENT_FOOD_MOOD_LOGS,
  MAIN_PATH_RECOMMENDATION,
  SEND_RECOMM_TO_CLIENT,
  GET_TODO_FORM_DETAILS,
  UPDATE_TODO_STATUS,
  GET_CLIENT_STATS_LIST,
  MERGE_CLIENTS,
  PREPOPULATE_RECOMM_FROM_NAQ,
  DELETE_FMJ_DAY_LOG,
} from "./apiPath";
import {
  getClient,
  addClient,
  viewClient,
  updateClient,
  getClientCategoryList,
  addUpdateFavorite,
  clientPagination,
  assignTagsToClient,
  getClientToDoDetails,
  saveClientTodoResponse,
  createClientToDo,
  deleteToDoItem,
  getClientToDoListing,
  getAllClientSettings,
  updateSettingsForClient,
  deleteClientData,
  UPDATE_CLIENT_PRIORITIES,
  addRecommendationForClient,
  getClientRecommendationDetails,
  updateClientRecommendation,
  deleteClientRecommendation,
  createRecommendationTemplate_,
  getRecommendationTemplateListing,
  getGraphData,
  getClientPackageInvitations,
  cancelClientPackageInvitation,
  removeClientPackageInvitation,
  getClientRecommendationReportListing,
  removeClientTags,
  getClientActionPlansListing,
  getFoodMoodLogs,
} from "store/actions/client";

//get
export function getClientList(value, callback) {
  let url = `${MAIN_PATH}${CLIENT_LIST}`;
  return (dispatch) =>
    postData(url, value).then((data) => {
      dispatch(getClient(data, callback));
      return data;
    });
}

//add
export function addClientData(value, callback) {
  let url = `${CLIENT_PATH}${CLIENT_ADD}`;
  // let url = `http://localhost:3000/dev/clients/add`;
  return (dispatch) =>
    postData(url, value).then((data) => {
      dispatch(addClient(data, callback));
      return data;
    });
}

//view
export function viewClientData(data, callback) {
  const { clientId } = data;
  let url = `${MAIN_PATH}${VIEW_CLIENT.replace(":id", clientId)}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(viewClient(data, callback));
      return data;
    });
}

//edit
export function updateClientData(data, callback) {
  let { clientId, values } = data;
  let url = `${MAIN_PATH}${UPDATE_CLIENT.replace(":id", clientId)}`;
  return (dispatch) =>
    putData(url, values).then((data) => {
      dispatch(updateClient(data, callback));
      return data;
    });
}

//delete
export function deleteClient(clientId, callback) {
  return (dispatch) =>
    deleteData(`${MAIN_PATH}${REMOVE_CLIENT.replace(":id", clientId)}`).then(
      (data) => {
        dispatch(deleteClientData(clientId, callback));
        return data;
      }
    );
}

//get client category
export function getClientCategoryList_(value, callback) {
  let url = `${MAIN_PATH}${CLIENT_CATEGORY_LIST}`;
  return (dispatch) =>
    getData(url, value).then((data) => {
      dispatch(getClientCategoryList(data, callback));
      return data;
    });
}

//add update favorite
export function addUpdateFavorite_(id, value, callback) {
  return (dispatch) =>
    postData(`${CLIENT_PATH}${id}${ADD_FAVORITE_DATA}`, value).then((data) => {
      dispatch(addUpdateFavorite(data, callback));
      return data;
    });
}

//assign tags to client
export function assignTagsToClient_(value, callback) {
  return (dispatch) =>
    postData(`${CLIENT_PATH}${ASSIGN_TAGS}`, value).then((data) => {
      dispatch(assignTagsToClient(data, callback));
      return data;
    });
}

// remove tags to client
export function removeTagsFromClient(value, callback) {
  let url = `${MAIN_PATH}${REMOVE_TAGS}`;
  return (dispatch) =>
    postData(url, value).then((data) => {
      dispatch(removeClientTags(data, callback));
      return data;
    });
}

//client pagination
export function clientPagination_(data, callback) {
  return (dispatch) => {
    dispatch(clientPagination(data, callback));
    return data;
  };
}

// get currently editing todo list
export function getClientToDoList(data, callback) {
  let { clientId } = data;
  return (dispatch) =>
    getData(`${MAIN_PATH}${GET_TODO_LIST.replace(":id", clientId)}`).then(
      (data) => {
        dispatch(getClientToDoListing(data, callback));
        return data;
      }
    );
}

// get client ToDo details
export function getTodoDetails(data, callback) {
  let { todoId } = data;
  let url = `${MAIN_PATH}${GET_TODO_DETAILS.replace(":id", todoId)}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getClientToDoDetails(data, callback));
      return data;
    });
}

// save ToDo response
export function saveTodoResponse(data, callback) {
  let { id, formData } = data;
  let url = `${MAIN_PATH}${SAVE_TODO_RESPONSE.replace(":id", id)}`;
  return postData(url, formData);
  // return (dispatch) => postData(url, formData)
  //   .then((data) => {
  //     dispatch(saveClientTodoResponse(data, callback));
  //     return data;
  //   });
}

// view ToDo response
export function getTodoResponse(data) {
  let { todoId } = data;
  let url = `${MAIN_PATH}${GET_TODO_RESPONSE.replace(":id", todoId)}`;
  return getData(url);
}

// create todo item
export function createClientToDoItem(data, callback) {
  let { clientId, values } = data;
  let url = `${MAIN_PATH}${ADD_CLIENT_TODO.replace(":id", clientId)}`;
  return (dispatch) =>
    postData(
      url,
      values
    ).then((data) => {
      // dispatch(createClientToDo(data, callback));
      dispatch(getClientToDoList({ clientId }, callback));
      return data;
    });
}

//delete
export function deleteClientToDo(values, callback) {
  const { todoId } = values;
  return (dispatch) =>
    deleteData(`${MAIN_PATH}${DELETE_CLIENT_TODO.replace(":id", todoId)}`).then(
      (data) => {
        dispatch(deleteToDoItem(values, callback));
        return data;
      }
    );
}

// fetch settings
export function getClientSettings(clientId, callback) {
  return (dispatch) =>
    getData(`${MAIN_PATH}${GET_CLIENT_SETTINGS.replace(":id", clientId)}`).then(
      (data) => {
        dispatch(getAllClientSettings(data, callback));
        return data;
      }
    );
}

// update client settings
export function updateClientSettings(data, callback) {
  let { clientId, values } = data;
  return (dispatch) =>
    postData(
      `${MAIN_PATH}${UPDATE_CLIENT_SETTINGS.replace(":id", clientId)}`,
      values
    ).then((data) => {
      dispatch(updateSettingsForClient(data, callback));
      return data;
    });
}

// get client recommendation list
// export function getClientRecommendationList(values, callback) {
//   let { clientId } = values;
//   let url = `${MAIN_PATH}${GET_CLIENT_RECOMMENDATION_LIST.replace(":id", clientId)}`;
//   return (dispatch) => getData(url)
//     .then((data) => {
//       dispatch(getClientRecommendationListing(data, callback));
//       return data;
//     });
// }

// get client recommendation reports list
export function getClientRecommendationReportsList(values, callback) {
  let { clientId } = values;
  let url = `${MAIN_PATH_RECOMMENDATION}${GET_RECOMMENDATION_REPORTS_LIST.replace(
    ":id",
    clientId
  )}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getClientRecommendationReportListing(data, callback));
      return data;
    });
}

// get recommendation details
export function getRecommendationReportDetails(values, callback) {
  let { recommendationId } = values;
  let url = `${MAIN_PATH_RECOMMENDATION}${VIEW_RECOMMENDATION_REPORT_DETAIL.replace(
    ":id",
    recommendationId
  )}`;
  return getData(url);
}

// get todo recommendation details
export function getTodoRecommendationDetails(values, callback) {
  let { todoId } = values;
  let url = `${MAIN_PATH_RECOMMENDATION}${VIEW_TODO_RECOMMENDATION_DETAIL.replace(
    ":id",
    todoId
  )}`;
  return getData(url);
}

// add client recommendation data
export function addClientRecommendation(data, callback) {
  let { clientId, values } = data;
  let url = `${MAIN_PATH_RECOMMENDATION}${ADD_CLIENT_RECOMMENDATION.replace(":id", clientId)}`;
  return (dispatch) =>
    postData(url, values).then((data) => {
      dispatch(addRecommendationForClient(data, callback));
      return data;
    });
}

// get client recommendation details
export function getRecommendationDetails(data, callback) {
  let { id } = data;
  let url = `${MAIN_PATH_RECOMMENDATION}${GET_CLIENT_RECOMMENDATION_DETAILS.replace(
    ":id",
    id
  )}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getClientRecommendationDetails(data, callback));
      return data;
    });
}

// update recommendation data
export function updateRecommendation(data, callback) {
  let { recommendationId, values } = data;
  let url = `${MAIN_PATH_RECOMMENDATION}${UPDATE_CLIENT_RECOMMENDATION_DETAILS.replace(
    ":id",
    recommendationId
  )}`;
  return (dispatch) =>
    putData(url, values).then((data) => {
      dispatch(updateClientRecommendation(data, callback));
      return data;
    });
}

// duplicate recommendation
export function duplicateRecommendation(data, callback) {
  let { recommendationId, clientId } = data;
  let url = `${MAIN_PATH_RECOMMENDATION}${DUPLICATE_CLIENT_RECOMMENDATION.replace(
    ":id",
    recommendationId
  ).replace(":clientId", clientId)}`;
  return (dispatch) =>
    getData(url).then((data) => {
      return data;
    });
}

// delete recommendation
export function deleteRecommendation(values, callback) {
  const { id } = values;
  let url = `${MAIN_PATH_RECOMMENDATION}${DELETE_CLIENT_RECOMMENDATION.replace(":id", id)}`;
  return (dispatch) =>
    deleteData(url).then((data) => {
      dispatch(deleteClientRecommendation(data, callback));
      return data;
    });
}

// create recommendation template
export function createRecommendationTemplate(values, callback) {
  let url = `${MAIN_PATH_RECOMMENDATION}${CREATE_RECOMMENDATION_TEMPLATE}`;
  return (dispatch) =>
    postData(url, values).then((data) => {
      dispatch(createRecommendationTemplate_(data, callback));
      return data;
    });
}

// update recommendation template
export function updateRecommendationTemplate(data, callback) {
  let { templateId, values } = data;
  let url = `${MAIN_PATH_RECOMMENDATION}${UPDATE_RECOMMENDATION_TEMPLATE}${templateId}`;
  return postData(url, values);
}

// delete recommendation template
export function deleteRecommendationTemplate(templateId, callback) {
  let url = `${MAIN_PATH_RECOMMENDATION}${DELETE_RECOMMENDATION_TEMPLATE}${templateId}`;
  return deleteData(url);
}

// get recommendation templates list
export function getRecommendationTemplateList(callback) {
  let url = `${MAIN_PATH_RECOMMENDATION}${GET_RECOMMENDATION_TEMPLATE_LIST}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getRecommendationTemplateListing(data, callback));
      return data;
    });
}

// send recommendation to client
export function sendRecommToClient(recommId, values) {
  let url = `${MAIN_PATH_RECOMMENDATION}${SEND_RECOMM_TO_CLIENT}${recommId}`;
  return postData(url, values);
}

// prepopulate recommendation
export function prepopulateRecommFromNAQ(values) {
  let url = `${MAIN_PATH_RECOMMENDATION}${PREPOPULATE_RECOMM_FROM_NAQ}`;
  // let url = `http://localhost:3000/dev/prepopulate`;
  return postData(url, values);
}

// resend invitation email to the client
export function resendInvitationEmail(values, callback) {
  let { clientId } = values;
  return postData(`${MAIN_PATH}${RESEND_INVITATION_EMAIL}${clientId}`, values);
}

// get package invitations list
export function getPackageInvitationsList(values, callback) {
  let { clientId } = values;
  let url = `${MAIN_PATH}${GET_PACKAGE_INVITATION_LIST}${clientId}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getClientPackageInvitations(data, callback));
      return data;
    });
}

// resend package invitation
export function resendPackageInvitation(data, callback) {
  let { invitationId, values } = data;
  let url = `${MAIN_PATH}${RESEND_PACKAGE_INVITATION}${invitationId}`;
  // let url = `http://localhost:3000/dev/clients/packages/invitation/resend/${invitationId}`;
  return postData(url,values);
}

// cancel package invitation
export function cancelPackageInvitation(data, callback) {
  let { invitationId, values } = data;
  return (dispatch) =>
    postData(
      `${MAIN_PATH}${CANCEL_PACKAGE_INVITATION}${invitationId}`,
      values
    ).then((data) => {
      dispatch(cancelClientPackageInvitation(data, callback));
      return data;
    });
}

// remove package invitation
export function removePackageInvitation(data, callback) {
  let { invitationId, values } = data;
  return (dispatch) =>
    deleteData(
      `${MAIN_PATH}${DELETE_PACKAGE_INVITATION}${invitationId}`,
      values
    ).then(() => {
      dispatch(removeClientPackageInvitation(data, callback));
      return data;
    });
}

export function sendToDoReminderToClient(todoId) {
  return postData(`${MAIN_PATH}${SEND_TODO_REMINDER.replace(":id", todoId)}`);
}

// get todo form details
export function getTodoFormDetails(todoId) {
  let url = `${MAIN_PATH}${GET_TODO_FORM_DETAILS.replace(":id", todoId)}`;
  return getData(url);
}

// update todo status
export function updateTodoStatus(todoId, values) {
  let url = `${MAIN_PATH}${UPDATE_TODO_STATUS.replace(":id", todoId)}`;
  return postData(url, values);
}

export function downloadRecommReport(id) {
  let url = `${MAIN_PATH}${DOWNLOAD_RECOMMENDATION_REPORT.replace(":id", id)}`;
  return getData(url);
}

export function downloadToDoRecommReport(id) {
  let url = `${MAIN_PATH}${DOWNLOAD_TODO_RECOMMENDATION_REPORT.replace(
    ":id",
    id
  )}`;
  return getData(url);
}

// get client action plans list
export function getClientActionPlansList(values, callback) {
  let { clientId } = values;
  let url = `${MAIN_PATH}${GET_CLIENT_ACTION_PLAN_LIST}${clientId}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getClientActionPlansListing(data, callback));
      return data;
    });
}

// get client action plan details
export function getClientActionPlanDetail(id) {
  let url = `${MAIN_PATH}${VIEW_CLIENT_ACTION_PLAN_DETAILS}${id}`;
  return getData(url);
}

// toggle publish action plan
export function toggleActionPlanPublish(id) {
  let url = `${MAIN_PATH}${TOGGLE_PUBLISH_ACTION_PLAN}${id}`;
  return postData(url);
}

// delete client action plan
export function deleteClientActionPlan(id) {
  let url = `${MAIN_PATH}${DELETE_ACTION_PLAN}${id}`;
  return deleteData(url);
}

// save Food & Mood Journal data
export function saveFoodMoodJournalData(data, callback) {
  let { values } = data;
  let url = `${MAIN_PATH}${SAVE_FOOD_MOOD_JOURNAL_DATA}`;
  return postData(url, values);
}

// get Food & Mood Journal data
export function getFoodMoodJournalData(data, callback) {
  let { values } = data;
  let url = `${MAIN_PATH}${GET_FOOD_MOOD_JOURNAL_DATA}`;
  return postData(url, values);
}

// get Food & Mood Journal progress state
export function getFoodMoodJournalProgress(id) {
  let url = `${MAIN_PATH}${GET_FOOD_MOOD_JOURNAL_PROGRESS}${id}`;
  return getData(url);
}

// submit Food & Mood Journal
export function submitFoodMoodJournal(id) {
  let url = `${MAIN_PATH}${SUBMIT_FOOD_MOOD_JOURNAL}${id}`;
  return postData(url);
}

// view Food & Mood Journal submitted data
export function viewFoodMoodSubmittedData(id) {
  let url = `${MAIN_PATH}${VIEW_FOOD_MOOD_SUBMITTED_DATA}${id}`;
  return getData(url);
}

// save Food & Mood Journal notes
export function saveFoodMoodJournalNotes(id, values) {
  let url = `${MAIN_PATH}${SAVE_FOOD_MOOD_NOTES}${id}`;
  return postData(url, values);
}

// delete food/mood log
export function deleteFMJDayLog(values){
  let url = `${MAIN_PATH}${DELETE_FMJ_DAY_LOG}`;
  return deleteData(url, values);
}

// GET CLIENT APPOINTMENTS
export function getClientAppointments(data) {
  let url = `${MAIN_PATH}${GET_CLIENT_APPOINTMENTS.replace(
    ":id",
    data?.id
  )}?limit=${data?.limit || 10}`;
  return getData(url);
}

//POST APPOINTMENTS ORDER

export function postAppointmentOrders(data) {
  let url = `${MAIN_PATH}${POST_APPOINTMENTS_ORDER}`;
  return postData(url, data);
}

//POST FORM ORDER
export function postFormOrders(data) {
  let url = `${MAIN_PATH}${POST_FORM_SORT_ORDER}`;
  return postData(url, data);
}

// get food mood log list
export function getFoodMoodLogsList(clientId, callback) {
  let url = `${MAIN_PATH}${GET_FOOD_MOOD_LOGS.replace(":id", clientId)}`;
  return (dispatch) =>
    getData(url).then((data) => {
      dispatch(getFoodMoodLogs(data, callback));
      return data;
    });
}

// add comment to food/mood log
export function addFoodMoodLogComment(values) {
  let url = `${MAIN_PATH}${ADD_COMMENT_FOOD_MOOD_LOGS}`;
  return postData(url, values);
}

// client merge stats list
export function clientMergeStatsList(clientId) {
  let url = `${MAIN_PATH}${GET_CLIENT_STATS_LIST}?clientId=${clientId}`;
  return getData(url);
}

// merge clients
export function mergeClients(values) {
  let url = `${MAIN_PATH}${MERGE_CLIENTS}`;
  return postData(url, values);
}