import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Accordion,
  Button,
  Dropdown,
} from "react-bootstrap";

// css
import "./Sidebar.scss";

// images
import logo from "../../Assets/Images/logo.png";
import dp from "../../Assets/Images/AuthImg1.png";

// icns
import { ReactComponent as DashIcn } from "../../Assets/Images/icn/Dashboard.svg";
import { ReactComponent as UsersIcn } from "../../Assets/Images/icn/Users.svg";
import { ReactComponent as V3DotsIcn } from "../../Assets/Images/icn/V3dots.svg";
import { ReactComponent as CloseBtn } from "../../Assets/Images/icn/close.svg";
import { ReactComponent as ResearchIcn } from "../../Assets/Images/icn/research.svg";
import { ReactComponent as CalenderIcn } from "../../Assets/Images/icn/Calender2.svg";
import { ReactComponent as DocIcn } from "../../Assets/Images/icn/docWhite.svg";
import { ReactComponent as SettingIcn } from "../../Assets/Images/icn/setting.svg";
import { toast } from "react-toastify";

import { logout } from "store/services/authService";
import * as actions from "store/actions";
import * as Path from "Routes/paths";
import { hasActivePlan } from "helpers/common";

const Sidebar = ({
  dispatch,
  user,
  planStatus,
  showSidebar,
  setSidebar,
  freeAccount,
}) => {
  const navigate = useNavigate();
  const handleSidebar = () => {
    setSidebar(!showSidebar);
  };

  //logout function
  const handleLogout = async () => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      const authToken = user ? user?.access_token : "";
      await dispatch(logout(authToken)).then((res) => {
        navigate("/login");
        dispatch(actions.persist_store({ loader: false }));
      });
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownLinkClick = () => {
    // Close the dropdown when a link is clicked
    setIsDropdownOpen(false);
  };
  return (
    <>
      <div className={`sidebar py-2 pt-4 ${showSidebar && "active"}`}>
        <Button
          onClick={handleSidebar}
          className="border-0 p-0 position-absolute closeBtn rounded-0 d-flex align-items-center justify-content-center d-lg-none"
          variant="transparent"
          style={{ right: "0", top: "0" }}
        >
          <CloseBtn />
        </Button>
        <div className="top py-3 mb-4 px-3">
          <Link
            to={
              user &&
              hasActivePlan(user) &&
              user?.terms_accepted === "yes"
                ? "/dashboard"
                : "#"
            }
            className="text-dark logo"
          >
            <img src={logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div className="LinksWrpper d-flex flex-column justify-content-between">
          <Accordion>
            <div className="LinksInner">
              <ul className="list-unstyled ps-0 mb-0">
                <li className="my-2 position-relative px-3">
                  {/* <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Dashboard</Tooltip>}
                  > */}
                  <NavLink
                    to={
                      user &&
                      hasActivePlan(user) &&
                      user?.terms_accepted === "yes"
                        ? "/dashboard"
                        : "#"
                    }
                    className={`${
                      user &&
                      hasActivePlan(user) &&
                      user?.terms_accepted === "yes"
                        ? "link"
                        : "link-disabled"
                    } d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn">
                      <DashIcn />
                    </span>
                    Dashboard
                  </NavLink>
                  {/* </OverlayTrigger> */}
                </li>
                <li className="my-2 position-relative px-3">
                  {/* <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">My Clients</Tooltip>}
                  > */}
                  <NavLink
                    to={
                      user &&
                      hasActivePlan(user) &&
                      user?.terms_accepted === "yes"
                        ? "/my-clients"
                        : "#"
                    }
                    className={`${
                      user &&
                      hasActivePlan(user) &&
                      user?.terms_accepted === "yes"
                        ? "link"
                        : "link-disabled"
                    } d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn">
                      <UsersIcn />
                    </span>
                    My Clients
                  </NavLink>
                  {/* </OverlayTrigger> */}
                </li>
                <li className="my-2 position-relative px-3">
                  {/* <Dropdown
                    className=" text-center"
                    show={isDropdownOpen}
                    onToggle={handleDropdownClick}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      className="position-relative noAfter link"
                      id="dropdown-basic"
                    >
                      <DocIcn />
                    </Dropdown.Toggle>
                    {user && planStatus === "yes" && (
                      <Dropdown.Menu className="border-0">
                        <ul className="list-unstyled ps-0 mb-0">
                          <li className="">
                            <Link
                              onClick={handleDropdownLinkClick}
                              to={
                                user && (planStatus === "no") ? "#" : "/my-docs"
                              }
                              className="text-dark fw-sbold px-3 py-1 d-flex"
                            >
                              My Docs
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              onClick={handleDropdownLinkClick}
                              to={
                                user && (planStatus === "no") ? "#" : "/my-forms"
                              }
                              className="text-dark fw-sbold px-3 py-1 d-flex"
                            >
                              My Forms
                            </Link>
                          </li>
                          <li className="">
                            <Link
                              onClick={handleDropdownLinkClick}
                              to={
                                user && (planStatus === "no") ? "#" : "/resources"
                              }
                              className="text-dark fw-sbold px-3 py-1 d-flex"
                            >
                              Resources
                            </Link>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    )}
                  </Dropdown> */}
                  <Accordion.Item
                    className="border-0 bg-transparent"
                    eventKey="0"
                  >
                    <Accordion.Header className=" position-relative text-white">
                      <span className="icn mb-2">
                        <DocIcn />
                      </span>
                      Paper Work
                    </Accordion.Header>
                    <Accordion.Body className="py-0 px-0">
                      {user &&
                        hasActivePlan(user) &&
                        user?.terms_accepted === "yes" && (
                          <ul className="list-unstyled ps-0 mb-0">
                            <li className="">
                              <NavLink
                                onClick={handleDropdownLinkClick}
                                to={
                                  user &&
                                  (planStatus === "yes" ||
                                    freeAccount === "yes") &&
                                  user?.terms_accepted === "yes"
                                    ? "/my-docs"
                                    : "#"
                                }
                                className=""
                              >
                                My Docs
                              </NavLink>
                            </li>
                            <li className="">
                              <NavLink
                                onClick={handleDropdownLinkClick}
                                to={
                                  user &&
                                  (planStatus === "yes" ||
                                    freeAccount === "yes") &&
                                  user?.terms_accepted === "yes"
                                    ? "/my-forms"
                                    : "#"
                                }
                                className=""
                              >
                                My Forms
                              </NavLink>
                            </li>
                            {/* <li className="">
                              <NavLink
                                onClick={handleDropdownLinkClick}
                                to={
                                  user &&
                                  (planStatus === "yes" ||
                                    freeAccount === "yes") &&
                                  user?.terms_accepted === "yes"
                                    ? "/resources"
                                    : "#"
                                }
                                className=""
                              >
                                Resources
                              </NavLink>
                            </li> */}
                          </ul>
                        )}
                    </Accordion.Body>
                  </Accordion.Item>
                </li>
                {/* <li className="my-2 position-relative px-3">
                  <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Research</Tooltip>}
                  >
                    <NavLink
                      to={user && (planStatus === "no") ? "#" : "/food-research"}
                      className={`${
                        user && (planStatus === "no") ? "link-disabled" : "link"
                      } d-flex position-relative align-items-center text-white`}
                    >
                      <span className="icn me-3 me-lg-0">
                        <ResearchIcn />
                      </span>
                      Research
                    </NavLink>
                  </OverlayTrigger>
                </li> */}
                <li className="my-2 position-relative px-3">
                  {/* <OverlayTrigger
                    placement="right"
                    arrow={false}
                    overlay={<Tooltip id="tooltip">Appointment</Tooltip>}
                  > */}
                  <NavLink
                    to={
                      user &&
                      hasActivePlan(user) &&
                      user?.terms_accepted === "yes"
                        ? "/appointment"
                        : "#"
                    }
                    className={`${
                      user &&
                      hasActivePlan(user) &&
                      user?.terms_accepted === "yes"
                        ? "link"
                        : "link-disabled"
                    } d-flex position-relative align-items-center text-white`}
                  >
                    <span className="icn">
                      <CalenderIcn />
                    </span>
                    Appointment
                  </NavLink>
                  {/* </OverlayTrigger> */}
                </li>
              </ul>
            </div>
          </Accordion>
          <div className="bottom px-2 pb-3">
            <Dropdown className="profileDropdown text-center">
              <Dropdown.Toggle
                variant="transparent"
                className="position-relative p-0 noAfter"
                id="dropdown-basic"
              >
                <img src={dp} alt="" className="img-fluid h-100 w-100" />
                <span className="position-absolute d-flex align-items-center justify-content-center">
                  <SettingIcn />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-0">
                <ul className="list-unstyled ps-0 mb-0">
                  <li className="">
                    <Link
                      to={
                        user &&
                        hasActivePlan(user) &&
                        user?.terms_accepted === "yes"
                          ? "/my-account"
                          : "#"
                      }
                      className="text-dark fw-sbold px-3 py-1 d-flex"
                    >
                      My Account
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to={Path.helpCreateAccount}
                      target="_blank"
                      className="text-dark fw-sbold px-3 py-1 d-flex"
                    >
                      Help
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="#"
                      className="text-dark fw-sbold px-3 py-1 d-flex"
                    >
                      What's New
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      onClick={handleLogout}
                      className="text-dark fw-sbold px-3 py-1 d-flex"
                    >
                      Sign out
                    </Link>
                  </li>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            {/* <div
              className="cardCstm p-3"
              style={{
                backgroundImage: `url(${OrangeBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="top d-flex align-items-start justify-content-between gap-10">
                <div className="imgWrp">
                  <img src={dp} alt="" className="img-fluid" />
                </div>
                <div className="right">
                  <Dropdown className="rightMenu">
                    <Dropdown.Toggle
                      variant="transparent"
                      className="noAfter p-1 px-2 border-white rounded"
                      id="dropdown-basic"
                    >
                      <V3DotsIcn />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="noLastBorder">
                      <Link className="fw-sbold dropdown-item" to="/my-account">
                        My Account
                      </Link>
                      <Dropdown.Item className="fw-sbold" href="#/action-2">
                        Help
                      </Dropdown.Item>
                      <Dropdown.Item className="fw-sbold" href="#/action-3">
                        Whats New
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="content pt-3">
                <h6 className="fw-bold text-white m-0">John Smith</h6>
                <p className="m-0 text-white">joh_smith@demomail.com</p>
                <div className="btnWrp mt-2 d-flex align-itmes-center gap-10">
                  <Button className="d-flex fw-sbold align-items-center text-dark justify-content-center bg-white border-white">
                    Go to Profile
                  </Button>
                  <Link
                    to="/login"
                    className="text-white underline fw-sbold m-0 d-flex align-items-center "
                  >
                    {" "}
                    Logout
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  // console.log(state)
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    user: state.Profile.user,
    planStatus: state.Profile?.user?.is_plan_active,
    freeAccount: state.Profile?.user?.is_free_account,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Sidebar);