import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import { appointmentSlots_, rescheduleAppointment_ } from 'store/services/appointmentService';
import moment from "moment/moment";
import momentTZ from "moment-timezone";

// icn
import { ReactComponent as OTick } from "../../../../../../Assets/Images/icn/OrangeTick.svg";
import { currentDayFromMonth_, utcTimestamp_, currentDayFromMonth, bookingdate, bookingdatetime, convertTimezone, isDateValid, getAppointmentDateTime, convertLocalDateToUTC } from 'helpers/common'
const ReschedulePopup = ({ dispatch, reschedulePop, setReschedulePop, stepRes, setStepRes, setShowPopup, currentDetail, setCurrentDetail, accountList, setCurrentTypeData, currentTypeData, setBookApp }) => {
  const [timeZoneValue, setTimeZoneValue] = useState(moment.tz.guess())
  const localizer = momentLocalizer(moment);
  const [selectedDay_, setSelectedDay_] = useState(null);
  const [currentRescheduledSLot, setCurrentRescheduledSLot] = useState(null);

  const [selectedDate, setSelectedDate] = useState(new Date()); // State to track the selected date
  const [stateSlotsVal, setStateSlotsVal] = useState([])
  const [finalResResponse, setFinalResResponse] = useState()
  const [showButton, setShowButton] = useState(false)

  const [calendarError, setCalendarError] = useState(null);
  const [schedulerSettings, setSchedulerSettings] = useState({});

  useEffect(() => {
    if(accountList && accountList.length > 0) {
      let schedulerSettings = {};
      for(let i = 0; i < accountList.length; i++) {
        schedulerSettings[accountList[i]?.data_key] = accountList[i]?.data_set;
      }

      setSchedulerSettings(schedulerSettings);
    }
  }, [accountList])

  const checkDateValid = (date, debug = false) => {
    return isDateValid(date, schedulerSettings, debug);
  }

  const handleSelectEvent = event => {
    // Handle event selection if needed
    console.log('Event selected:', event);
  };

  const handleNavigate = date => {
    // Handle navigation to a new date
    if(!checkDateValid(date)) {
      setCalendarError("Please choose a valid date");
      return;
    }
    setSelectedDate(date);
    setCalendarError(null);
  };
  const handleSelectSlot = (slotInfo) => {
    const newSelectedDate = moment(slotInfo.start).toDate();
    if(!checkDateValid(newSelectedDate)) {
      setCalendarError("Please choose a valid date");
      return;
    }
    setSelectedDate(newSelectedDate);
    setCalendarError(null);
  };

  useEffect(() => {
    var parsedDate = moment(selectedDate);
    // Get the day name (full)
    var dayNameFull = parsedDate.format("dddd").toLowerCase();
    setSelectedDay_(dayNameFull);
    if (currentTypeData) {
      onClickAppointType(currentTypeData, dayNameFull)
    }
  }, [selectedDate])

  useEffect(() => {
    // if(!checkDateValid(currentDetail?.booking_date)) {
    //   setCalendarError("Please choose a valid date");
    //   return;
    // }
    // setSelectedDate(currentDetail?.booking_date)
  }, [currentDetail])

  const onClickAppointType = async (data, day) => {
    try {
      setCurrentTypeData(data);
      let finalObj = {
        day: day,
        duration: parseInt(data?.duration),
        hours: data?.available_working_hours,
        booking_date: convertLocalDateToUTC(selectedDate, "YYYY-MM-DD")
      }
      dispatch(actions.persist_store({ loader: true }));
      // setButtonDisable(true)
      let res = await dispatch(appointmentSlots_(data?.id, finalObj));
      dispatch(actions.persist_store({ loader: false }));
      setStateSlotsVal(res?.body?.slots)

    } catch (err) {
      console.log(err);
      // setButtonDisable(false)
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  useEffect(() => {
    if (accountList && accountList.length > 0) {
      for (let timeZone of accountList) {
        if (timeZone.data_key === 'scheduler_timezone') {
          setTimeZoneValue(timeZone.data_set)
        }
      }
    }
  }, [timeZoneValue])

  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color,
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
      width: "100%",
    };
    return {
      style,
    };
  };
  const Trips = [
    {
      title: "Trip to Paris",
      start: new Date(2023, 6, 15), // July 15, 2023
      end: new Date(2023, 6, 20), // July 20, 2023
    },
    {
      title: "Beach Vacation",
      start: new Date(2023, 7, 5), // August 5, 2023
      end: new Date(2023, 7, 12), // August 12, 2023
    },
    // Add more trips as needed
  ];
  const handleReschedulePop = () => {
    setReschedulePop(!reschedulePop);
    setCurrentDetail(null)
  }
  const cancelAppointmentPop_ = () => {
    setShowPopup(true)
    setReschedulePop(false)
  }
  const cancelRes_ = () => {
    setStepRes(3);
    setReschedulePop(true);
  }

  const slotselctForBooking = (slot) => {
    setCurrentRescheduledSLot(slot);
    setStepRes(2)
    setShowPopup(false)
    setReschedulePop(true)
  }

  const confirmResAppointment = async () => {
    try {
      let startDateTime = currentRescheduledSLot ? bookingdatetime(selectedDate) + ' ' + currentRescheduledSLot.start_time : '';
      let endDateTime = currentRescheduledSLot ? bookingdatetime(selectedDate) + ' ' + currentRescheduledSLot.end_time : '';
      let finalObj = {
        booking_date: bookingdate(selectedDate),
        // "start_time": currentRescheduledSLot ? bookingdatetime(selectedDate) + ' ' + currentRescheduledSLot.start_time : '',
        // "end_time": currentRescheduledSLot ? bookingdatetime(selectedDate) + ' ' + currentRescheduledSLot.end_time : '',
        // start_time: momentTZ.tz(moment(startDateTime, "YYYY-MM-DD h:mm A"), timeZoneValue).toDate(),
        // end_time: momentTZ.tz(moment(endDateTime, "YYYY-MM-DD h:mm A"), timeZoneValue).toDate(),
        start_time: getAppointmentDateTime(selectedDate, currentRescheduledSLot.start_time, timeZoneValue),
        end_time: getAppointmentDateTime(selectedDate, currentRescheduledSLot.end_time, timeZoneValue),
      }
      dispatch(actions.persist_store({ loader: true }));
      setShowButton(true)
      let res = await dispatch(rescheduleAppointment_(currentDetail?.id, finalObj));
      dispatch(actions.persist_store({ loader: false }));
      setShowButton(false)
      setFinalResResponse(res?.body)
      setBookApp(true)
      setStepRes(4)
    } catch (err) {
      console.log(err);
      setShowButton(false)
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const checkIfValidDay = (date) => {
    try {
      if(!schedulerSettings) {
        return;
      }
      let isValid = checkDateValid(date);
      if(!isValid) {
        return {
          className: "blocked-day"
        }
      }
      
    } catch(err) {
      // console.log("Error validating date", err);
    }
  }

  return (
    <>
      {stepRes === 1 &&
        <Modal
          show={reschedulePop}
          onHide={handleReschedulePop}
          backdrop="static"
          keyboard={false}
          scrollable="true"
          centered
          className="reschedulePop FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-sbold">Reschedule Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h2 className="m-0">
                  Your Appointment
                </h2>
                <p className="m-0 py-2">
                  Edit your previously booked appointment
                </p>
              </div>
              <div className="CardBody py-3">

                <div className="py-2">
                  <div
                    className="commonCardBox p-3"
                    style={{ background: "#f6f6f6" }}
                  >
                    <div className="top py-2">
                      <h4 className="m-0 fw-sbold">{currentDetail?.title}</h4>
                      <span className="text-muted m-0">
                        (duration : {currentDetail?.duration} minutes)
                      </span>
                    </div>

                    <div className="content">

                      <div className="py-2">
                        <h6 className="m-0">{moment(currentDetail?.start_time).format('hh:mm A')} to {moment(currentDetail?.end_time).format('hh:mm A')} on {currentDayFromMonth_(currentDetail?.start_time)}</h6>
                      </div>

                      {currentTypeData?.location_notes && (
                        <div className="py-2">
                          <p className="m-0">
                            <strong>
                              {currentTypeData?.location === 'by_phone'
                                ? 'By Phone' : currentTypeData?.location === 'in_person'
                                  ? 'In Person' : currentTypeData?.location === 'video_zoom'
                                    ? 'Video/Zoom' : ''
                              }{` `}
                              Instructions
                            </strong>
                          </p>
                          <p className="m-0">{currentTypeData?.location_notes}</p>
                        </div>
                      )}
                      
                      {currentTypeData?.additonal_notes && (
                        <div className="py-2">
                          <p className="m-0">
                            <strong>Additional Notes</strong>
                          </p>
                          <p className="m-0">{currentTypeData?.additonal_notes}</p>
                        </div>
                      )}
                      
                    </div>
                  </div>
                </div>
                <h6 className="m-0 fw-sbold">Need to reschedule or cancel ?</h6>
                <div className="top py-2">
                  <Link onClick={() => setStepRes(3)}>
                    <span className="text m-0">
                      Reschedule Appointment
                    </span>
                  </Link><br />
                  <Link onClick={() => cancelAppointmentPop_()}>
                    <span className="text m-0">
                      Cancel Appointment
                    </span>
                  </Link>
                </div>
                {currentDetail?.uuid && (
                  <span className="text-muted m-0">
                    Confirmation code: {currentDetail?.uuid}
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="FormbtnWrpper">
            <Button
              onClick={handleReschedulePop}
              className="d-flex align-items-center justify-content-center commonBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
      {stepRes === 2 &&
        <Modal
          show={reschedulePop}
          onHide={handleReschedulePop}
          backdrop="static"
          keyboard={false}
          scrollable="true"
          centered
          className="reschedulePop FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-sbold">Reschedule your Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h3 className="m-0">
                  Confirm reschedule appointment.
                </h3>
                <p className="m-0 py-2">
                  <Link onClick={() => cancelRes_()}>Cancel Rescheduling</Link>
                </p>
              </div>
              <div className="CardBody py-3">
                <div className="py-2">
                  <div
                    className="commonCardBox p-3"
                    style={{ background: "#f6f6f6" }}
                  >
                    <div className="top py-2">
                      <h4 className="m-0 fw-sbold">{currentDetail?.title}</h4>
                      <span className="text-muted m-0">
                        (duration : {currentDetail?.duration} minutes)
                      </span>
                    </div>
                    <div className="py-2">
                      <h6 className="m-0">{(currentRescheduledSLot?.start_time)} to {(currentRescheduledSLot?.end_time)} on {currentDayFromMonth_(selectedDate, timeZoneValue)}</h6>
                    </div>
                    <br />
                    <h6 className="m-0 fw-sbold">What's Changing:</h6>
                    <div className="top py-2">
                      <span className="text m-0">
                        <Link to="#">Old Appointment</Link>: {moment(currentDetail?.start_time).format('hh:mm A')} to {moment(currentDetail?.end_time).format('hh:mm A')} on {currentDayFromMonth_(currentDetail?.booking_date, timeZoneValue)}
                      </span><br />
                      <span className="text m-0">
                        <Link to="#">New Appointment</Link>: {(currentRescheduledSLot?.start_time)} to {(currentRescheduledSLot?.end_time)} on {currentDayFromMonth_(selectedDate, timeZoneValue)}
                      </span>
                      <br />
                    </div>
                    <br />
                    <span className="text-muted m-0">
                      Please confirm that the updated data and time is correct. Once you click'Confirm Rescheduled Appointment' your old appointment will be canceled and rescheduled to the updated date and time above.
                    </span>
                  </div>
                </div>
                <div className="d-flex gap-10">
                  <Button
                    className="d-flex align-items-center justify-content-center btnSm btn-danger"
                    onClick={() => cancelAppointmentPop_()}
                  >
                    Cancel Rescheduling
                  </Button>
                  <Button
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                    onClick={() => confirmResAppointment()}
                    disabled={showButton ? true : false}
                  >
                    {showButton ? 'Wait...' : 'Confirm Rescheduled Appointment'}
                  </Button>
                </div>

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="FormbtnWrpper">
            <Button
              onClick={handleReschedulePop}
              className="d-flex align-items-center justify-content-center commonBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
      {stepRes === 3 &&
        <Modal
          show={reschedulePop}
          onHide={handleReschedulePop}
          backdrop="static"
          keyboard={false}
          scrollable="true"
          centered
          className="reschedulePop FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-sbold"> Reschedule your Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="commenContent commonCardBox p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">

                <p className="m-0">
                  Select a highlighted date in the calendar to view
                  available times below.
                </p>
                <p className="m-0">
                  All dates/times are in <b> {timeZoneValue}</b>{" "}
                  time zone.
                </p>
                <ul className="list-unstyled ps-0 mb-0 d-flex align-items-center py-2 gap-10">
                  <li className="">
                    <Button
                      onClick={() => cancelAppointmentPop_()}
                      className="themeLink fw-sbold border-0 p-0"
                      variant="transparent"
                    >
                      Cancel Rescheduling
                    </Button>
                  </li>
                </ul>
              </div>
              <div className="py-2">
                <h6 className="m-0">{currentDetail?.title}</h6>
              </div>
              <div className="py-2">
                <h6 className="m-0">Duration : {currentDetail?.duration} minutes</h6>
              </div>
              {currentDetail?.is_paid_appointment === 'yes' &&
                <div className="py-2">
                  <h6 className="m-0">Price : {currentDetail?.currency_sign}{currentDetail?.price}</h6>
                </div>
              }
              <div className="CardBody py-3">
                <Row>
                  <Col lg="12" className="my-2">
                    <Calendar
                      localizer={localizer}
                      events={Trips}
                      eventPropGetter={eventStyleGetter}
                      startAccessor="start"
                      endAccessor="end"
                      onSelectEvent={handleSelectEvent}
                      defaultDate={selectedDate}
                      views={['month']}
                      onNavigate={handleNavigate}
                      style={{ height: 500 }}
                      selectable
                      selected={selectedDate}
                      onSelectSlot={handleSelectSlot}
                      dayPropGetter={checkIfValidDay}
                    />
                    {calendarError &&
                      <p className="invalid-feedback d-block">{calendarError}</p>
                    }
                  </Col>
                  <Col lg="12" className="my-2">
                    <div className="commonContent">
                      <h4 className="m-0 py-2">
                        Open Times on {currentDayFromMonth_(selectedDate, timeZoneValue)}
                      </h4>
                      {timeZoneValue && (
                        <p>All dates/times are in <strong>{timeZoneValue}</strong> time zone.</p>
                      )}
                      <ul className="list-unstyled ps-0 mb-0 py-2">
                        {stateSlotsVal.length > 0 && stateSlotsVal.map((slot, i) => {
                          return (
                            <li className="py-1" key={i}>
                              <div
                                className="commonCardBox p-2 d-flex align-items-center justify-content-between gap-10 flex-wrap"
                                style={{ background: "#f6f6f6" }}
                              >
                                <h6 className="m-0 py-2">
                                  {slot.start_time} to {slot.end_time} on {currentDayFromMonth(selectedDate, timeZoneValue)}
                                </h6>
                                <Button
                                  onClick={() => slotselctForBooking(slot)}
                                  className="d-flex align-items-center justify-content-center commonBtn btnSm px-4"
                                  disabled={slot?.is_booked}
                                >
                                  Select
                                </Button>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      }
      {stepRes === 4 &&
        <Modal
          show={reschedulePop}
          onHide={handleReschedulePop}
          backdrop="static"
          keyboard={false}
          scrollable="true"
          centered
          className="reschedulePop FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title className="fw-sbold">Your Appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="px-lg-4">
            <div className="commenContent commonCardBox px-lg-4 p-3 bg-white">
              <div className="top py-2 border-bottom bg-white ">
                <h3 className="m-0">
                  Apointment Rescheduled!
                </h3>
              </div>
              <div className="CardBody py-3">
                <div className="py-2">
                  <span className="text-muted m-0">
                    Your appointment has been rescheduled. We have also sent you an email confirmations as well.
                  </span>
                  <div
                    className="commonCardBox p-3"
                    style={{ background: "#f6f6f6" }}
                  >
                    <div className="top py-2">
                      <h4 className="m-0 fw-sbold">{finalResResponse?.title}</h4>
                      <span className="text-muted m-0">
                        (duration : {finalResResponse?.duration} minutes)
                      </span>
                    </div>
                    <div className="py-2">
                      <h6 className="m-0">{moment(finalResResponse?.start_time).format('hh:mm A')} to {moment(finalResResponse?.end_time).format('hh:mm A')} on {currentDayFromMonth_(finalResResponse?.booking_date)}</h6>
                    </div>
                    <br />
                    <span className="text-success m-0">
                      Everything is complete. Your confirmation code is {finalResResponse.uuid}. <br />
                      Please clcik the 'Close Appointment Window' button to close this window.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="FormbtnWrpper">
            <Button
              onClick={handleReschedulePop}
              className="d-flex align-items-center justify-content-center commonBtn"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    accountList: state?.AccountSetting?.accountSettingList,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ReschedulePopup);
