import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { decode } from 'html-entities';
import { chartOptions, dangerSignAnnotation, chartMax, chartMaxSuper, lineAnnotation } from 'components/shared/ChartConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const LineChart = forwardRef(function Chart({ chartData, height, screenSize }, ref) {
  const [isLoading, setLoading] = useState(true);
  const [formattedData, setFormattedData] = useState(null);
  const [options, setOptions] = useState(chartOptions)
  
  const chartRef = useRef();

  useEffect(() => {
    if(chartData) {
      const labels = chartData?.line?.map(row => {
        return `${decode(row.title)}`;
      })
      
      let dangerLabels = {};
      const dataset = chartData?.line?.map((row, i) => {
        let percentOfMax = row.percentOfMax;
        let origValue = (percentOfMax * chartMax);
        let lineValue = origValue;

        // keeping a reserved space for the danger sign so adding +5
        if((origValue + 5) > chartMaxSuper) {
          lineValue = chartMaxSuper;
        }
        if(percentOfMax == 0.99) {
          dangerLabels[`danger_${i}`] = dangerSignAnnotation(i, (origValue + 5 > chartMaxSuper ? chartMaxSuper - 15 : lineValue));
        }
        return lineValue;
      })

      const chartLabels = {};
      for(let i = 0; i < labels.length; i++) {
        chartLabels[`chartLabel${i}`] = {
          type: 'label',
          xValue: i,
          yValue: 2.5,
          content: [chartData?.line[i]?.rawTotal],
          font: {
            size: 15
          },
          // rotation: 270,
          position: "center",
          align: "left",
        }
      }

      const data = {
        labels: labels,
        datasets: [
          {
            label: '',
            data: dataset,
            backgroundColor: chartData?.line && chartData?.line[0].color,
            pointRadius: 6,
            pointHoverRadius: 8,
            skipNull: false,
          }
        ],
      };

      let xAdjustValue = (function (){
        switch(screenSize) {
          case "extra-small":
            return -15;
          case "small":
            return -18;
          case "medium":
            return -20;
          case "large":
            return -25;
          default:
            return -25;
        }
      })();
      
      let newOptions = {
        ...options,
        scales: {
          ...options.scales,
          x: {
            ...options.scales.x,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
              padding: 10,
              color: "#000",
              font: {
                size: 16,
              }
            },
            grid: {
              display: true
            }
          }
        },
        plugins: {
          ...options.plugins,
          tooltip: {
            callbacks: {
                label: function(context) {
                  if(chartData) {
                    let label = chartData.line[context.dataIndex]?.rawTotal;
                    return label;
                  }
                },
                title: function(context) {
                  if(chartData) {
                    let title = chartData.line[context[0].dataIndex]?.title;
                    return decode(title);
                  }
                }
            }
          },
          annotation: {
            ...options.plugins.annotation,
            annotations: {
              ...options.plugins.annotation.annotations,
              ...chartLabels,
              ...dangerLabels,
              label1: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 1.2),
                content: ['Low Priority'],
              },
              label2: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax - (chartMax / 2),
                content: ['Medium Priority'],
              },
              label3: {
                ...lineAnnotation,
                xAdjust: xAdjustValue,
                yValue: chartMax / 1.2,
                content: ['High Priority'],
              }, 
            }
          }
        }
      }

      setFormattedData(data);
      setLoading(false);
      setOptions(newOptions);
    }
  }, [chartData, screenSize]);

  useImperativeHandle(ref, () => {
    return {
      downloadChartImage() {
        if(chartRef.current) {
          const base64Image = chartRef.current.toBase64Image('image/png', 1);
          return base64Image;
        }
      }
    }
  });

  if(!chartData) {
    return ""
  }

  return (
    <>
      {isLoading ? 
        <>
          <p>Loading...</p>
        </>
        :
        <Line
          ref={chartRef}
          options={options}
          data={formattedData}
          height={height}
        />
      }
    </>
  );
})