import { FOOD_MOOD_DIRECTIONS_PDF } from "common/constants";
import moment from "moment";

export const fieldLabels = {
  foods: "Foods",
  drinks: "Drinks",
  supplements: "Supps, Herbs & Meds",
  feeling: "Energy & Mood",
  movement: "Movement & Relaxation",
  digestion: "Digestion & Reactions"
}

export const timeRowFields = {
  foods: {
    value: ""
  },
  drinks: {
    value: ""
  },
  supplements: {
    value: ""
  },
  feeling: {
    value: ""
  },
  movement: {
    value: ""
  },
  digestion: {
    value: ""
  }
}

export const fieldInstructions = {
  foods: "Write down everything you eat, including all meals, snacks, etc.",
  drinks: "Write down everything you drink, including coffee, tea, alcohol, water, etc",
  supplements: "Write down the product name and dosage of each supplement, herb, or medication you take.",
  feeling: "Keep track of your energy level and mood throughout the day, especially as related to meals.",
  movement: "Track the type, time, and intensity of all movement and exercise. If you engage in any relaxation activities, note the type and duration.",
  digestion: `Monitor your digestion throughout the day, noting any bloating, gas, nausea, abdominal cramps, etc. If you have any reactions to specific foods, drinks, etc., note them here. If you have a bowel movement, note the time and <a target="_blank" href="${FOOD_MOOD_DIRECTIONS_PDF}#page-2">Bristol number</a>.`
}

export const singleTimeRow = {
  start_time: "",
  end_time: "",
  ...timeRowFields
}

export const FMSingleDayFields = {
  log_date: "",
  wake_time: "00:00",
  sleep_time: "00:00",
  food_served: []
}

export const LOG_DATE_FORMAT = "YYYY-MM-DD";

export const TIME_FORMAT_24 = "HH:mm";

export const TIME_FORMAT_12 = "h:mm a";

export const getDefaultCurrentDayData = (date) => {
  return ({
    ...FMSingleDayFields,
    log_date: moment(date).format(LOG_DATE_FORMAT),
  })
}
