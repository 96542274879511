import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { decode } from 'html-entities';
import { FULL_NAME, saveAs, validatePageConditions, validateQuestionConditions } from 'helpers/common';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";
import { Link } from 'react-router-dom';
import { getTodoResponse } from 'store/services/clientService';
import { useShowLoader } from 'hooks/useShowLoader';

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0",
    marginBottom: "-30px"
  }
});

const tableStyles = StyleSheet.create({
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  row: {
    flexDirection: "row",
    alignItems: "baseline",
    borderBottom: "1px solid #ccc",
    // padding: "10px"
  },
  headerCell: {
    color: "#000",
    fontSize: "11px",
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    width: "8%",
    padding: "5px",
    paddingTop: "1px",
    paddingBottom: "1px",
    flexWrap: "wrap",
    alignSelf: "flex-end",
    borderBottom: "2px solid #ccc",
  },
  cell: {
    color: "#000",
    fontSize: "9px",
    width: "8%",
    padding: "2px",
    flexWrap: "wrap",
  },
});

const AllQuestionAnswersPDF = ({ client, date, todoId, linkType }) => {

  const showLoader = useShowLoader();

  const downloadPDFReport = async () => {
    try {
      showLoader(true);
      const todoDetails = await getNAQv2TodoDetails(todoId, client);
      const doc = <NAQ2QeuAnsDoc client={client} date={date} todoDetails={todoDetails} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, 'NAQ-Questions-Answers.pdf');
      showLoader(false);
    } catch(err) {
      console.log("Error downloading report", err);
      showLoader(false);
    }
  }

  return (
    linkType === "button" ? (
      <Button
        className="d-flex align-items-center justify-content-center commonBtn btnSm download-pdf-btn"
        onClick={downloadPDFReport}
      >
        <span className="icn me-2">
          <DownloadIcn />
        </span>
          All Questions/Answers
      </Button>  
    ):(
      <Link
        onClick={downloadPDFReport}
      >
        All Questions/Answers
      </Link>
    )
  )
}

export const NAQ2QeuAnsDoc = ({ client, date, todoDetails }) => {
  let questionCount = 0;
  return (
    <Document>
      <Page style={styles.page}>

        <View style={styles.pageHeader} fixed>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            NAQ Questions/Answers
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName} fixed>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>

        {todoDetails && todoDetails.map(page => {
          if(!page || page.visible === false) {
            return null;
          }
          // page.questions = page.questions.map((q) => {
          //   q.index = ++questionCount;
          //   return q;
          // })
          return (
            <View style={{ marginTop: "15px" }}>
              <View style={{ borderBottom: "1px solid #ccc" }}>
                <Text style={{fontSize: "14px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
                  {decode(page.page_title)}
                </Text>
              </View>
              {page.questions && page.questions.length > 0 &&
                <View style={styles.tableContainer}>
                  <QuestionsTable
                    items={page.questions}
                  />
                </View>
              }
            </View>
          )
        })}
        

        {/* Disclaimer */}
        <View style={styles.disclaimer} fixed>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

export const getNAQv2TodoDetails = async (todoId, client) => {
  if (!todoId) {
    return;
  }
  try {
    let data = {
      todoId,
      clientId: client.id
    };
    const response = await getTodoResponse(data);
    if (response.success === true) {
      const details = response.body;
      const formattedData = validateConditions(details);
      return formattedData;
    }
    return false;
  } catch (err) {
    console.log(err);
    return false;
  }
}

const QuestionsTable = ({ items }) => {
  const rows = items.map((question, i) => {
    if(!question || question.visible === false) {
      return null;
    }
    return (
      <View style={[tableStyles.row]} key={i} wrap={false}>
        <View style={[tableStyles.cell, { width: "40%" }]}>
          <View style={[tableStyles.row, { borderBottom: "none" }]} key={i}>
            <Text
              style={[tableStyles.cell, { 
                width: "15px",
                height: "15px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontWeight: "bold",
                fontFamily: "Helvetica-Bold",
                marginRight: "10px",
              }]}
            >
              {`${question.sorting}.`}
            </Text>
            {question.other_column_data && question.other_column_data.length > 0 &&
              question.other_column_data.map((option, i) => {
                return (
                  <Text
                    style={[tableStyles.cell, { 
                      width: "15px",
                      height: "15px",
                      borderBottom: "none",
                      border: `${option == question.answer ? "2px solid red" : "none"}`,
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }]}
                  >
                    {i}
                  </Text>
                )
              })
            }
          </View>
        </View>
        <Text style={[tableStyles.cell, { width: "60%" }]}>{decode(question.question)}</Text>
      </View>
    )
  });
  
  return (
    <View style={tableStyles.table}>
      {/* <View style={tableStyles.row}>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Question</Text>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Answer Score</Text>
        <Text style={[tableStyles.headerCell, { width: "33%", }]}>Answer</Text>
      </View> */}
      {rows}
    </View>
  )
};

const validateConditions = (allPages) => {
  let allQuestionAnswers = [];
  for(let i = 0; i < allPages.length; i++) {
    if(allPages[i] && allPages[i].questions && allPages[i].questions.length > 0) {
      allQuestionAnswers= [...allPages[i].questions, ...allQuestionAnswers];
    }
  }
  return allPages.map(page => {
    page.visible = validatePageConditions(page, allQuestionAnswers);
    page.questions = page.questions.map(q => {
      q.visible = validateQuestionConditions(q, allQuestionAnswers);
      return q;
    })
    return page;
  })
}

// const validatePageConditions = (page, questionAnswers) => {
//   try {
//     let showPage = false;
//     if(!page.page_depend_q_id || !page.page_depend_q_ans || !page.page_depend_q_ans?.length) {
//       showPage = true;
//     } else {
//       let dependQuestionAns = JSON.parse(page.page_depend_q_ans);
//       let questionIndex = questionAnswers.findIndex(q => ((q.id == page.page_depend_q_id) && q.visible));
//       let foundQuestion = questionAnswers[questionIndex];
//       let dependSourceAnswer = null;
//       if(questionIndex !== -1 && foundQuestion) {
//         dependSourceAnswer = foundQuestion.answer;
//         // if answer is singular and not an array
//         if(typeof dependSourceAnswer == "string") {
//           if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
//             showPage = true;
//           }
//         // if answer is an array, check if any of the given answers match the condition
//         } else if(Array.isArray(dependSourceAnswer)) {
//           let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
//           if(findMatch) {
//             showPage = true;
//           }
//         }
//       }
//     }
//     return showPage;
//   } catch(err) {
//     console.log("Error validating page conditions", err);
//     return true;
//   }
// }

// const validateQuestionConditions = (question, questionAnswers) => {
//   if(!question) {
//     return false;
//   }
//   let showQuestion = false;
//   if(!question.depend_q_id || !question.depend_q_ans || !question.depend_q_ans?.length) {
//     showQuestion = true;
//   } else {
//     let dependQuestionAns = JSON.parse(question.depend_q_ans);
//     let questionIndex = questionAnswers.findIndex(q => ((q.id == question.depend_q_id) && q.visible));
//     let foundQuestion = questionAnswers[questionIndex];
//     let dependSourceAnswer = null;
//     if(questionIndex !== -1 && foundQuestion) {
//       dependSourceAnswer = foundQuestion.answer;
//       // if answer is singular and not an array
//       if(typeof dependSourceAnswer == "string") {
//         if(dependQuestionAns?.length > 0 && dependQuestionAns.includes(dependSourceAnswer)) {
//           showQuestion = true;
//         }
//       // if answer is an array, check if any of the given answers match the condition
//       } else if(Array.isArray(dependSourceAnswer)) {
//         let findMatch = dependSourceAnswer.find(sourceAns => !!dependQuestionAns.find(ans => ans == sourceAns))
//         if(findMatch) {
//           showQuestion = true;
//         }
//       }
//     }
//   }
//   return showQuestion;
// }

export default AllQuestionAnswersPDF;