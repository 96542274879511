import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import RightSidebar from "./Component/RightSidebar/RightSidebar";
import { DELETE_ALERT } from "common/constantMessage";
import * as Yup from "yup";

// icn
import { ReactComponent as WPlus } from "../../../Assets/Images/icn/plus.svg";
import { ReactComponent as CalenderIcn } from "../../../Assets/Images/icn/Calender2.svg";
import { ReactComponent as OTick } from "../../../Assets/Images/icn/OrangeTick.svg";
import moment from "moment";
import AppointmentCalender from "./Component/AppointmentSchedule/Index";
import AddTimePop from "./Component/Modals/AddTimePop";
import AppointmentTypePop from "./Component/Modals/AppointmentTypePop";
import DeleteAppointmentPop from "./Component/Modals/DeleteAppointmentPop";
import AppointmentLinkPopUp from "./Component/Modals/AppointmentLinkPop";
import { FormikProvider } from "formik";
import ScheduleLearnMorePop from "./Component/AppointmentSchedule/Modals/ScheduleLearnMorePop";
import * as actions from "store/actions";
//appointment
import {
  timeZoneList_,
  updateSchedulerSetting_,
  workingHourList_,
  addWorkingHours_,
  appointmentTypeList_,
  appointmentTypeRemove_,
  appointmentTypeView_,
  appointmentStepsSaveTrackingData,
} from "store/services/appointmentService";
import StepWizard from "react-step-wizard";
import { getAccountSettingList_ } from "store/services/accountSettingService";
import {
  convertTo12HourFormat,
  dateFormatData_,
  parseTimeString,
} from "helpers/common";
import GetStarted from "./Component/Steps/GetStarted";
import SchedulerTimezone from "./Component/Steps/SchedulerTimezone";
import SchedulerDisplayName from "./Component/Steps/SchedulerDisplayName";
import SchedulerContactEmail from "./Component/Steps/SchedulerContactEmail";
import SchedulerContactEmailDisplay from "./Component/Steps/SchedulerContactEmailDisplay";
import SchedulerClientReminders from "./Component/Steps/SchedulerClientReminders";
import SchedulerSameDayAppointments from "./Component/Steps/SchedulerSameDayAppointments";
import SchedulerCancellationPolicy from "./Component/Steps/SchedulerCancellationPolicy";
import WorkingHoursSetting from "./Component/Steps/WorkingHoursSetting";
import AppointmentTypesSettings from "./Component/Steps/AppointmentTypesSettings";
import SchedulerBlockedDates from "./Component/Steps/SchedulerBlockedDates";
import FinishReview from "./Component/Steps/FinishReview";
import AppointmentCalendarStep from "./Component/Steps/AppointmentCalendarStep";
import SchedulerStatus from "./Component/Steps/SchedulerStatus";
import SchedulerFutureBookingAllowed from "./Component/Steps/SchedulerFutureBookingAllowed";

// images
import bookApptImg from "Assets/Images/bookAppt.png";

let noTransitions = {
  enterRight: "",
  enterLeft: "",
  exitRight: "",
  exitLeft: "",
};

const Appointment = ({
  dispatch,
  appointmentStepsData,
  accountSettingListData_,
  accountSettingDetails,
  hoursLists,
  appointTypeList,
  user,
}) => {
  const validationSchema = Yup.object().shape({
    from_date: Yup.string().required("Required"),
    to_date: Yup.string().required("Required"),
  });

  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };

  const todayDate = moment().format("YYYY-MM-DD");

  const [schedulerTimezone, setSchedulerTimezone] = useState(moment.tz.guess());
  const [editValueSetting, setEditValueSetting] = useState(false);
  const [AppointmentSidebar, setAppointmentSidebar] = useState("");
  const [timeRowPop, setTimeRowPop] = useState("");
  const [editDisplayName, setDisplayName] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [allUpdate, setAllUpdate] = useState(false);

  const [step, setStep] = useState(1);
  const [timeZone, setTimeZone] = useState("");
  const [appointmentPop, setAppointmentPop] = useState("");
  const [appointmentDeletePop, setAppointmentDeletePop] = useState("");
  const [appointmentLinkPop, setAppointmentLinkPop] = useState("");
  const [currentApptTypeId, setCurrentApptTypeId] = useState(null);
  const [scheduleSec, setScheduleSec] = useState("");
  const [stepType, setStepType] = useState(false);
  const [contentPop, setContentPop] = useState("");
  const [BlockAdd, setBlockAdd] = useState("");

  //timezone list
  const [buttonDisable_, setButtonDisable_] = useState(false);
  const [schedulerType, setSchedulerType] = useState(false);
  const [schedulerTypes, setSchedulerTypes] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [timeZoneListing, setTimeZoneListing] = useState([]);
  //working hours
  const [workingHours, setworkingHours] = useState([]);
  const [sending, setSending] = useState(false);
  const [currentDayValue, setCurrentDayValue] = useState(null);
  const [hoursFields, setHoursFields] = useState({
    start_time: "09:00",
    end_time: "17:00",
  });
  const [dbHoursFields, setDbHoursFields] = useState([]);

  const [accountList, setAccountList] = useState([]);
  const [accDetail, setAccDetail] = useState({
    contact_email: "",
  });
  const [accDetail1, setAccDetail1] = useState({
    contact_display_name: "",
  });
  //settings
  const [fields, setfields] = useState({
    scheduler_timezone: "",
    scheduler_display_name: "",
    scheduler_contact_email: "",
    scheduler_contact_email_display: "",
    scheduler_allow_cancellations: "",
    scheduler_client_reminders: "",
    scheduler_same_day_appointment: "",
    scheduler_cancellation_policy: "",
    scheduler_blocked_dates: "",
    scheduler_status: "",
    scheduler_future_date_booking_allowed: "",
  });
  const [fields1, setfields1] = useState({
    scheduler_allow_cancellations: "",
  });
  //appointment types
  const [appointmentTypeList, setAppointmentTypeList] = useState([]);
  const [updateAppType, setUpdateAppType] = useState(false);
  const [currentAppType, setCurrentAppType] = useState(null);

  const [typeFields, setTypeFields] = useState({
    title: "",
    duration: 15,
    buffer_before: "",
    buffer_after: "",
    description: "",
    additonal_notes: "",
    available_working_hours: "",
    location: currentAppType ? currentAppType.location : "",
    location_notes: "",
    in_person:
      currentAppType?.location === "in_person"
        ? currentAppType?.location_notes
        : "",
    video_zoom:
      currentAppType?.location === "video_zoom"
        ? currentAppType?.location_notes
        : "",
    by_phone:
      currentAppType?.location === "by_phone"
        ? currentAppType?.location_notes
        : "",
    video_url: "",
    price: "0.0",
    currency_code: "$USD",
  });

  const [availability, setAvailability] = useState(false);
  const [bufferType, setBufferType] = useState(false);
  const [availabileHours, setAvailabileHours] = useState([]);
  const [dbBlockTimeData, setdbBlockTimeData] = useState([]);

  //block data
  const [blockfields, setblockfields] = useState({
    full_day_block: true,
    from_date: "",
    to_date: "",
    from_time: "00:00",
    to_time: "00:00",
  });

  const handleScheduleSec = () => {
    setSchedulerType(false);
  };
  const handleScheduleSec_ = () => {
    setSchedulerType(false);
    setEditValueSetting(false);
    setStep("13");
  };
  const handleContentPop = () => setContentPop(!contentPop);

  const handleAppointmentSidebar = () => {
    setAppointmentSidebar(!AppointmentSidebar);
  };
  const handleTimeZone = () => {
    setTimeZone(!timeZone);
  };
  const handleDisplayName = () => {
    setDisplayName(!editDisplayName);
  };
  const handleAddTime = (hours) => {
    setTimeRowPop(!timeRowPop);
    setCurrentDayValue(hours);
  };

  const handleAppointmentPop = () => {
    setAppointmentPop(!appointmentPop);
    setTypeFields({});
    setAvailability(false);
    setBufferType(false);
  };

  const handleAppointmentLinkPop = (apptTypeId) => {
    setCurrentApptTypeId(apptTypeId || null);
    setAppointmentLinkPop(!appointmentLinkPop);
  };

  //get time zone list
  async function getListTimeZone() {
    try {
      let res = await dispatch(timeZoneList_());
      setTimeZoneListing(res?.body);
    } catch (err) {
      console.log(err);
    }
  }
  //setting
  async function accountSettingList() {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getAccountSettingList_());
      setAccountList(res?.body);
      dispatch(actions.persist_store({ loader: false }));
      for (let block of res?.body) {
        if (block.data_key === "scheduler_blocked_dates") {
          setdbBlockTimeData(block.data_set ? JSON.parse(block.data_set) : []);
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  useEffect(() => {
    getListTimeZone();
    accountSettingList();
  }, []);

  //working hours
  async function getWorkingHoursList_() {
    try {
      let res = await dispatch(workingHourList_());
      setworkingHours(res?.body);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getWorkingHoursList_();
  }, []);

  // Handle changes for text inputs, checkboxes, and radio buttons
  const handleChange = useCallback(
    (name) => (evt) => {
      const value =
        evt.target.type === "checkbox"
          ? evt.target.checked
          : evt.target.type === "radio"
            ? evt.target.value
            : evt.target.value;
      setfields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    []
  );

  useEffect(() => {
    let fields_ = { ...fields };
    for (let val of accountList) {
      fields_ = {
        ...fields_,
        scheduler_timezone:
          val.data_key === "scheduler_timezone"
            ? val.data_set
            : fields_.scheduler_timezone,
        scheduler_display_name:
          val.data_key === "scheduler_display_name"
            ? val.data_set
            : fields_.scheduler_display_name,
        scheduler_contact_email:
          val.data_key === "scheduler_contact_email"
            ? val.data_set
            : fields_.scheduler_contact_email,
        scheduler_contact_email_display:
          val.data_key === "scheduler_contact_email_display"
            ? val.data_set
            : fields_.scheduler_contact_email_display,
        scheduler_client_reminders:
          val.data_key === "scheduler_client_reminders"
            ? val.data_set
            : fields_.scheduler_client_reminders,
        scheduler_same_day_appointment:
          val.data_key === "scheduler_same_day_appointment"
            ? val.data_set
            : fields_.scheduler_same_day_appointment,
        scheduler_cancellation_policy:
          val.data_key === "scheduler_cancellation_policy"
            ? val.data_set
            : fields_.scheduler_cancellation_policy,
        scheduler_allow_cancellations:
          val.data_key === "scheduler_allow_cancellations"
            ? val.data_set
            : fields_.scheduler_allow_cancellations,
        scheduler_blocked_dates:
          val.data_key === "scheduler_blocked_dates"
            ? val.data_set
            : fields_.scheduler_blocked_dates,
        scheduler_status:
          val.data_key === "scheduler_status"
            ? val.data_set
            : fields_.scheduler_status,
        scheduler_future_date_booking_allowed:
          val.data_key === "scheduler_future_date_booking_allowed"
            ? val.data_set
            : fields_.scheduler_future_date_booking_allowed,
      };
    }
    setfields(fields_);
    setfields1({
      scheduler_allow_cancellations: fields_.scheduler_allow_cancellations,
    });

    if (fields_.scheduler_timezone && fields_.scheduler_timezone != "disable") {
      setSchedulerTimezone(fields_.scheduler_timezone);
    }
  }, [accountList]);

  const handleChangeOptions = useCallback(
    (name, id) => async (evt) => {
      const value = evt.target.value;
      let obj = {
        data_set: value,
      };
      await dispatch(updateSchedulerSetting_(id, obj));
      setfields1((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [dispatch]
  );

  //update setting detail
  const submitData_ = async (type, fieldId, nextStep, schedulerType_) => {
    try {
      if (fields.scheduler_contact_email !== "") {
        setAccDetail({
          contact_email: fields.scheduler_contact_email,
        });
      } else if (fields.scheduler_display_name !== "") {
        setAccDetail1({
          contact_display_name: fields.scheduler_display_name,
        });
      }
      let obj;
      if (
        fields.scheduler_timezone !== "" ||
        fields.scheduler_display_name !== "" ||
        fields.scheduler_contact_email !== "" ||
        fields.scheduler_contact_email_display !== "" ||
        fields.scheduler_client_reminders !== "" ||
        fields.scheduler_same_day_appointment !== "" ||
        fields.scheduler_cancellation_policy !== "" ||
        fields.scheduler_future_date_booking_allowed !== "" ||
        fields.scheduler_status !== ""
      ) {
        obj = {
          data_set:
            type === "3"
              ? fields.scheduler_timezone
              : type === "4"
                ? fields.scheduler_display_name
                : type === "5"
                  ? fields.scheduler_contact_email
                  : type === "6"
                    ? fields.scheduler_contact_email_display
                    : type === "7"
                      ? fields.scheduler_client_reminders
                      : type === "8"
                        ? fields.scheduler_same_day_appointment
                        : type === "9"
                          ? fields.scheduler_cancellation_policy
                          : type === "14"
                            ? fields.scheduler_status
                            : type === "15"
                              ? fields.scheduler_future_date_booking_allowed
                              : "",
        };
        obj = JSON.stringify(obj);
        // return
        setButtonDisable(true);
        setTimeout(async () => {
          await dispatch(updateSchedulerSetting_(fieldId, obj));
          setfields({});
          // setStep(type);
          setButtonDisable(false);
          accountSettingList();
          if (schedulerType_) {
            setSchedulerType(true);
          } else {
            setStep(nextStep);
            // if(typeof nextStep === "function") {
            //   nextStep();
            // }
          }
        }, 1000);
      }
    } catch (err) {
      setButtonDisable(false);
      console.log(err, "==============================");
    }
  };

  //working hours
  // const handleChangeHours = useCallback(
  //   (name) => (evt) => {
  //     const value = evt.target.value;
  //     console.log(name, "name")
  //     console.log(value, "value")
  //     console.log(hoursFields, "hoursFields")
  //     if(name === 'start_time'){

  //     }
  //     else if(name === 'end_time'){

  //     }
  //     setHoursFields((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   },
  //   [hoursFields]
  // );
  const [errorTime, setErrorTime] = useState("");
  const handleChangeHours = (name, value) => {
    setErrorTime(""); // Clear previous error messages

    if (name === "start_time") {
      if (
        parseTimeString(value).isBefore(parseTimeString(hoursFields.end_time))
      ) {
        setHoursFields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
      } else {
        setErrorTime("Start time cannot be greater than end time");
      }
    } else if (name === "end_time") {
      if (
        parseTimeString(value).isAfter(parseTimeString(hoursFields.start_time))
      ) {
        setHoursFields((prevFields) => ({
          ...prevFields,
          [name]: value,
        }));
      } else {
        setErrorTime("End time cannot be less than start time");
      }
    }
  };

  useEffect(() => {
    let timing = currentDayValue?.timings;
    timing = currentDayValue ? JSON.parse(timing) : [];
    setDbHoursFields(timing);
  }, [currentDayValue]);
  //add hours
  const submitWorkingHours = async () => {
    try {
      let timeArr = [...dbHoursFields];
      timeArr.push(hoursFields);
      let obj = {
        day: currentDayValue?.day,
        timings: JSON.stringify(timeArr),
      };
      let hourId = currentDayValue?.id;
      obj = JSON.stringify(obj);
      setSending(true);
      setButtonDisable(true);
      await dispatch(addWorkingHours_(hourId, obj));
      setTimeRowPop(false);
      setSending(false);
      setButtonDisable(false);
      setCurrentDayValue(null);
      getWorkingHoursList_();
      setHoursFields({
        start_time: "09:00",
        end_time: "17:00",
      });
    } catch (err) {
      setSending(false);
      setButtonDisable(false);
      console.log(err, "==============================");
    }
  };
  //remove hours
  const removeHours_ = async (hours, time) => {
    try {
      let newArrVal = [];
      let timeArr = hours?.timings;
      timeArr = JSON.parse(timeArr);
      for (let val of timeArr) {
        if (
          val.start_time !== time.start_time &&
          val.end_time !== time.end_time
        ) {
          newArrVal.push(val);
        }
      }
      let obj = {
        day: hours?.day,
        timings: JSON.stringify(newArrVal),
      };
      let hourId = hours?.id;
      obj = JSON.stringify(obj);
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(addWorkingHours_(hourId, obj));
      getWorkingHoursList_();
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err, "==============================");
    }
  };

  //appointement type
  async function getListAppointmentTypes() {
    try {
      let res = await dispatch(appointmentTypeList_());
      setAppointmentTypeList(res?.body);
      setUpdateAppType(false);
      getLastStepValue();
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (updateAppType) {
      getListAppointmentTypes();
    } else {
      getListAppointmentTypes();
    }
  }, [updateAppType]);

  //delete appointment
  const deleteAppointmentType_ = async (id) => {
    try {
      await DELETE_ALERT().then((willDelete) => {
        if (willDelete) {
          try {
            dispatch(actions.persist_store({ loader: true }));
            dispatch(appointmentTypeRemove_(id)).then(() => {
              getListAppointmentTypes();
              dispatch(actions.persist_store({ loader: false }));
            });
          } catch (err) {
            console.log(err);
            dispatch(actions.persist_store({ loader: false }));
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  //edit appointment data
  const editAppointmentType = async (type) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(appointmentTypeView_(type?.id));
      if (res?.body) {
        setAppointmentPop(true);
        setCurrentAppType(res?.body);

        setTypeFields((prevState) => ({
          ...prevState,
          ...res?.body,
        }));

        setBufferType(
          (prevBufferType) =>
            (type?.buffer_after && type?.buffer_after) || false
        );
        setAvailability((prevAvailability) => {
          try {
            let availableHours = type?.available_working_hours;
            if (availableHours) {
              availableHours = JSON.parse(availableHours);
              if (!availableHours || availableHours.length == 0) {
                return false;
              }

              return true;
            }
          } catch (err) {
            return false;
          }
        });
        setAvailabileHours((prevHours) =>
          type?.available_working_hours
            ? JSON.parse(type?.available_working_hours)
            : []
        );
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };
  useEffect(() => { }, [currentAppType]);

  // Handle changes for text inputs, checkboxes, and radio buttons
  const handleChangeBlock_ = useCallback(
    (name) => (evt) => {
      const value =
        evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
      setblockfields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    [blockfields]
  );

  //submitBlockTimeData
  const submitBlockTimeData = async (fieldId, type) => {
    try {
      let newArr = [...dbBlockTimeData];
      const validation = await validateData(blockfields);
      if (validation.isValid) {
        let data = {
          full_day_block: blockfields.full_day_block,
          from_date: blockfields.from_date + " " + blockfields.from_time,
          to_date: blockfields.to_date + " " + blockfields.to_time,
          timezone: schedulerTimezone,
        };
        newArr.push(data);
        let obj = {
          data_set: JSON.stringify(newArr),
        };
        setButtonDisable_(true);
        await dispatch(updateSchedulerSetting_(fieldId, obj));
        setButtonDisable_(false);
        setBlockAdd(false);
        setStep(type);
        setblockfields({
          from_date: "",
          to_date: "",
          from_time: "",
          to_time: "",
          full_day_block: true,
        });
        accountSettingList();
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      console.log(err);
      setButtonDisable_(false);
    }
  };
  const removeBlockTime = async (fieldId, timeValue) => {
    try {
      let newArrVal = [];
      let timeArr = dbBlockTimeData;
      for (let val of timeArr) {
        if (
          val.full_day_block !== timeValue.full_day_block &&
          val.from_date !== timeValue.from_date &&
          val.to_date !== timeValue.to_date
        ) {
          newArrVal.push(val);
        }
      }
      let obj = {
        data_set: JSON.stringify(newArrVal),
      };
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(updateSchedulerSetting_(fieldId, obj));
      dispatch(actions.persist_store({ loader: false }));
      accountSettingList();
    } catch (err) {
      console.log(err);
      dispatch(actions.persist_store({ loader: false }));
    }
  };

  const findSetting = (key) => {
    // console.log("accountList", accountList)
    if (!accountList || !accountList.length > 0) {
      return {};
    }
    let setting = accountList.find((setting) => setting.data_key == key);
    return setting || {};
  };
  const shouldShowStep = (stepName) => {
    if (!appointmentStepsData || !appointmentStepsData.length > 0) {
      return true;
    }

    let stepSetting = appointmentStepsData.find(
      (step) => step.step_name == stepName
    );

    return !stepSetting || !stepSetting.is_updated;
  };

  const saveStepTracking = async (currentStepName) => {
    try {
      await dispatch(
        appointmentStepsSaveTrackingData({
          step_name: currentStepName,
          is_updated: true,
          step_number: step,
        })
      );
    } catch (err) {
      console.log("Error saving step tracking data", err);
    }
  };
  const saveStepTracking_ = async (currentStepName) => {
    try {
      setButtonDisable(true);
      await dispatch(
        appointmentStepsSaveTrackingData({
          step_name: currentStepName,
          is_updated: true,
          step_number: step,
        })
      );
      setButtonDisable(false);
      setStep(10);
    } catch (err) {
      console.log("Error saving step tracking data", err);
      setButtonDisable(false);
    }
  };
  const saveStepTrackings_ = async (currentStepName) => {
    try {
      setButtonDisable(true);
      await dispatch(
        appointmentStepsSaveTrackingData({
          step_name: currentStepName,
          step_number: step,
          is_updated: true,
        })
      );
      setButtonDisable(false);
      setStep(11);
    } catch (err) {
      console.log("Error saving step tracking data", err);
      setButtonDisable(false);
    }
  };
  useEffect(() => {
    // if(appointTypeList?.length==0){
    for (let listVal of accountSettingListData_) {
      if (listVal.data_key === "track_appointment_schedule_step") {
        let dataSetval = listVal?.data_set ? JSON.parse(listVal?.data_set) : [];

        let lastElement = dataSetval[dataSetval.length - 1];
        let step = null;
        if (dataSetval?.length == 0 || lastElement === undefined) {
          step = 1;
        } else if (lastElement?.step_number == 15) {
          step = 13;
        } else {
          step = parseInt(lastElement?.step_number) + 1;
        }
        
        if (step) {
          setStep(step);
        }
      }
    }
    // }
  }, [accountSettingListData_, appointTypeList]);

  const getLastStepValue = () => {
    for (let listVal of accountSettingListData_) {
      if (listVal.data_key === "track_appointment_schedule_step") {
        let dataSetval = listVal?.data_set ? JSON.parse(listVal?.data_set) : [];
        let lastElement = dataSetval[dataSetval.length - 1];

        if (dataSetval?.length == 0 || lastElement === undefined) {
          setStep(1);
        } else if (lastElement?.step_number === 15) {
          setStep(13);
        } else if (parseInt(lastElement?.step_number) < 15) {
          setStep(parseInt(lastElement?.step_number) + 1);
        }
      }
    }
  };

  const updateHours_ = (step) => {
    setStep(step);
    setSchedulerTypes(true);
    setStepType(true);
    // setSchedulerType(false)
    // setEditValueSetting(false)
  };

  return (
    <>
      <AddTimePop
        timeRowPop={timeRowPop}
        setTimeRowPop={setTimeRowPop}
        setCurrentDayValue={setCurrentDayValue}
        currentDayValue={currentDayValue}
        hoursFields={hoursFields}
        handleChangeHours={handleChangeHours}
        setErrorTime={setErrorTime}
        errorTime={errorTime}
        submitWorkingHours={submitWorkingHours}
        sending={sending}
        setHoursFields={setHoursFields}
      />
      <AppointmentTypePop
        appointmentPop={appointmentPop}
        setAppointmentPop={setAppointmentPop}
        workingHours={workingHours}
        setUpdateAppType={setUpdateAppType}
        setCurrentAppType={setCurrentAppType}
        currentAppType={currentAppType}
        setButtonDisable={setButtonDisable}
        buttonDisable={buttonDisable}
        typeFields={typeFields}
        setTypeFields={setTypeFields}
        setBufferType={setBufferType}
        bufferType={bufferType}
        setAvailability={setAvailability}
        availability={availability}
        setAvailabileHours={setAvailabileHours}
        availabileHours={availabileHours}
      />
      <DeleteAppointmentPop
        appointmentDeletePop={appointmentDeletePop}
        setAppointmentDeletePop={setAppointmentDeletePop}
      />
      <AppointmentLinkPopUp
        appointmentLinkPop={appointmentLinkPop}
        setAppointmentLinkPop={setAppointmentLinkPop}
        apptTypeId={currentApptTypeId}
        user={user}
      />
      <ScheduleLearnMorePop
        contentPop={contentPop}
        setContentPop={setContentPop}
      />
      <section className="Appointment position-relative d-flex align-items-start">
        <Container fluid>
          <Row>
            <Col lg="12" className="my-2">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="btnWrpper">
                  <Button
                    onClick={handleAppointmentSidebar}
                    className="d-flex align-items-center justify-cotent-center commonBtn btnSm d-xl-none"
                  >
                    <span className="icn">
                      <CalenderIcn />
                    </span>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>

          {schedulerType && !editValueSetting && (
            <>
              <div
                className="commonContent p-3 gettingStarted"
                style={{ background: "#fffef7", zIndex: "9" }}
              >
                <div className="top py-2 border-bottom d-flex align-items-center justify-content-between gap-10 flex-wrap">
                  <h3 className="m-0 fw-bold py-2 text-uppercase">
                    Scheduler Settings
                  </h3>
                  <Button
                    onClick={handleContentPop}
                    className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  >
                    Learn More
                  </Button>
                </div>
                <div className="CardBody py-2">
                  <p className="py-2 m-0">
                    Below are all of your Scheduler settings
                  </p>
                  <Row>
                    <Col lg="12">
                      <ul className="list-unstyled ps-0 mb-0 halfDividedList">
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Scheduler Id :
                          </span>
                          <span className="w-75 fw-sbold">{user.uuid}</span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Scheduler Status :
                          </span>
                          <span className="w-75 fw-sbold">
                            {accountSettingDetails?.scheduler_status ===
                              "disable"
                              ? "Disabled, clients cannot view your scheduler or book appointments"
                              : "Active, clients can view your scheduler and book appointments"}{" "}
                            <Button
                              onClick={() => {
                                setStep("14"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1 ankit"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Time Zone :
                          </span>
                          <span className="w-75 fw-sbold">
                            {accountSettingDetails?.scheduler_timezone}{" "}
                            <Button
                              onClick={() => {
                                setStep("2"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Display Name :
                          </span>
                          <span className="w-75 fw-sbold">
                            <span className="text-danger">
                              {accountSettingDetails?.scheduler_display_name ||
                                "You haven't added a display name"}
                            </span>
                            <Button
                              onClick={() => {
                                setStep("3"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Contact Email :
                          </span>
                          <span className="w-75 fw-sbold">
                            {accountSettingDetails?.scheduler_contact_email}
                            <Button
                              onClick={() => {
                                setStep("4"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Contact Email Display :
                          </span>
                          <span className="w-75 fw-sbold">
                            {accountSettingDetails?.scheduler_contact_email_display ===
                              "disable"
                              ? "No, do not display my contact email"
                              : "Yes, display my contact email"}
                            <Button
                              onClick={() => {
                                setStep("5"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Reminders :
                          </span>
                          <span className="w-75 fw-sbold">
                            {accountSettingDetails?.scheduler_client_reminders ===
                              "disable"
                              ? "No, do not send clients an appointment reminder"
                              : "Yes, send clients an appointment reminder the day before"}
                            <Button
                              onClick={() => {
                                setStep("6"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Same Day Appointments :
                          </span>
                          <span className="w-75 fw-sbold">
                            {
                              accountSettingDetails?.scheduler_same_day_appointment
                            }
                            <Button
                              onClick={() => {
                                setStep("7"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Allow Cancellations :
                          </span>
                          <span className="w-75 fw-sbold">
                            {
                              accountSettingDetails?.scheduler_allow_cancellations
                            }
                            <Button
                              onClick={() => {
                                setStep("8"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Cancellation Policy Text :
                          </span>
                          <span className="w-75 fw-sbold">
                            <i>
                              {
                                accountSettingDetails?.scheduler_cancellation_policy
                              }
                            </i>

                            <Button
                              onClick={() => {
                                setStep("8"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Working Hours :
                          </span>
                          <span className="w-75 fw-sbold">
                            <ul className="list-unstyled ps-0 mb-0">
                              {hoursLists?.length > 0 &&
                                hoursLists?.map((hours, i) => {
                                  let timeArr = hours?.timings
                                    ? JSON.parse(hours?.timings)
                                    : [];
                                  if (timeArr?.length > 0) {
                                    return (
                                      <li className="py-1" key={i}>
                                        <p className="m-0">
                                          {hours?.day.charAt(0).toUpperCase() +
                                            hours.day?.slice(1)}
                                          :{" "}
                                          <Button
                                            onClick={() => {
                                              setStep("9"),
                                                setSchedulerType(false),
                                                setEditValueSetting(false);
                                            }}
                                            className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                                            variant="transparent"
                                          >
                                            Edit
                                          </Button>
                                        </p>
                                        <ul className="ps-2 mb-0">
                                          {timeArr.length > 0 &&
                                            timeArr.map((time, j) => {
                                              return (
                                                <li
                                                  className="text-dark"
                                                  key={j}
                                                >
                                                  {convertTo12HourFormat(
                                                    time.start_time
                                                  )}{" "}
                                                  to{" "}
                                                  {convertTo12HourFormat(
                                                    time.end_time
                                                  )}
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Appointment Types :
                          </span>
                          <div>
                            {appointTypeList && appointTypeList.length > 0 && (
                              <ul className="list-unstyled p-0">
                                {appointTypeList.map((apptType) => {
                                  return (
                                    <li className="mb-3">
                                      <p className="fw-sbold text-dark m-0">
                                        {apptType.title}
                                      </p>
                                      <ul className="p-0">
                                        <li className="fw-sbold">
                                          (Duration: {apptType.duration || 0}{" "}
                                          minutes)
                                        </li>
                                        <li className="fw-sbold">
                                          <Button
                                            onClick={() => {
                                              handleAppointmentLinkPop(
                                                apptType.id
                                              );
                                            }}
                                            className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                                            variant="transparent"
                                          >
                                            Appointment Link
                                          </Button>
                                        </li>
                                        <li className="fw-sbold">
                                          <Button
                                            onClick={() => {
                                              setStep("10"),
                                                setSchedulerType(false),
                                                setEditValueSetting(false);
                                            }}
                                            className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                                            variant="transparent"
                                          >
                                            Edit Appointment
                                          </Button>
                                        </li>
                                      </ul>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                            <Button
                              onClick={() => {
                                setStep("10"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1 mt-2"
                              variant="transparent"
                            >
                              Add New Appointment Type
                            </Button>
                          </div>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Max Scheduling Date :
                          </span>
                          <span className="w-75 fw-sbold">
                            {
                              accountSettingDetails.scheduler_future_date_booking_allowed
                            }{" "}
                            in the future
                            <Button
                              onClick={() => {
                                setStep("15"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Schedule Exceptions :
                          </span>
                          <span className="w-75 fw-sbold">
                            {accountSettingDetails?.scheduler_blocked_dates
                              ? "Change any future dates blocked"
                              : "You don't have any future dates blocked"}
                            <Button
                              onClick={() => {
                                setStep("11"),
                                  setSchedulerType(false),
                                  setEditValueSetting(false);
                              }}
                              className="border-0 p-0 themeLink underline btnSm fw-sbold h-auto px-1"
                              variant="transparent"
                            >
                              Edit
                            </Button>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Scheduler URL :
                          </span>
                          <span className="w-75 fw-sbold">
                            <Link
                              className="underline"
                              to={`${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}`}
                            >
                              {process.env.REACT_APP_CLIENT_PORTAL_URL}
                              /public/calendar/{user?.uuid}
                            </Link>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Scheduler Link :
                          </span>
                          <span className="w-75 fw-sbold">
                            Add a link to your Appointment Scheduler with the
                            following code:
                            <Row>
                              <Col lg="12" className="my-2">
                                <div
                                  className="CardCstm SubCardBox px-lg-4 p-3 pay-4 h-100"
                                  style={{ background: "#051C27" }}
                                >
                                  <div className="content">
                                    <div
                                      className="p-3 innerCard"
                                      style={{ background: "#31444c" }}
                                    >
                                      <p className="text-white m-0">
                                        {`<a href="${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}" target="_blank" alt="Book an Appointment"><img style="height:34px;width:181px;" height="34" width="181" src="${process.env.REACT_APP_URL}${bookApptImg}"></a>`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </span>
                        </li>
                        <li className="py-2 d-flex align-items-start gap-10">
                          <span className="w-25 fw-sbold text-dark">
                            Scheduler Embed :
                          </span>
                          <span className="w-75 fw-sbold">
                            Add your Appointment Scheduler to your own web site
                            with the following code:
                            <Row>
                              <Col lg="12" className="my-2">
                                <div
                                  className="CardCstm SubCardBox px-lg-4 p-3 pay-4 h-100"
                                  style={{ background: "#051C27" }}
                                >
                                  <div className="content">
                                    <div
                                      className="p-3 innerCard"
                                      style={{ background: "#31444c" }}
                                    >
                                      <p className="text-white m-0">
                                        {`<iframe id="nutri-q-appt-view" src="${process.env.REACT_APP_CLIENT_PORTAL_URL}/public/calendar/${user?.uuid}" frameborder="no" style="width:1px;min-width:100%;height:"100vh"></iframe>`}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </span>
                        </li>
                      </ul>
                      <div className="FormbtnWrpper mt-4 pt-3 border-top ">
                        <Button
                          onClick={handleScheduleSec_}
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        >
                          Close Settings
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
          {!schedulerType && (
            <Row>
              {/* <StepWizard
                key={currentStepNumber}
                transitions={noTransitions}
                initialStep={currentStepNumber}
                onStepChange={(data) => {
                  setCurrentStepNumber(data.activeStep);
                  window.scrollTo(0, 0);
                }}
              > */}
              {/* setp 1 */}
              {/* {shouldShowStep("get_started") && */}
              {step == 1 && (
                <GetStarted
                  settingList={findSetting("scheduler_contact_email")}
                  handleNext={saveStepTracking}
                  setSchedulerType={setSchedulerType}
                  nextStep={2}
                  setStep={setStep}
                />
              )}
              {/* } */}
              {/* setp 2 */}
              {/* {shouldShowStep("timezone_settings") &&  */}
              {step == 2 && (
                <SchedulerTimezone
                  settingList={findSetting("scheduler_timezone")}
                  handleTimeZone={handleTimeZone}
                  handleChange={handleChange}
                  setfields={setfields}
                  timeZoneListing={timeZoneListing}
                  timeZone={timeZone}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleNext={saveStepTracking}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"timezone_settings"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  stepType={stepType}
                  setStepType={setStepType}
                  nextStep={3}
                />
              )}
              {/* } */}

              {/* step 3 */}
              {/* {shouldShowStep("display_name_settings") &&  */}
              {step == 3 && (
                <SchedulerDisplayName
                  settingList={findSetting("scheduler_display_name")}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleChange={handleChange}
                  fields={fields}
                  handleNext={saveStepTracking}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"display_name_settings"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={4}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}

              {/* step 4 */}
              {/* {shouldShowStep("contact_email_setting") &&  */}
              {step == 4 && (
                <SchedulerContactEmail
                  settingList={findSetting("scheduler_contact_email")}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  fields={fields}
                  buttonDisable={buttonDisable}
                  handleChange={handleChange}
                  handleNext={saveStepTracking}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"contact_email_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={5}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}

              {/* step 5 */}
              {/* {shouldShowStep("contact_email_display_setting") &&  */}
              {step == 5 && (
                <SchedulerContactEmailDisplay
                  settingList={findSetting("scheduler_contact_email_display")}
                  accDetail={accDetail}
                  accDetail1={accDetail1}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  fields={fields}
                  handleChange={handleChange}
                  handleNext={saveStepTracking}
                  buttonDisable={buttonDisable}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"contact_email_display_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={6}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}
              {/* step 6 */}
              {/* {shouldShowStep("client_reminder_settings") &&  */}
              {step == 6 && (
                <SchedulerClientReminders
                  settingList={findSetting("scheduler_client_reminders")}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  fields={fields}
                  handleChange={handleChange}
                  handleNext={saveStepTracking}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"client_reminder_settings"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={7}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}

              {/* step 7 */}
              {/* {shouldShowStep("same_day_appointment_setting") &&  */}
              {step == 7 && (
                <SchedulerSameDayAppointments
                  settingList={findSetting("scheduler_same_day_appointment")}
                  fields={fields}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleChange={handleChange}
                  handleNext={saveStepTracking}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"same_day_appointment_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={8}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}

              {/* step 8 */}
              {/* {shouldShowStep("cancellation_policy_setting") &&  */}
              {step == 8 && (
                <SchedulerCancellationPolicy
                  settingList={findSetting("scheduler_cancellation_policy")}
                  settingList1={findSetting("scheduler_allow_cancellations")}
                  fields={fields}
                  fields1={fields1}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleChangeOptions={handleChangeOptions}
                  handleChange={handleChange}
                  handleNext={saveStepTracking}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"cancellation_policy_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={9}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}

              {/* step 9 */}
              {/* {shouldShowStep("working_hours_setting") &&  */}
              {step == 9 && (
                <WorkingHoursSetting
                  workingHours={workingHours}
                  convertTo12HourFormat={convertTo12HourFormat}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleNext={saveStepTracking}
                  removeHours_={removeHours_}
                  handleAddTime={handleAddTime}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"working_hours_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={10}
                  stepType={stepType}
                  setStepType={setStepType}
                  saveStepTracking_={saveStepTracking_}
                  setSchedulerTypes={setSchedulerTypes}
                  schedulerType={schedulerType}
                  schedulerTypes={schedulerTypes}
                />
              )}
              {/* } */}

              {/* step 10 */}
              {/* {shouldShowStep("appointment_type_setting") &&  */}
              {step == 10 && (
                <AppointmentTypesSettings
                  handleAppointmentPop={handleAppointmentPop}
                  appointmentTypeList={appointmentTypeList}
                  setAppointmentTypeList={setAppointmentTypeList}
                  handleAppointmentLinkPop={handleAppointmentLinkPop}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  deleteAppointmentType_={deleteAppointmentType_}
                  editAppointmentType={editAppointmentType}
                  typeFields={typeFields}
                  handleNext={saveStepTracking}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"appointment_type_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={11}
                  stepType={stepType}
                  setStepType={setStepType}
                  saveStepTrackings_={saveStepTrackings_}
                  schedulerTypes={schedulerTypes}
                  setSchedulerTypes={setSchedulerTypes}
                />
              )}
              {/* } */}

              {/* step 11 */}
              {/* {shouldShowStep("blocked_dates_setting") &&  */}
              {step == 11 && (
                <SchedulerBlockedDates
                  settingList={findSetting("scheduler_blocked_dates")}
                  accountList={accountList}
                  handleChangeBlock_={handleChangeBlock_}
                  blockfields={blockfields}
                  OTick={OTick}
                  todayDate={todayDate}
                  validationErrors={validationErrors}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  buttonDisable_={buttonDisable_}
                  handleNext={saveStepTracking}
                  submitBlockTimeData={submitBlockTimeData}
                  dateFormatData_={dateFormatData_}
                  removeBlockTime={removeBlockTime}
                  accountSettingListData_={accountSettingListData_}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"blocked_dates_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={12}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* } */}

              {/* step 12 */}
              {/* {shouldShowStep("finish_review") && */}
              {step == 12 && (
                <FinishReview
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleNext={saveStepTracking}
                  accountSettingListData_={accountSettingListData_}
                  setSchedulerType={setSchedulerType}
                  nextStep={13}
                  stepType={stepType}
                  setStep={setStep}
                />
              )}

              {/* } */}
              {step == 13 && (
                <AppointmentCalendarStep
                  buttonDisable={buttonDisable}
                  scheduleSec={scheduleSec}
                  setScheduleSec={setScheduleSec}
                  handleChangeBlock_={handleChangeBlock_}
                  blockfields={blockfields}
                  submitBlockTimeData={submitBlockTimeData}
                  setBlockAdd={setBlockAdd}
                  BlockAdd={BlockAdd}
                  accountList={accountList}
                  validationErrors={validationErrors}
                  appointmentTypeList={appointmentTypeList}
                  timeZoneListing={timeZoneListing}
                  submitData_={submitData_}
                  buttonDisable_={buttonDisable_}
                  handleNext={saveStepTracking}
                  setEditValueSetting={setEditValueSetting}
                  setSchedulerType={setSchedulerType}
                  nextStep={14}
                  setStepType={setStepType}
                  stepType={stepType}
                  schedulerTimezone={schedulerTimezone}
                  accountSettingList={accountSettingList}
                />
              )}
              {/* step 14 */}
              {step == 14 && (
                <SchedulerStatus
                  settingList={findSetting("scheduler_status")}
                  handleChange={handleChange}
                  scheduleSec={scheduleSec}
                  schedulerType={schedulerType}
                  handleScheduleSec={handleScheduleSec}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleNext={saveStepTracking}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"scheduler_status_"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={15}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* step 15 */}
              {step == 15 && (
                <SchedulerFutureBookingAllowed
                  settingList={findSetting(
                    "scheduler_future_date_booking_allowed"
                  )}
                  handleDisplayName={handleDisplayName}
                  editDisplayName={editDisplayName}
                  submitData_={submitData_}
                  buttonDisable={buttonDisable}
                  handleChange={handleChange}
                  handleNext={saveStepTracking}
                  settingListStep={findSetting(
                    "track_appointment_schedule_step"
                  )}
                  step_name={"future_date_booking_setting"}
                  setStep={setStep}
                  setSchedulerType={setSchedulerType}
                  nextStep={12}
                  stepType={stepType}
                  setStepType={setStepType}
                />
              )}
              {/* </StepWizard> */}
            </Row>
          )}
        </Container>
        {step == 13 && !schedulerType ? (
          <>
            <RightSidebar
              step={step}
              AppointmentSidebar={AppointmentSidebar}
              setAppointmentSidebar={setAppointmentSidebar}
              hoursLists={hoursLists}
              appointTypeList={appointTypeList}
              setEditValueSetting={setEditValueSetting}
              setSchedulerType={setSchedulerType}
              setSchedulerTypes={setSchedulerTypes}
              updateHours_={updateHours_}
              user={user}
            />
          </>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    // appointmentStepsData: stepsData,
    accountSettingListData_: state?.AccountSetting?.accountSettingList,
    accountSettingDetails: state?.AccountSetting?.accountSettingDetail_,
    hoursLists: state?.Appointment?.hoursList,
    appointTypeList: state?.Appointment?.appointmentList,
    user: state.Profile?.user,
    // accountSettingDetail : state.AccountSetting.accountSettingDetail_
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Appointment);
