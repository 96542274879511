import { useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { useMatch } from "react-router-dom";
import MyRoutes from "./Routes/routes";
import store from "./store";
import { authCheck } from "./store/actions/auth";
import * as Path from "./Routes/paths";

import "react-toastify/dist/ReactToastify.css";
import 'react-tooltip/dist/react-tooltip.css'
import 'Assets/css/timepicker.css';
import "./App.css";

const ZENDESK_API_KEY = process.env.REACT_APP_ZENDESK_API_KEY;

global.Buffer = global.Buffer || require("buffer").Buffer;
store.dispatch(authCheck());

function App() {
  const zendeskRef = useRef(null);
  const zendeskScriptLoaded = useRef(null);

  const isMobilePath = useMatch(Path.mobileTodoReports);

  // useEffect(() => {
  //   // if app is being loaded thorugh a react native webview, then don't load zendesk chat widget
  //   if(zendeskScriptLoaded.current || isMobilePath) {
  //     return;
  //   }
  //   const scriptTag = document.createElement("script");
  //   scriptTag.id = "ze-snippet";
  //   scriptTag.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_API_KEY}`;

  //   // append script to zendesk container
  //   zendeskRef.current.appendChild(scriptTag);
  //   zendeskScriptLoaded.current = true;
  // }, []);
  return (
    <>
      <ToastContainer />
      {/* <Suspense fallback={<div>Loading...</div>}> */}
      <MyRoutes />
      {/* </Suspense> */}
      <div id="nta-zendesk-widget" ref={zendeskRef}></div>
    </>
  );
}

export default App;
