import React from "react";
import { Button } from "react-bootstrap";

// css
import "./RecentSidebar.scss";

// icn
import { ReactComponent as CalenderIcn } from "../../../../../Assets/Images/icn/Calender.svg";
import { ReactComponent as StarIcn } from "../../../../../Assets/Images/icn/star.svg";
import { ReactComponent as StarYellowIcn } from "../../../../../Assets/Images/icn/starYellow.svg";
import { ReactComponent as DisabledIcn } from "../../../../../Assets/Images/icn/disabled.svg";
import { ReactComponent as CloseBtn } from "../../../../../Assets/Images/icn/close.svg";
import { RenderEvent } from "./EventTypes/Index";

const RecentSidebar = ({ RecentTab, setRecentTab, recentEvents }) => {
  const handleRecentTab = () => {
    setRecentTab(!RecentTab);
  };


  return (
    <>
      <div className={`RecentSidebar sticky-top ${RecentTab && "active"}`}>
        <div className="top position-relative p-3 ">
          <Button
            onClick={handleRecentTab}
            className="border-0 p-0 position-absolute closeBtn d-none align-items-center justify-content-center rounded-0"
            style={{ left: "0", top: "0", background: "#000" }}
          >
            <CloseBtn />
          </Button>
          <h3 className="m-0 fw-bold">Recent Events</h3>
        </div>
        <div className="Inner">
          {(recentEvents && recentEvents?.data.length > 0) ?
            <ul
              className="list-unstyled ps-0 mb-0 ActivityList position-relative"
              style={{ zIndex: "9" }}
            >
              {recentEvents?.data?.map(event => {
                return (
                  <RenderEvent
                    event={event}
                  />
                )
              })
              }
            </ul>
            :
            <div className="px-3 my-3 d-flex align-itmes-start">
              <div className="content">
                <p>No recent events to show</p>
              </div>
            </div>
          }
          {/* <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#405C40" }}
              >
                <StarIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FEFAE0" }}
              >
                <DisabledIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Email Bounced</h6>
                <p className="m-0">abc@gmail.com</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 py-3 d-flex align-itmes-start">
              <div className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2">
                <CalenderIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">New Appointment Booked</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Thu, Sep 14th at 3:17 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#405C40" }}
              >
                <StarIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FF5757" }}
              >
                <StarYellowIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FEFAE0" }}
              >
                <DisabledIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Email Bounced</h6>
                <p className="m-0">abc@gmail.com</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 py-3 d-flex align-itmes-start">
              <div className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2">
                <CalenderIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">New Appointment Booked</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Thu, Sep 14th at 3:17 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#405C40" }}
              >
                <StarIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FF5757" }}
              >
                <StarYellowIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FEFAE0" }}
              >
                <DisabledIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Email Bounced</h6>
                <p className="m-0">abc@gmail.com</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 py-3 d-flex align-itmes-start">
              <div className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2">
                <CalenderIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">New Appointment Booked</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Thu, Sep 14th at 3:17 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#405C40" }}
              >
                <StarIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li>
            <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FF5757" }}
              >
                <StarYellowIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Agdh wdsd</h6>
                <p className="m-0">Mike Latimer</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li> */}
          {/* <li className="px-3 my-3 d-flex align-itmes-start">
              <div
                className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
                style={{ background: "#FEFAE0" }}
              >
                <DisabledIcn />
              </div>
              <div className="content">
                <h6 className="m-0 fw-bold">Email Bounced</h6>
                <p className="m-0">abc@gmail.com</p>
                <i className="time">Wed, Sep 13th at 3:00 pm</i>
              </div>
            </li> */}
      </div>
    </div >
    </>
  );
};

export default RecentSidebar;
