export const MAIN_PATH = `${process.env.REACT_APP_API_MAIN_PATH}`;
export const MAIN_PATH_RECOMMENDATION = `${process.env.REACT_APP_RECOMM_API_MAIN_PATH}`;
export const CLIENT_PATH = "practitioner/clients/";
export const COMMON_PATH = "common";
export const APP_PATH = "api";
//auth
export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register";
export const LOGOUT = "/auth/logout";
export const SEND_OTP = "/auth/otp/send";
export const RESEND_OTP = "/auth/otp/resend";
export const VERIFY_OTP = "/auth/otp/verify";

//profile
export const PROFILE = "/auth/me";
export const UPDATE_PROFILE = "/auth/updateLoginEmail";
//profile
export const FORGOT_PASSWORD = "/auth/forgotPassword";
//reset password
export const RESET_PASSWORD_FORM = "/reset_password_form";
export const RESET_PASSWORD = "/auth/resetPassword";

export const ACCOUNT_ACTIVATION = "/activate_account";
//change password
export const CHANGE_PASSWORD = "/auth/changePassword";

//dashboard
export const DASHBOARD = "/dashboard";
export const GETTING_STARTED_PROGRESS = "/get-started-progress";

//terms & conditon accpetances

export const ACCPET_TERMS = "/profile/terms_acceptance";
export const PLAN_ACTIVE_API = "/profile/plan_active";

//subscription
export const SUBSCRIPTION_LIST = "/subscriptions";
export const SUBSCRIPTION_ADD = "/subscriptions/createSubscription";
export const SUBSCRIPTION_UPDATE = "/subscription/edit";
export const SUBSCRIPTION_VIEW = "/subscription/detail/";
export const SUBSCRIPTION_DELETE = "/subscription/delete/";
export const SUBSCRIPTION_HISTORY_ = "/subscriptions/subscribed";

//client
export const CLIENT_LIST = "/clients/list";
export const VIEW_CLIENT = "/clients/:id/view";
export const CLIENT_ADD = "add";
export const UPDATE_CLIENT = "/clients/:id/update";
export const REMOVE_CLIENT = "/clients/:id/delete";
export const CLIENT_CATEGORY_LIST = "/clients/categories/list";
export const ADD_FAVORITE_DATA = "/mark_favorite";
export const ASSIGN_TAGS = "assign_tags";
export const REMOVE_TAGS = "/clients/remove-tags";
export const RESEND_INVITATION_EMAIL = "/clients/resend-invite-email/";

// merge clients
export const GET_CLIENT_STATS_LIST = "/clients/stats/list/";
export const MERGE_CLIENTS = "/clients/merge";

// client todo
export const GET_TODO_LIST = "/clients/todo/:id/list";
export const ADD_CLIENT_TODO = "/clients/todo/:id/add";
export const GET_TODO_DETAILS = "/clients/todo/:id/view";
export const SAVE_TODO_RESPONSE = "/clients/todo/:id/saveResponse";
export const GET_TODO_RESPONSE = "/clients/todo/:id/getResponse";
export const DELETE_CLIENT_TODO = "/clients/todo/:id/remove";
export const SEND_TODO_REMINDER = "/clients/todo/:id/reminder";
export const GET_TODO_FORM_DETAILS = "/clients/todo/:id/formDetails";
export const UPDATE_TODO_STATUS = "/clients/todo/:id/status";

// get client reports data
export const GET_GRAPH_DATA = "/clients/symptom-burden-comparison/graph/";

// NAQ v2
export const SYMPTOM_BURDEN_REPORT_V2 = "/naq2/symptom-burden/reports/";
export const RECOMMENDATION_REPORT_V2 = "/naq2/recommendation/reports/";
export const PRIORITY_REPORT_V2 = "/naq2/priority/reports/";
export const BODY_SYMPTOM_BURDEN_GRAPH_V2 = "/naq2/symptom-burden/graph/";
export const GET_FOUNDATION_SYMPTOM_BURDEN_GRAPH =
  "/naq2/foundation-burden/graph/";

// client data analysis NAQ v2
export const COMPLETED_TODO_LIST_V2 = "/naq2/data-analysis/todo/";
export const CLIENT_DATA_ANALYSIS_GRAPHS_V2 = "/naq2/data-analysis/sbc-graph/";
export const CLIENT_DATA_ANALYSIS_REPORTS_V2 =
  "/naq2/data-analysis/sbc-report/";
export const CLIENT_DATA_ANALYSIS_PRIORITY_REPORTS_V2 =
  "/naq2/data-analysis/pc-report/";

// NAQ v1
export const SYMPTOM_BURDEN_REPORT_V1 = "/naq/symptom-burden/reports/";
export const RECOMMENDATION_REPORT_V1 = "/naq/recommendation/reports/";
export const PRIORITY_REPORT_V1 = "/naq/severity/reports/";
export const BODY_SYMPTOM_BURDEN_GRAPH_V1 = "/naq/symptom-burden/graph/";

// client data analysis NAQ v1
export const COMPLETED_TODO_LIST_V1 = "/naq/data-analysis/todo/";
export const CLIENT_DATA_ANALYSIS_GRAPHS_V1 = "/naq/data-analysis/sbc-graph/";
export const CLIENT_DATA_ANALYSIS_REPORTS_V1 = "/naq/data-analysis/sbc-report/";
export const CLIENT_DATA_ANALYSIS_PRIORITY_REPORTS_V1 =
  "/naq/data-analysis/severity-report/";

// client Health & wellness assessment reports
export const WELLNESS_GRAPH_DATA = "/wellness/reports/";
export const COMPLETED_WELLNESS_FORMS_LIST = "/wellness/data-analysis/todo/";
export const WELLNESS_COMPARISON_GRAPH_DATA = "/wellness/data-analysis/graph/";

// client action plans
export const GET_CLIENT_ACTION_PLAN_LIST = "/action-plan/list/";
export const VIEW_CLIENT_ACTION_PLAN_DETAILS = "/action-plan/view/";
export const TOGGLE_PUBLISH_ACTION_PLAN = "/action-plan/publish/";
export const DELETE_ACTION_PLAN = "/action-plan/delete/";

// client settings
export const GET_CLIENT_SETTINGS = "/clients/:id/settings";
export const UPDATE_CLIENT_SETTINGS = "/clients/:id/settings";

// client recommendations
export const GET_RECOMMENDATION_REPORTS_LIST = "/recommendation/list/:id";
// export const GET_CLIENT_RECOMMENDATION_LIST = "/recommendation/getList/:id";
export const ADD_CLIENT_RECOMMENDATION = "/recommendation/create/:id";
export const GET_CLIENT_RECOMMENDATION_DETAILS = "/recommendation/view/:id";
export const UPDATE_CLIENT_RECOMMENDATION_DETAILS =
  "/recommendation/update/:id";
export const DUPLICATE_CLIENT_RECOMMENDATION =
  "/recommendation/duplicate/:id?client_id=:clientId";
export const DELETE_CLIENT_RECOMMENDATION = "/recommendation/remove/:id";
export const VIEW_RECOMMENDATION_REPORT_DETAIL = "/recommendation/view/:id";
export const VIEW_TODO_RECOMMENDATION_DETAIL =
  "/recommendation/todo-form/view/:id";
export const DOWNLOAD_RECOMMENDATION_REPORT = "/recommendation/download/:id";
export const DOWNLOAD_TODO_RECOMMENDATION_REPORT =
  "/recommendation/download/todo/:id";
export const PREPOPULATE_RECOMM_FROM_NAQ = "/recommendation/prepopulate";

// client recommendation templates
export const GET_RECOMMENDATION_TEMPLATE_LIST = "/recommendation/template/list";
export const CREATE_RECOMMENDATION_TEMPLATE = "/recommendation/template/create";
export const UPDATE_RECOMMENDATION_TEMPLATE = "/recommendation/template/update/";
export const DELETE_RECOMMENDATION_TEMPLATE = "/recommendation/template/delete/";
export const SEND_RECOMM_TO_CLIENT = "/recommendation/send/";

// client package invitations
export const GET_PACKAGE_INVITATION_LIST = "/clients/packages/invitation/list/";
export const RESEND_PACKAGE_INVITATION = "/clients/packages/invitation/resend/";
export const CANCEL_PACKAGE_INVITATION = "/clients/packages/invitation/cancel/";
export const DELETE_PACKAGE_INVITATION = "/clients/packages/invitation/delete/";
export const SEND_PACKAGE = "/clients/packages/invitation/send/";

// save notes
export const SAVE_TODO_NOTES = "/forms/response/notes";
export const GET_TODO_NOTES = "/forms/response/notes/";

//my form
export const MY_FORM_LIST = "/forms/list";
export const MY_FORM_ADD = "/forms/add";
export const MY_FORM_UPDATE = "/forms/";
export const MY_FORM_VIEW = "/forms/";
export const MY_FORM_DELETE = "/forms/";
export const DUPLICATE_FORM = "/forms/duplicate/";
export const GET_FORM_LISTING = "/forms/my-forms";
export const POST_FORM_SORT_ORDER = "/forms/questions/orders";

// Form Pages
export const FORM_PAGE_ADD = "/forms/pages/add/";
export const FORM_PAGE_DELETE = "/forms/pages/";
export const FORM_PAGE_EDIT = "/forms/pages/";
export const FORM_PAGE_SORTING = "/forms/pages/sorting/";

// Form Questions
export const GET_FORM_QUESTION_TYPES = "/forms/questions/type/";
export const FORM_QUESTION_ADD = "/forms/questions/add";
export const FORM_QUESTION_EDIT = "/forms/questions/";
export const FORM_QUESTION_DELETE = "/forms/questions/";

//resource
export const RESOURCE_LIST = "/resources/list";
export const RESOURCE_ADD = "/resource/add";
export const RESOURCE_UPDATE = "/resource/edit";
export const RESOURCE_VIEW = "/resource/detail/";
export const RESOURCE_DELETE = "/resource/delete/";

// resource categories
export const RESOURCE_CATEGORIES = "/resources/categories";


//faq
export const FAQ_LIST = "/faq/list";
export const FAQ_ADD = "/faq/add";
export const FAQ_UPDATE = "/faq/edit";
export const FAQ_VIEW = "/faq/detail/";
export const FAQ_DELETE = "/faq/delete/";

//content pages
export const CONTENT_PAGE_LIST = "/pages/list";
export const CONTENT_PAGE_ADD = "/pages/add";
export const CONTENT_PAGE_UPDATE = "/pages/edit";
export const CONTENT_PAGE_VIEW = "/pages/";
export const CONTENT_PAGE_DELETE = "/pages/delete/";

//Practitioner
export const PRACTITIONER_LIST = "/practitioner/list";
export const PRACTITIONER_ADD = "/practitioner/add";
export const PRACTITIONER_UPDATE = "/practitioner/edit";
export const PRACTITIONER_VIEW = "/practitioner/detail/";
export const PRACTITIONER_DELETE = "/practitioner/delete/";

//Document
export const DOCUMENT_LIST = "/documents/list";
export const DOCUMENT_ADD = "/documents/add";
export const DOCUMENT_DELETE = "/documents/remove/";

//Configuration
export const CONFIGURATION_ADD = "/configuration/add";
export const CONFIGURATION_VIEW = "/configuration/detail/";

//image
export const UPLOAD_IMAGE_PATH = "/file/upload";
export const DELETE_IMAGE_PATH = "/file/delete";
//csv
export const IMPORT_CSV_PATH = "/user/import_csv";
export const IMPORT_CSV_PATH_PROVIDER = "/provider/import_csv";

//account setting
export const ACCOUNT_SETTING_LIST = "/settings/list";
export const DEFAULT_FORM_LIST = "/forms/default";
export const ADD_UPDATE_ACCOUNT_SETTING = "/settings/";
export const GET_ACCOUNT_SETTING_DETAIL = "/settings/view/";
export const GET_ACCOUNT_SETTING_DETAIL_ = "/settings/values";
export const SELF_REGISTER_LIST = "/self_registration/list";
export const ADD_SELF_REGISTER = "/self_registration/add/";
export const GET_THIRD_PARTY_INTEGRATION_STATUS = "/linked-components/status";
export const CONNECT_STRIPE_ACCOUNT = "/stripe/connect/account";
export const DISCONNECT_STRIPE_ACCOUNT = "/stripe/connect/account/remove";
export const STRIPE_LOGIN_LINK = "/stripe/login-link";
export const CONNECT_GOOGLE_CALENDAR = "/calendar/auth/generate";
export const DISCONNECT_GOOGLE_CALENDAR = "/calendar/unlink";
export const CONNECT_FULLSCRIPT_ACCOUNT = "/fullscript/link";
export const DISCONNECT_FULLSCRIPT_ACCOUNT = "/fullscript/unlink";
export const GENERATE_PRACTICE_BETTER_KEY = "/pb/generate-key";
export const DISCONNECT_PRACTICE_BETTER_ACCOUNT = "/pb/unlink-account-nutriq";

//tags
export const TAG_LIST = "/tags/list";
export const TAG_ADD = "/tags/add";
export const TAG_UPDATE = "/tags/edit/";
export const TAG_DETAIL = "/tags/detail/";
export const TAG_DELETE = "/tags/remove/";

//subscription
export const BILLING_INFO_URL = "/subscriptions/billing/portal";
export const ACTIVE_PLAN_LIST = "/subscriptions/active";
export const GET_SUBSCRIPTION_PACAKGES_LIST = "/subscriptions/upgradable/plans";

//packages
export const GET_PACKAGE = "/clients/packages";
export const ADD_PACKAGE = "/clients/packages/add";
export const UPDATE_PACKAGE = "/clients/packages/edit/";
export const VIEW_PACKAGE = "/clients/packages/view/";
export const DELETE_PACKAGE = "/clients/packages/remove/";

//appointment
//time zone
export const TIME_ZONE_LIST = "/appointment/timezonelist";
//scheduler setting
export const SCHEDULER_SETTING_API = "/appointment/schedulerSetting/";
//working hours list
export const WORKING_LIST_API = "/appointment/workinghoursList";
export const ADD_HOURS = "/appointment/addWorkingHours/";
//appointment type
export const APPOINTMENT_TYPE_LIST = "/appointment/type/list";
export const APPOINTMENT_TYPE_ADD = "/appointment/type";
export const REMOVE_APPOINTMENT_API = "/appointment/type/remove/";
export const APPOINTMENT_TYPE_VIEW_API = "/appointment/type/view/";
export const APPOINTMENT_CLEINT_API = "/appointment/client/list";
export const APPOINTMENT_SLOT_API = "/appointment/slot/";
export const APPOINTMENT_BOOK_API = "/appointment/book";
export const GET_APPOINTMENT_BOOKING_LIST = "/appointment/booking/list";
export const APPOINTMENT_STEPS_SAVE_TRACKING_DATA =
  "/appointment/setting/step/";
export const RESCHEDULE_APPOINTMENT = "/appointment/reschedule/";
export const CANCEL_APPOINTMENT = "/appointment/cancel/";
export const UPDATE_APPOINTMENT = "/appointment/update/";
export const APPOINTMENT_LINK_CLIENT = "/appointment/link-client/";
export const APPOINTMENT_UNLINK_CLIENT = "/appointment/unlink-client/";
export const GET_CLIENT_APPOINTMENTS = `/appointment/client/:id/list`;
export const POST_APPOINTMENTS_ORDER = "/appointment/type/ordering";

// Fullscript
export const FULLSCRIPT_SESSION_GRANT = "/fullscript/session/grant";
export const FIND_FULLSCRIPT_CLIENT = "/fullscript/find-patient";
export const GET_FULLSCRIPT_CLIENT_LIST = "/fullscript/patients";
export const FULLSCRIPT_UNLINK_CLIENT = "/fullscript/unlink-client";
export const LINK_CLIENT_TO_FULLSCRIPT = "/fullscript/link-client";
export const GET_FULLSCRIPT_RECOMMENDATIONS =
  "/fullscript/patients/:id/recommendations";
export const GET_FULLSCRIPT_RECOMMENDATION_DETAILS =
  "/fullscript/recommendations";
export const CANCEL_FULLSCRIPT_RECOMMENDATION =
  "/fullscript/recommendations/:id/cancel";

// Food & Mood Journal
export const SAVE_FOOD_MOOD_JOURNAL_DATA = "/clients/food-log/response";
export const GET_FOOD_MOOD_JOURNAL_DATA = "/clients/food-log/view";
export const GET_FOOD_MOOD_JOURNAL_PROGRESS = "/clients/food-log/state/";
export const SUBMIT_FOOD_MOOD_JOURNAL = "/clients/food-log/submission/";
export const VIEW_FOOD_MOOD_SUBMITTED_DATA = "/clients/food-log/submitted/";
export const SAVE_FOOD_MOOD_NOTES = "/clients/food-log/notes/";
export const DELETE_FMJ_DAY_LOG = "/clients/food-log/remove";

// Food & Mood Logs
export const GET_FOOD_MOOD_LOGS = "/clients/logs/:id/list";
export const ADD_COMMENT_FOOD_MOOD_LOGS = "/clients/logs/comments/add";


// TEMP BILLING PORTAL
export const STRIPE_BILLING_PORTAL = "/subscriptions/update/portal";

// Basic settings
export const FETCH_BASIC_SETTINGS = "/settings/basic";