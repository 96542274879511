import moment from "moment"

// icons
import { ReactComponent as CalenderIcn } from "Assets/Images/icn/Calender.svg";
import { ReactComponent as StarIcn } from "Assets/Images/icn/star.svg";
import { ReactComponent as StarYellowIcn } from "Assets/Images/icn/starYellow.svg";
import { ReactComponent as DisabledIcn } from "Assets/Images/icn/disabled.svg";
import { ReactComponent as CloseBtn } from "Assets/Images/icn/close.svg";
import { Link } from "react-router-dom";
import * as Path from "Routes/paths";
import { UC_FIRST } from "helpers/common";

const timestampFormat = (timestamp) => {
  return `${moment(timestamp).format("ddd, MMM DD")} at ${moment(timestamp).format("hh:mm a")}`
}

const DefaultEvent = ({ event }) => {
  let eventLabel = UC_FIRST(event.event.replaceAll("_", " "));
  return (
    <li>
      <div className="px-3 my-3 d-flex align-itmes-start event">
        <div
          className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
          style={{ background: "#FF5757" }}
        >
          <StarIcn />
        </div>
        <div className="content">
          <h6 className="m-0 fw-bold">{event.meta.client_name}</h6>
          <p className="m-0">{eventLabel}</p>
          <i className="time">{timestampFormat(event.timestamp)}</i>
        </div>
      </div>
    </li>
  )
}

const AppointmentBooking = ({ event, eventLabel }) => {
  return (
    <li className="event">
      <Link
        to={Path.appointment}
      >
        <div className="px-3 py-3 d-flex align-itmes-start">
          <div className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2">
            <CalenderIcn />
          </div>
          <div className="content">
            <h6 className="m-0 fw-bold">{eventLabel}</h6>
            <p className="m-0">With: {event.meta.client_name}</p>
            {/* <p className="m-0">When: {moment(event.meta.booking_date).format("")}</p> */}
            <i className="time">{timestampFormat(event.timestamp)}</i>
          </div>
        </div>
      </Link>
    </li>
  )
}


const ClientMarkFavorite = ({ event }) => {
  return (
    <li className="px-3 my-3 d-flex align-itmes-start event">
      <div
        className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
        style={{ background: "#FF5757" }}
      >
        <StarYellowIcn />
      </div>
      <div className="content">
        <h6 className="m-0 fw-bold">Marked Favorite</h6>
        <p className="m-0">{event.meta.client_name}</p>
        <i className="time">{timestampFormat(event.timestamp)}</i>
      </div>
    </li>
  )
}

const ClientUnmarkedFavorite = ({ event }) => {
  return (
    <li className="px-3 my-3 d-flex align-itmes-start event">
      <div
        className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
        style={{ background: "#FF5757" }}
      >
        <StarYellowIcn />
      </div>
      <div className="content">
        <h6 className="m-0 fw-bold">Unmarked Favorite</h6>
        <p className="m-0">{event.meta.client_name}</p>
        <i className="time">{timestampFormat(event.timestamp)}</i>
      </div>
    </li>
  )
}

const RecommendationPublished = ({ event }) => {
  return (
    <li>
      <Link
        to={`${Path.myClientDetails.replace(":clientId", event.client_id)}?tab=Recommendations`}
      >
        <div className="px-3 my-3 d-flex align-itmes-start event">
          <div
            className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
            style={{ background: "#FF5757" }}
          >
            <StarYellowIcn />
          </div>
          <div className="content">
            <h6 className="m-0 fw-bold">{event.meta.client_name}</h6>
            <p className="m-0">Recommendation Published</p>
            <i className="time">{timestampFormat(event.timestamp)}</i>
          </div>
        </div>
      </Link>
    </li>
  )
}

const RecommendationUnpublished = ({ event }) => {
  return (
    <li>
      <Link
        to={`${Path.myClientDetails.replace(":clientId", event.client_id)}?tab=Recommendations`}
      >
        <div className="px-3 my-3 d-flex align-itmes-start event">
          <div
            className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
            style={{ background: "#FF5757" }}
          >
            <StarYellowIcn />
          </div>
          <div className="content">
            <h6 className="m-0 fw-bold">{event.meta.client_name}</h6>
            <p className="m-0">Recommendation Unublished</p>
            <i className="time">{timestampFormat(event.timestamp)}</i>
          </div>
        </div>
      </Link>
    </li>
  )
}

const ClientSignup = ({ event, eventLabel }) => {
  return (
    <li>
      <Link
        to={`${Path.myClientDetails.replace(":clientId", event.client_id)}`}
      >
        <div className="px-3 my-3 d-flex align-itmes-start event">
          <div
            className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
            style={{ background: "#FF5757" }}
          >
            <StarIcn />
          </div>
          <div className="content">
            <h6 className="m-0 fw-bold">{event.meta.client_name}</h6>
            <p className="m-0">{eventLabel}</p>
            <i className="time">{timestampFormat(event.timestamp)}</i>
          </div>
        </div>
      </Link>
    </li>
  )
}

const ClientRemoved = ({ event, eventLabel }) => {
  return (
    <li>
      <div className="px-3 my-3 d-flex align-itmes-start event">
        <div
          className="icn position-relative d-flex align-items-center justify-content-center rounded-circle me-2"
          style={{ background: "#FF5757" }}
        >
          <StarIcn />
        </div>
        <div className="content">
          <h6 className="m-0 fw-bold">{event.meta.client_name}</h6>
          <p className="m-0">{eventLabel}</p>
          <i className="time">{timestampFormat(event.timestamp)}</i>
        </div>
      </div>
    </li>
  )
}


export const RenderEvent = ({ event }) => {
  // return (
  //   <p>test</p>
  // )
  switch (event.event) {
    case "appt_booked":
      return (
        <AppointmentBooking
          event={event}
          eventLabel="New Appointment Booked"
        />
      );

    case "appt_updated":
      return (
        <AppointmentBooking
          event={event}
          eventLabel="Appointment Updated"
        />
      );

    case "appt_rescheduled":
      return (
        <AppointmentBooking
          event={event}
          eventLabel="Appointment Rescheduled"
        />
      );

    // case "mark_favorite":
    //   return <ClientMarkFavorite event={event} />;

    // case "unmark_favorite":
    //   return <ClientUnmarkedFavorite event={event} />;

    case "recommendation_publish":
      return <RecommendationPublished event={event} />;

    case "recommendation_unpublish":
      return <RecommendationUnpublished event={event} />;

    // case "recommendation_unpublish":
    // return <RecommendationUnublished event={event} />;

    case "client_created":
      return (
        <ClientSignup
          event={event}
          eventLabel="Client Created"
        />
      );

    case "client_register":
      return (
        <ClientSignup
          event={event}
          eventLabel="Client Registered"
        />
      );

    case "client_signup_started":
      return (
        <ClientSignup
          event={event}
          eventLabel="Started Sign-Up"
        />
      );

    case "client_signup_completed":
      return (
        <ClientSignup
          event={event}
          eventLabel="Completed Sign-Up"
        />
      );

    case "client_remove":
      return (
        <ClientRemoved
          event={event}
          eventLabel="Client Removed"
        />
      );

      case "client_self_signup_started":
        return (
          <ClientSignup
            event={event}
            eventLabel="Started Sign-Up"
          />
        );
  
      case "client_self_signup_completed":
        return (
          <ClientSignup
            event={event}
            eventLabel="Completed Sign-Up"
          />
        );

    default:
      return (
        <DefaultEvent
          event={event}
        />
      );
  }
}