import { Document, Page, Text, StyleSheet, View, Image, pdf } from '@react-pdf/renderer';
import { useEffect, useRef, useState } from 'react';
import { FULL_NAME, saveAs } from 'helpers/common';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { getBodySymptomBurdenGraphV2 } from 'store/services/clientDataReportsService';
import { useShowLoader } from 'hooks/useShowLoader';
import CustomBarChart from '../CustomChart';

import { ReactComponent as DownloadIcn } from "Assets/Images/icn/download.svg";

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "11px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  chartImage: {
    width: "100%",
    height: "auto"
  }
});

export const BodySymptomGraphDoc = ({ base64Image, client, date }) => {
  return (
    <Document>
      <Page orientation="landscape" style={styles.page}>

        <View style={styles.pageHeader}>
          <Text style={{fontSize: "18px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Body Systems Symptom Burden Graph
          </Text>
          <Text style={{fontSize: "22px", fontWeight: "bold", fontFamily: "Helvetica-Bold", color: "#08415c"}}>
            Nutri-Q
          </Text>
        </View>

        <View style={styles.clientName}>
          <Text>
            Client: {FULL_NAME(client.first_name, client.last_name)}
          </Text>
          <Text>
            {moment(date).format("MMM DD, YYYY")}
          </Text>
        </View>
        <Image 
          style={styles.chartImage}
          src={base64Image}
        />

        {/* Disclaimer */}
        <View style={styles.disclaimer}>
          <Text style={{ width: "90%" }}>
            Statements made on this document and reference materials have not been evaluated by the Food and Drug Administration.
            These products or protocols are not intended to diagnose, treat, cure or prevent any disease or be used as a substitute for appropriate care of a qualified health practitioner. 
          </Text>
          <Text>
            {moment().format("MMM DD, YYYY")}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const DownloadBSBurdenGraph = ({ client, date, todoId, label, linkType }) => {
  const [bodySymptomBurdenGraphData, setBodySymptomBurdenGraphData] = useState(null);

  const chartRef = useRef(null);
  const showLoader = useShowLoader();
  
  useEffect(() => {
    if (!bodySymptomBurdenGraphData) {
      getBodySymptomBurdenGraphData();
    }
  }, []);

  const getBodySymptomBurdenGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId: client.id
      };
      showLoader(true);
      const response = await getBodySymptomBurdenGraphV2(data);
      if (response.success === true) {
        const data = response.body;
        setBodySymptomBurdenGraphData(data);
      }
      showLoader(false);
    } catch (err) {
      showLoader(false);
      console.log(err);
    }
  }

  const downloadGraphPdf = async (event) => {
    try {
      if(chartRef.current) {
        showLoader(true);
        const canvas = await html2canvas(chartRef.current, { scale: 3 })
        const base64Image = canvas.toDataURL('image/png');
        const doc = <BodySymptomGraphDoc base64Image={base64Image} client={client} date={date}/>;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();
        saveAs(blob, 'Body-Symptom-Burden-Graph.pdf');
        showLoader(false);
      }
    } catch(err) {
      console.log("Error downloading graph", err);
      showLoader(false);
    }
  }

  return (
    <>
      {linkType === "button" ? (
        <Button
          className="d-flex align-items-center justify-content-center commonBtn btnSm download-pdf-btn"
          onClick={downloadGraphPdf}
        >
          <span className="icn me-2">
            <DownloadIcn />
          </span>
            {label || "Body Systems Symptom Burden Graph"}
        </Button>  
      ):(
        <Link
          onClick={downloadGraphPdf}
          disabled={true}
        >
          {label || "Body Systems Symptom Burden Graph"}
        </Link>
      )}
      <div style={{position: "fixed", right: "-99999px", width: "1000px", height: "1000px"}}>
        <CustomBarChart
          innerRef={chartRef}
          dataset={bodySymptomBurdenGraphData}
          title="Body Systems Symptom Burden Graph"
          totalsTitle="Body Systems Totals"
        />
      </div>
    </>
  )
}

export default DownloadBSBurdenGraph;