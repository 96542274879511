import moment from "moment";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import AddNotesPopup from "./Modals/AddNotesPopup";
import { Link } from "react-router-dom";
import { cloneDeep } from "lodash";
import * as actions from "store/actions";
import { saveFoodMoodJournalNotes } from "store/services/clientService";
import { connect } from "react-redux";
import { showDateInUtc, utcToLocal } from "helpers/common";

export const fmFields = {
  foods: "Foods",
  drinks: "Drinks",
  supplements: "Supps, Herbs & Meds",
  feeling: "Energy & Mood",
  movement: "Movement & Relaxation",
  digestion: "Digestion & Reactions",
};

const FoodMoodJournalData = ({ dispatch, loading, journalData, todoId, getDetails }) => {
  const [showNotesPopup, setShowNotesPopup] = useState(false);
  const [currentNotesData, setCurrentNotesData] = useState();

  const openNotesPopup = (notesData) => {
    setCurrentNotesData(notesData);
    setShowNotesPopup(true);
  };

  const closeNotesPopup = () => {
    setCurrentNotesData(null);
    setShowNotesPopup(false);
  };

  const saveNotes = async (notesFields, setSaving) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      let copyJournalData = cloneDeep(journalData);
      let logRow = copyJournalData.find((row) => row.id == currentNotesData?.log_id);
      if (!logRow || !currentNotesData) {
        throw "Invalid parameters for saving notes";
      }
      let payload = {
        food_log_id: currentNotesData?.log_id,
      };
      if (currentNotesData?.type == "food_served_notes") {
        payload.food_served = logRow.food_served;
        payload.food_served[currentNotesData?.rowIndex][currentNotesData?.fieldKey].notes =
          notesFields;
      }

      if (currentNotesData?.type == "additional_notes") {
        payload.additional_notes = notesFields;
      }

      await saveFoodMoodJournalNotes(todoId, payload);
      await getDetails(todoId);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      throw err;
    }
  };

  return (
    <>
      {showNotesPopup && (
        <AddNotesPopup
          showPopup={showNotesPopup}
          closePopup={closeNotesPopup}
          notesData={currentNotesData}
          handleSave={saveNotes}
        />
      )}

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="CardBody my-2">
          {journalData &&
            journalData.length > 0 &&
            journalData.map((data) => {
              return (
                <>
                  <div className="py-2">
                    <div className="top d-flex align-items-center justify-content-between gap-10">
                      <div className="left d-flex align-items-start gap-10 flex-wrap">
                        <h6 className="m-0 text-uppercase">
                          {showDateInUtc(data.log_date, "dddd, MMM DD YYYY")}
                        </h6>
                        <h6 className="m-0 text-uppercase">
                          Wake Time: {data.wake_time} Sleep Time: {data.sleep_time}
                        </h6>
                      </div>
                      <div className="right"></div>
                    </div>
                  </div>
                  <div className="py-2">
                    <div className="commonCardBox">
                      <div className="table-responsive border-0 fm-data-table">
                        <table className="table borderedTable">
                          <thead>
                            <tr>
                              <th width="10%" className="p-3 text-uppercase text-dark">
                                Meal Time
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Foods
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Drinks
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Supps, Herbs & Meds
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Energy & Mood
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Movement & Relaxation
                              </th>
                              <th width="14%" className="p-3 text-uppercase text-dark">
                                Digestion & Reactions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.food_served &&
                              data.food_served.length > 0 &&
                              data.food_served.map((timeRow, index) => {
                                return (
                                  <tr>
                                    <td className="p-3 ws-normal">
                                      <p className="m-0 text-dark fw-sbold">
                                        Start: <br />
                                        {timeRow.start_time}
                                      </p>
                                      <p className="m-0 text-dark fw-sbold">
                                        End: <br />
                                        {timeRow.end_time}
                                      </p>
                                    </td>
                                    {fmFields &&
                                      Object.keys(fmFields).map((fieldKey, fieldIndex) => {
                                        return (
                                          <td
                                            className="p-3 ws-normal"
                                            style={{
                                              background: `${
                                                timeRow[fieldKey].value ? "#d6e4d6" : "#fff"
                                              }`,
                                            }}
                                          >
                                            <div className="content py-3 fm-data-cell">
                                              {timeRow[fieldKey].value && (
                                                <span
                                                  className="tick position-absolute icn"
                                                  style={{ top: "0px", right: "0px" }}
                                                >
                                                  <CheckMarkIcon />
                                                </span>
                                              )}
                                              <p className="m-0 py-1">{timeRow[fieldKey].value}</p>
                                              {timeRow[fieldKey]?.notes &&
                                                timeRow[fieldKey]?.notes.length > 0 && (
                                                  <>
                                                    <hr className="m-1"></hr>
                                                    {timeRow[fieldKey]?.notes?.map((row) => {
                                                      return (
                                                        <p
                                                          className="m-0 py-1"
                                                          style={{ color: `${row.color}` }}
                                                        >
                                                          {row.note}
                                                        </p>
                                                      );
                                                    })}
                                                  </>
                                                )}
                                            </div>
                                            <div
                                              className="position-absolute"
                                              style={{ bottom: "5px", left: "10px" }}
                                            >
                                              <span className="icn">
                                                <Link
                                                  onClick={() => {
                                                    openNotesPopup({
                                                      type: "food_served_notes",
                                                      log_id: data.id,
                                                      rowIndex: index,
                                                      fieldKey: fieldKey,
                                                      notes: timeRow[fieldKey].notes,
                                                      value: timeRow[fieldKey].value,
                                                    });
                                                  }}
                                                >
                                                  <NoteIcon />
                                                </Link>
                                              </span>
                                            </div>
                                          </td>
                                        );
                                      })}

                                    {/* <td className="p-3 ws-normal">
                                      <div className="content py-3 h-100 position-relative">
                                        <p className="m-0 py-1 ">Drinks</p>

                                        <div
                                          className=" poistion-absolute "
                                          style={{ bottom: "0", left: "0" }}
                                        >
                                          <span className="icn">
                                            <Link
                                              onClick={() => {
                                                openNotesPopup({
                                                  type: "food_served_notes",
                                                  log_id: data.id,
                                                  rowIndex: index,
                                                  fieldKey: "foods",
                                                  notes: timeRow.foods.notes,
                                                  value: timeRow.foods.value,
                                                });
                                              }}
                                            >
                                              <NoteIcon />
                                            </Link>
                                          </span>
                                        </div>
                                      </div>
                                    </td> */}
                                  </tr>
                                );
                              })}
                            <tr>
                              <td colSpan={7}>
                                <div className="py-2">
                                  {data?.additional_notes?.map((row) => {
                                    return (
                                      <p className="m-0 py-1" style={{ color: `${row.color}`, whiteSpace: "pre-wrap" }}>
                                        {row.note}
                                      </p>
                                    );
                                  })}
                                </div>
                                <Link
                                  onClick={() => {
                                    openNotesPopup({
                                      type: "additional_notes",
                                      log_id: data.id,
                                      notes: data?.additional_notes
                                    });
                                  }}
                                >
                                  <NoteIcon />
                                  Add Notes
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}
    </>
  );
};

const CheckMarkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <g clip-path="url(#clip0_0_6718)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.89502 0.461914C4.48252 0.461914 0.89502 4.04941 0.89502 8.46191C0.89502 12.8744 4.48252 16.4619 8.89502 16.4619C13.3075 16.4619 16.895 12.8744 16.895 8.46191C16.895 4.04941 13.3075 0.461914 8.89502 0.461914Z"
          fill="#4BAE4F"
        />
        <path
          d="M7.35439 12.1212C6.33252 11.0993 5.31689 10.0681 4.29189 9.04619C4.17939 8.93369 4.17939 8.74619 4.29189 8.63369L5.47002 7.45557C5.58252 7.34307 5.77002 7.34307 5.88252 7.45557L7.56689 9.13994L11.8981 4.80557C12.0138 4.69307 12.1981 4.69307 12.3138 4.80557L13.495 5.98682C13.6106 6.10244 13.6106 6.28682 13.495 6.39932L7.76689 12.1212C7.65439 12.2368 7.47002 12.2368 7.35439 12.1212Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_6718">
          <rect width="16" height="16" fill="white" transform="translate(0.89502 0.461914)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const NoteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22 22" fill="none">
      <g clip-path="url(#clip0_0_6722)">
        <path
          d="M17.5311 0.753418H3.25695C1.76455 0.753418 0.550293 1.96743 0.550293 3.46008V12.1956C0.550293 13.6849 1.75923 14.897 3.24736 14.9023V18.8663L8.94415 14.9022H17.5311C19.0235 14.9022 20.2378 13.688 20.2378 12.1956V3.46008C20.2378 1.96743 19.0235 0.753418 17.5311 0.753418ZM19.0842 12.1955C19.0842 13.0519 18.3875 13.7487 17.5311 13.7487H8.58224L4.40092 16.6583V13.7487H3.25695C2.40055 13.7487 1.70386 13.0519 1.70386 12.1956V3.46008C1.70386 2.60358 2.40055 1.90698 3.25695 1.90698H17.5311C18.3875 1.90698 19.0842 2.60358 19.0842 3.46008V12.1955Z"
          fill="#2B3674"
        />
        <path
          d="M5.81934 4.82959H14.9683V5.98315H5.81934V4.82959ZM5.81934 7.29053H14.9683V8.44409H5.81934V7.29053ZM5.81934 9.75146H14.9683V10.905H5.81934V9.75146Z"
          fill="#2B3674"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_6722">
          <rect width="21" height="21" fill="white" transform="translate(0.550293 0.78418)" />
        </clipPath>
      </defs>
    </svg>
  );
};

const mapStateToPros = (state) => {
  return {
    client: state.Client.client.details,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(FoodMoodJournalData);
