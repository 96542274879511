import React, {useState, useEffect} from "react";
import { Button, Modal, Form, Tab, Nav } from "react-bootstrap";
import GeneralNotes from "./Components/GeneralNotes";
import SpecificNotes from "./Components/SpecificNotes";

const AddNotesPopup = ({
  showPopup,
  setShowPopup,
  closePopup,
  question,
  notes,
  saveNotes
}) => {
  const initialValues = {
    general_notes: "",
    specific_notes: {
      "Indicated Nutrients": "",
      "Food Support Sources": "",
      "Targeted Supplements": "",
      "Lifestyle Support Ideas": "",
      "Foundational Connections": "",
      "Related Client Concern(s)": ""
    }
  }
  const [isSaving, setSaving] = useState(false);
  const [notesData, setNotesData] = useState(initialValues);

  useEffect(() => {
    if(notes) {
      setNotesData({
        general_notes: notes.general_notes || "",
        specific_notes: {
          "Indicated Nutrients": (notes.specific_notes && notes.specific_notes["Indicated Nutrients"]) || "",
          "Food Support Sources": (notes.specific_notes && notes.specific_notes["Food Support Sources"]) || "",
          "Targeted Supplements": (notes.specific_notes && notes.specific_notes["Targeted Supplements"]) || "",
          "Lifestyle Support Ideas": (notes.specific_notes && notes.specific_notes["Lifestyle Support Ideas"]) || "",
          "Foundational Connections": (notes.specific_notes && notes.specific_notes["Foundational Connections"]) || "",
          "Related Client Concern(s)": (notes.specific_notes && notes.specific_notes["Related Client Concern(s)"]) || "",
        }
      })
    }
  }, [notes])

  const handleChangeNotes = (type, value) => {
    setNotesData(prevNotes => ({
      ...prevNotes,
      [type]: value
    }))
  }

  const handleClickSave = async () => {
    try {
      setSaving(true);
      await saveNotes(notesData, question);
      setSaving(false);
      handleClose();
    } catch(err) {
      console.log("error saving notes", err);
      setSaving(false);
    }
  }

  const handleClose = () => {
    setNotesData(initialValues);
    closePopup();
  }

  return (
    <>
      <Form>
        <Modal
          show={showPopup}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          className="FormPop"
        >
          <Modal.Header closeButton>
            <Modal.Title>{question?.question}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="py-2">
              <Tab.Container id="left-tabs-example" defaultActiveKey="general-notes">
                <div className="top ">
                  <Nav variant="pills" className="commonBtnTabs">
                    <Nav.Item className="px-2">
                      <Nav.Link
                        className="fw-sbold text-uppercase py-2"
                        eventKey="general-notes"
                      >
                        General Notes
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="px-2">
                      <Nav.Link
                        className="fw-sbold text-uppercase py-2"
                        eventKey="specific-notes"
                      >
                        Specific Notes
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="CardBody p-3">
                  <Tab.Content>
                    <Tab.Pane eventKey="general-notes">
                      <GeneralNotes
                        notes={notesData.general_notes}
                        setNotesValue={(value) => {
                          handleChangeNotes("general_notes", value)
                        }}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="specific-notes">
                      <SpecificNotes
                        notes={notesData.specific_notes}
                        setNotesValue={(value) => {
                          handleChangeNotes("specific_notes", value)
                        }}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                  
                </div>
              </Tab.Container>
            </div>
          </Modal.Body>
          
          <Modal.Footer className="FormbtnWrpper d-flex justify-content-end">
            <>
              <div className="d-flex gap-10">
                <Button
                  className="d-flex align-items-center justify-content-center commonBtn btnSm"
                  disabled={isSaving}
                  onClick={handleClickSave}
                >
                  {isSaving ? "Please wait..." : "Save"}
                </Button>
              </div>
              <div>
                <Button
                  className="d-flex align-items-center justify-content-center btn-danger btnSm"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </>
          </Modal.Footer>
        </Modal>
      </Form >
      
    </>
  );
};

export default AddNotesPopup;