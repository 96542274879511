import { useEffect, useState } from "react";
import { getBodySymptomBurdenGraphV1, getCompletedTodoListV1, getDataAnalysisGraphsV1 } from "store/services/clientDataReportsService";
import { LineChart } from "../../AccordionTabs/NutritionalAssessmentV1/Component/NAQBodySymptomsGraph/Components/LineChart";
import { LineChart as LineChartComparision } from "pages/SideTabs/MyClients/Detail/component/TabContent/DataAnalysis/NAQv1/Component/TabContent/NAQGraph/Components/LineChart";

export const LineChartPDF = ({ innerRef, todoId, client, setLoading }) => {
  const [bodySymptomBurdenGraphData, setBodySymptomBurdenGraphData] = useState(null);

  useEffect(() => {
    if (!bodySymptomBurdenGraphData) {
      getBodySymptomBurdenGraphData();
    }
  }, []);

  const getBodySymptomBurdenGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      setLoading(true);
      let data = {
        todoId,
        clientId: client?.id
      };
      const response = await getBodySymptomBurdenGraphV1(data);
      if (response.success === true) {
        const data = response.body;
        setBodySymptomBurdenGraphData(data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      <LineChart
        ref={innerRef}
        chartData={bodySymptomBurdenGraphData}
        height={150}
        screenSize={"large"}
      />
    </>
  )
}

export const ComparisonLineChartPDF = ({ innerRef, selectedNAQs, client, setLoading }) => {
  const [naqList, setNaqList] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if(!naqList) {
      getCompletedNAQList();
    }
  }, [])

  useEffect(() => {
    if(selectedNAQs && selectedNAQs.length > 0){
      getNAQGraphData(selectedNAQs);
    }
  }, [selectedNAQs])

  // useEffect(() => {
  //   let todoIds = naqList && naqList.slice(0, 2).map(naq => naq.id);
  //   if(todoIds) {
  //     getNAQGraphData(todoIds);
  //   }
  // }, [naqList]);

  const getCompletedNAQList = async () => {
    try {
      let data = {
        clientId: client.id
      }
      setLoading(true);
      const response = await getCompletedTodoListV1(data);
      if( response.success === true && response.body.length > 0) {
        setNaqList(response.body)
      }
      setLoading(false);
    } catch(error) {
      console.log("Error getting graph data", error);
      setLoading(false);
    }
  }

  const getNAQGraphData = async (todoIds) => {
    try {
      let data = {
        clientId: client.id,
        values: {
          todoIds
        }
      } 
      setLoading(true);
      const response = await getDataAnalysisGraphsV1(data);
      setLoading(false);
      if( response.success === true && response.body.length > 0) {
        setChartData(response.body);
      }
    } catch(error) {
      console.log("Error getting graph data", error);
      setLoading(false);
    }
  }

  const getNAQColor = (naqId) => {
    let naq = naqList?.find(naq => naq.id === naqId);
    return naq && naq.color;
  }

  return (
    <>
      <LineChartComparision
        innerRef={innerRef}
        chartData={chartData}
        height={150}
        screenSize={"large"}
        selectedNAQs={[]}
        getColor={getNAQColor}
      />
    </>
  )
}
