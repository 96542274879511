import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import RecommendationListing from "./Component/RecommendationListing";
import NewRecomendationPop from "./Component/Modal/NewRecomendationPop";
import NewRecommendationWizard from "./Component/NewRecommendationWizard/Index";
import { addClientRecommendation, createClientToDoItem, deleteClientActionPlan, deleteRecommendation, getClientActionPlansList, getClientRecommendationReportsList, getRecommendationDetails, getRecommendationTemplateList, toggleActionPlanPublish } from "store/services/clientService";
import { getFullscriptClientRecommendations, getFullscriptRecommendationDetails } from "store/services/fullscriptService";
import { FULL_NAME, UC_FIRST } from "helpers/common";
import SubmittedRecommendations from "./Component/SubmittedRecommendations/Index";
import ActiveActionPlans from "./Component/ActiveActionPlans/Index";
import FullscriptRecommendations from "./Component/FullscriptRecommendations/Index";
import FullscriptWidget from "./Component/FullscriptWidget/Index";
import * as actions from "store/actions";

// icn
import { ReactComponent as PlusIcn } from "../../../../../../../Assets/Images/icn/plus.svg";
import { ReactComponent as FSIcn } from "../../../../../../../Assets/Images/icn/fullScript.svg";

import { getIntegrationStatus } from "store/services/accountSettingService";
import { CONFIRM_POPUP, SUCCESS_MESSAGE } from "common/constantMessage";
import { ACTION_PLAN_FORM_ID, HEALTH_COATCH_CAT_ID, PRACTITIONER_CAT_ID, RECOMMENDATION_FORM_ID } from "common/constants";
import ActionPlansListing from "./Component/ActionPlansListing";
import { getClientRecommendationDetails, setActiveTab } from "store/actions/client";


const RecommendationTab = ({
  dispatch,
  client,
  clientCategory,
  activeRecommendations,
  pendingRecommendations,
  recommendationTemplates,
  fullscriptConnection,
  activeActionPlans,
  pendingActionPlans,
  activeTab,
}) => {
  const [recommendationPop, setRecommendationPop] = useState();
  const [currentRecommendationId, setCurrentRecommendationId] = useState(null);
  const [isLoadingRecommendationData, setLoadingRecommendationData] = useState(null);
  const { clientId } = useParams();

  const itemName = (clientCategory === HEALTH_COATCH_CAT_ID) ? "Action Plan" : "Recommendation";

  const fullscriptWidgetRef = useRef(null);

  useEffect(() => {
    if(clientCategory === PRACTITIONER_CAT_ID) {
      getThirdPatyIntegrationStatus();
      getClientRecommendations();
      getRecommendationTemplates();
    }
    if(clientCategory === HEALTH_COATCH_CAT_ID) {
      getClientActionPlans();
    }
  }, [activeTab, clientCategory]);

  const toggleRecommendationPopup = () => {
    setRecommendationPop(!recommendationPop);
  };

  const getClientRecommendations = async () => {
    try {
      let data = {
        clientId
      }
      dispatch(actions.persist_store({ loader: true }));
      // await dispatch(getClientRecommendationList(data));
      await dispatch(getClientRecommendationReportsList(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch(error) {
      console.log("Error fetching recommendations", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const createNewRecommendation = async () => {
    try {
      let data = {
        clientId: clientId,
        values: {
          forms: [RECOMMENDATION_FORM_ID],
          todo_owner: "pract"
        },
      };
      dispatch(actions.persist_store({ loader: true }));
      let response = await dispatch(createClientToDoItem(data));
      const { body } = response;
      const createdData = body?.length > 0 && body[0];
      if (createdData && typeof createdData.id !== "undefined") {
        let recommTodoId = createdData?.id;
        setCurrentRecommendationId(recommTodoId);
        editRecommendation(recommTodoId);
        // setShowSelectFormScreen(false);
        // setCurrentToDoId(body.id);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("error", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  // const createNewRecommendation = async () => {
  //   try {
  //     let data = {
  //       clientId,
  //       values: {
          
  //       }
  //     }
  //     dispatch(actions.persist_store({ loader: true }));
  //     let response = await dispatch(addClientRecommendation(data));
  //     dispatch(getClientRecommendationDetails(response));
  //     if(response?.success === true && response?.body) {
  //       let recommTodoId = response?.body?.id;
  //       setRecommendationPop(false);
  //       setCurrentRecommendationId(recommTodoId);
  //       editRecommendation(recommTodoId);
  //       // setShowRecommendationForm(true);
  //     }
  //     dispatch(actions.persist_store({ loader: false }));
  //   } catch(error) {
  //     console.log("Error fetching recommendations", error);
  //     dispatch(actions.persist_store({ loader: false }));
  //   }
  // }

  const getRecommendationTemplates = async () => {
    try {
      await dispatch(getRecommendationTemplateList());
    } catch(error) {
      console.log("Error fetching recommendations", error);
    }
  }

  const createNewRecommendationFromTemplate = async (templateId, callback) => {
    if(templateId) {
      try {
        let data = {
          clientId,
          values: {
            template_id: templateId
          }
        }
        
        await dispatch(addClientRecommendation(data, callback));
        await dispatch(getClientRecommendationReportsList({clientId}));
      } catch(error) {
        throw error;
      }
    }
  }

  const removeRecommendation = async (id) => {
    try {
      await CONFIRM_POPUP()
      .then(async (isConfirm) => {
        if(isConfirm) {
          let data = {
            id
          }
          const callback = (res) => {
            if(res.success == true) {
              SUCCESS_MESSAGE("Recommendation deleted");
            }
          }
          dispatch(actions.persist_store({ loader: true }));
          await dispatch(deleteRecommendation(data, callback));
          await dispatch(getClientRecommendationReportsList({clientId}));
          dispatch(actions.persist_store({ loader: false }));
        }
      })
      .catch(err => {
        console.log("Error deleting  recommendation", err);
        dispatch(actions.persist_store({ loader: false }));
        throw err;
      })
      
    } catch(err) {
      console.log("Error deleting recommendation", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const createFullscriptRecommendation = () => {
    if(fullscriptWidgetRef.current) {
      fullscriptWidgetRef.current.mountWidget();
      setRecommendationPop(false);
    }
  }

  const getFullscriptRecommendations = async () => {
    try {
      let data = {
        clientId,
      }
      dispatch(actions.persist_store({ loader: true }));
      await dispatch(getFullscriptClientRecommendations(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch(error) {
      console.log("Error fetching fullscript recommendations", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const fullscriptRecommendationDetails = async (id) => {
    try {
      let data = {
        id,
      }
      let res = await getFullscriptRecommendationDetails(data);
      return res;
    } catch(error) {
      console.log("Error fetching fullscript recommendations", error);
      throw error;
    }
  }

  const closeRecommendationForm = async () => {
    getClientRecommendations();
  }

  const getThirdPatyIntegrationStatus = async () => {
    try {
      await dispatch(getIntegrationStatus());
    } catch(err) {
      console.log("Error fetching third party integration status");
    }
  }

  const getClientActionPlans = async () => {
    try {
      let data = {
        clientId
      }
      dispatch(actions.persist_store({ loader: true }));
      // await dispatch(getClientRecommendationList(data));
      await dispatch(getClientActionPlansList(data));
      dispatch(actions.persist_store({ loader: false }));
    } catch(error) {
      console.log("Error fetching recommendations", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const createNewActionPlan = async () => {
    try {
      let data = {
        clientId: clientId,
        values: {
          forms: [ACTION_PLAN_FORM_ID],
          todo_owner: "pract"
        },
      };
      dispatch(actions.persist_store({ loader: true }));
      let response = await dispatch(createClientToDoItem(data));
      const { body } = response;
      if (body && typeof body.id !== "undefined") {
        editActionPlan(body.id);
        // setShowSelectFormScreen(false);
        // setCurrentToDoId(body.id);
      }
      dispatch(actions.persist_store({ loader: false }));
    } catch (error) {
      console.log("error", error);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const editActionPlan = (id) => {
    localStorage.setItem(`recently_edited_todo_${clientId}`, id);
    dispatch(setActiveTab("AddData"));
    setRecommendationPop(false);
  }

  const removeActionPlan = async (id) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await deleteClientActionPlan(id);
      await getClientActionPlans();
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error occurred while deleting action plan", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const togglePublishActionPlan = async (id) => {
    try {
      dispatch(actions.persist_store({ loader: true }));
      await toggleActionPlanPublish(id);
      await getClientActionPlans();
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      console.log("Error occurred while updating action plan status", err);
      dispatch(actions.persist_store({ loader: false }));
    }
  }

  const editRecommendation = (todoRecommId) => {
    localStorage.setItem(`recently_edited_todo_${clientId}`, todoRecommId);
    dispatch(setActiveTab("AddData"));
    setRecommendationPop(false);
  }

  return (
    <>
      <NewRecomendationPop
        recommendationPop={recommendationPop}
        setRecommendationPop={setRecommendationPop}
        handleNewRecommendatition={createNewRecommendation}
        handleNewActionPlan={createNewActionPlan}
        newRecommendationFromTemplate={createNewRecommendationFromTemplate}
        recommendationTemplates={recommendationTemplates}
        viewRecommendation={editRecommendation}
        handleCreateFullscriptRecomm={createFullscriptRecommendation}
        fullscriptConnection={fullscriptConnection}
        clientCategory={clientCategory}
        itemName={itemName}
      />
      {fullscriptConnection && clientCategory === PRACTITIONER_CAT_ID &&
        <div className="py-2">
          <div
            className="commonCardBox p-lg-4 p-3"
            style={{ borderColor: "#548B54", backgroundColor: "#FCFFFC" }}
          >
            <div className="content">
              <FullscriptWidget
                ref={fullscriptWidgetRef}
                onCreateRecommendation={getFullscriptRecommendations}
              />
            </div>
          </div>
        </div>
      }
      <div className="py-2">
        <div className="top d-flex align-items-start justify-content-between gap-10">
          <div className="left">
            <h6 className="m-0 fw-bold">
              {itemName}s you're Currently Entering or Editing
            </h6>
            <p className="m-0">
              Below are your in progress forms that you're currently entering or
              editing. These are forms that you (and not your client) are
              filling out or editing.
            </p>
          </div>
        </div>
        <div className="commonCardBox my-3">
          {clientCategory === PRACTITIONER_CAT_ID && (
            <RecommendationListing
              recommendations={pendingRecommendations}
              onClickOpen={editRecommendation}
              removeRecommendation={removeRecommendation}
            />
          )}

          {clientCategory === HEALTH_COATCH_CAT_ID && (
            <ActionPlansListing
              actionPlans={pendingActionPlans}
              onClickOpen={editActionPlan}
              removeActionPlan={removeActionPlan}
            />
          )}
        </div>
      </div>
      <div className="py-2">
        <div className="top d-flex align-items-start justify-content-between gap-10">
          <div className="left">
            {/* <h6 className="m-0 fw-bold">{FULL_NAME(client.first_name, client.last_name)}'s To-Do Items</h6> */}
            <p className="m-0">
              Below are all your {itemName}s for {FULL_NAME(client.first_name, client.last_name)}.
            </p>
          </div>
          <div className="right">
            <Button
              onClick={toggleRecommendationPopup}
              className="d-flex align-items-center justify-content-center commonBtn btnSm"
            >
              <span className="icn me-2">
                <PlusIcn />
              </span>
              New {itemName} for {UC_FIRST(client.first_name)}
            </Button>
          </div>
        </div>
        {fullscriptConnection &&
          <FullscriptRecommendations
            getRecommendationDetails={fullscriptRecommendationDetails}
            getFullscriptRecommendations={getFullscriptRecommendations}
          />
        }
        <SubmittedRecommendations 
          handleEditRecommendation={editRecommendation}
        />
        <ActiveActionPlans
          actionPlans={activeActionPlans}
          handleEdit={editActionPlan}
          togglePublish={togglePublishActionPlan}
        />
      </div>
      {/* <p className="m-0 py-2 text-dark">
        *Looking for all the NAQ questions/answers your client submitted? All of
        the NAQ data is under the{" "}
        <Link to="" className="underline">
          {" "}
          Data & To-Do Items
        </Link>{" "}
        tab.
      </p> */}
    </>
  )
};

const mapStateToPros = (state) => {
  // let pendingRecommendations = state.Client?.client?.recommendationReports?.customRecList?.filter(rec => rec.status !== "active");
  let fullscriptConnection = state.AccountSetting.integrations.find(integration => integration.connection_type == "full_script");

  // client action plans
  let activeActionPlans = state.Client.client.actionPlans.filter(plan => plan.status == "completed");
  let pendingActionPlans = state.Client.client.actionPlans.filter(plan => plan.status != "completed");
  return {
    activeTab: state.Client.activeTab,
    pendingRecommendations: state.Client?.client?.recommendationReports?.pending_recommendation || [], 
    client: state.Client.client.details,
    recommendationTemplates: state.Client.recommendationTemplates,
    fullscriptConnection: fullscriptConnection,
    clientCategory: state.Client.client?.details?.client_category,
    activeActionPlans,
    pendingActionPlans
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(RecommendationTab);