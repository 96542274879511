import { CURRENT_PAGE_TITLE, GETTING_STARTED_PROGRESS, GET_BASIC_SETTINGS, GET_DASHBOARD_CONTENT } from "store/actions/misc";

const initialState = {
  isAuthenticated: false,
  dashboardData: {},
  stepsProgress: {},
  currentPage: {},
  basicSettings: {},
  loader:false,
};

//upload image
const getDashboardContent = (state, payload) => {
    const { data, callback } = payload;
    if (typeof callback === 'function') {
      callback(data);
    }
    const stateObj = {
      ...state,
      isAuthenticated: true,
      dashboardData: data.body,
    };
    return stateObj;
  };


// get progress
const gettingStartedProgress = (state, payload) => {
  const { data, callback } = payload;
  if (typeof callback === 'function') {
    callback(data);
  }
  const stateObj = {
    ...state,
    stepsProgress: data.body,
  };
  return stateObj;
};

// set current page title
const currentPageTitle = (state, payload) => {
  const stateObj = {
    ...state,
    currentPage: {
      ...state.currentPage,
      title: payload
    },
  };
  return stateObj;
}

// set basic settings state
const getBasicSettings = (state, payload) => {
  const { data, callback } = payload;
  const stateObj = {
    ...state,
    basicSettings: data?.body,
  };
  return stateObj;
}

const Misc = (state = initialState, { type, payload = null }) => {
    switch (type) {
        case GET_DASHBOARD_CONTENT:
          return getDashboardContent(state, payload);

        case GETTING_STARTED_PROGRESS: 
          return gettingStartedProgress(state, payload);

        case CURRENT_PAGE_TITLE:
          return currentPageTitle(state, payload);

        case GET_BASIC_SETTINGS:
          return getBasicSettings(state, payload);

        default:
        return state;
    };
}
export default Misc;
