import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Accordion,
  Button,
} from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import ReactDOMServer from "react-dom/server";

import { downloadRecommReport, sendRecommToClient } from "store/services/clientService";
import * as actions from "store/actions";

// icons
import { ReactComponent as CopyIcn } from "Assets/Images/icn/Copy.svg";
import { ReactComponent as EditIcon } from "Assets/Images/icn/pencil.svg";
import DownloadRecommendationPDF from "components/shared/PDFComponents/Recommendations/RecommendationReportPDF";
import RecommendationBlobProvider from "components/shared/PDFComponents/Recommendations/RecommReportBlobProvider";
import ConfirmSendRecommPopup from "../../Modal/ConfirmSendRecommPopup";
import GenericBSModal from "components/shared/GenericBSModal";
import { UC_FIRST } from "helpers/common";
import { decode } from "html-entities";

const SingleRecommendation = ({
  dispatch,
  recommendation,
  toggleDuplicatePopup,
  getDetails,
  handleEditRecommendation,
  togglePublishRecommendation,
  setCurrentRecommId,
  sendEmailCallback,
  client
}) => {
  const [recommendationDetails, setRecommendationDetails] = useState(null);
  const [isLoading, setLoading] = useState(false)
  const [isOpen, setOpen] = useState(false);
  const [rows, setRows] = useState({});
  const [additionalRecommendation, setAdditionalRecommendation] = useState([]);
  const [showConfirmSendPopup, setShowconfirmSendPoup] = useState(false);
  const [showRecommSentPopup, setShowRecommSentPopup] = useState(false);

  const nutriTableRef = useRef(null);

  useEffect(() => {
    if (recommendationDetails) {
      let rows = recommendationDetails.recommendation;
      let additional_recommendation = recommendationDetails.additional_recommendation;
      setRows(rows);
      setAdditionalRecommendation(additional_recommendation);
    }
  }, [recommendationDetails])

  useEffect(() => {
    if (isOpen) {
      fetchRecommendationDetails();
    }
  }, [isOpen])

  const fetchRecommendationDetails = async () => {
    try {
      if (!recommendation.todo_id) {
        throw new Error("Invalid recommendation ID");
      }
      setLoading(true);
      let response = await getDetails(recommendation.todo_id);
      if (response.success === true && response.body) {
        setRecommendationDetails(response.body);
      }
      setLoading(false);
    } catch (err) {
      console.log("Error occurred while fetching recommendation details", err);
      setLoading(false);
    }
  }

  const recommEmailSentCallback = () => {
    setShowconfirmSendPoup(false);
    setShowRecommSentPopup(true);
  }

  if (!recommendation) {
    return "";
  }

  return (
    <>
      <ConfirmSendRecommPopup
        showPopup={showConfirmSendPopup}
        handleClose={() => {
          setShowconfirmSendPoup(false);
        }}
        client={client}
        SendRecommendationLink={
          <RecommendationBlobProvider
            callback={(pdfContents) => {
              sendEmailCallback(recommendation.todo_id, pdfContents, recommEmailSentCallback)
            }}
            nutritionalRows={rows.nutritional}
            lifestyleRows={rows.lifestyle}
            details={recommendationDetails}
            additionalDetails={additionalRecommendation}
            client={client}
            date={recommendation.created_at}
          />
        }
      />
      <GenericBSModal
        showPopup={showRecommSentPopup}
        handleClose={() => {
          setShowRecommSentPopup(false);
        }}
        title={"Success!"}
        content={`We successfully sent your Recommendation to ${UC_FIRST(client?.first_name)}!`}
      />
      <Accordion.Item eventKey={recommendation.todo_id} className="recommendation-report border-0 my-2">
        <Accordion.Header
          className=""
          style={{
            background: "#FFFDFA",
            border: "1px solid #405C40",
            color: "#405C40",
          }}
          onClick={() => {
            setOpen(true);
            setCurrentRecommId(recommendation.todo_id);
          }}
        >
          <span className="toggleBtn position-absolute"></span>
          <div className="d-flex align-items-center gap-10">
            <span className="icn me-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
              >
                <path
                  d="M0.533325 11.8246C0.533325 10.5925 1.53211 9.59375 2.76418 9.59375C3.99624 9.59375 4.99503 10.5925 4.99503 11.8246V17.0503H0.533325V11.8246Z"
                  fill="currentColor"
                />
                <path
                  d="M6.13538 2.8246C6.13538 1.59254 7.13416 0.59375 8.36623 0.59375C9.59829 0.59375 10.5971 1.59254 10.5971 2.8246V17.0503H6.13538V2.8246Z"
                  fill="currentColor"
                />
                <path
                  d="M11.7371 7.8246C11.7371 6.59254 12.7358 5.59375 13.9679 5.59375C15.2 5.59375 16.1988 6.59254 16.1988 7.8246V17.0503H11.7371V7.8246Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            Recommendation
            {recommendation.for_client === "publish" && ` (Published)`}
          </div>
          <div className="right d-flex align-items-center gap-10">
            <p className="m-0 text-dark text-lowercase fw-sbold">
              {`from ${moment(recommendation.created_at).format("MMM DD, YYYY")}`}
            </p>
            {/* <span className="icn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_0_3353)">
                              <path
                                d="M14.5024 2.16927C16.2504 2.16927 17.6719 3.58952 17.6719 5.33594V14.8359C17.6719 16.5824 16.2504 18.0026 14.5024 18.0026H4.99379C3.2458 18.0026 1.82427 16.5824 1.82427 14.8359V5.33594C1.82427 3.58952 3.2458 2.16927 4.99379 2.16927H14.5024ZM14.5024 0.585938H4.99379C3.73288 0.585938 2.52361 1.08638 1.632 1.97718C0.740399 2.86798 0.239502 4.07616 0.239502 5.33594L0.239502 14.8359C0.239502 16.0957 0.740399 17.3039 1.632 18.1947C2.52361 19.0855 3.73288 19.5859 4.99379 19.5859H14.5024C15.7633 19.5859 16.9726 19.0855 17.8642 18.1947C18.7558 17.3039 19.2567 16.0957 19.2567 14.8359V5.33594C19.2567 4.07616 18.7558 2.86798 17.8642 1.97718C16.9726 1.08638 15.7633 0.585938 14.5024 0.585938Z"
                                fill="black"
                              />
                              <path
                                d="M4.99377 10.0846C4.99377 9.87467 5.07726 9.67331 5.22586 9.52484C5.37446 9.37638 5.576 9.29297 5.78616 9.29297H13.71C13.9201 9.29297 14.1217 9.37638 14.2703 9.52484C14.4189 9.67331 14.5024 9.87467 14.5024 10.0846C14.5024 10.2946 14.4189 10.496 14.2703 10.6444C14.1217 10.7929 13.9201 10.8763 13.71 10.8763H5.78616C5.576 10.8763 5.37446 10.7929 5.22586 10.6444C5.07726 10.496 4.99377 10.2946 4.99377 10.0846Z"
                                fill="black"
                              />
                              <path
                                d="M9.74821 14.8398C9.53825 14.8398 9.33688 14.7564 9.18842 14.6078C9.03995 14.4592 8.95654 14.2576 8.95654 14.0475L8.95654 6.12364C8.95654 5.91349 9.03995 5.71194 9.18842 5.56334C9.33688 5.41474 9.53825 5.33126 9.74821 5.33126C9.95817 5.33126 10.1595 5.41474 10.308 5.56334C10.4565 5.71194 10.5399 5.91349 10.5399 6.12364L10.5399 14.0475C10.5399 14.2576 10.4565 14.4592 10.308 14.6078C10.1595 14.7564 9.95817 14.8398 9.74821 14.8398Z"
                                fill="black"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_0_3353">
                                <rect
                                  width="19.0172"
                                  height="19"
                                  fill="white"
                                  transform="translate(0.239502 0.585938)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span> */}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {isLoading ?
            <p>Loading...</p>
            :
            <div className="py-2 AccordionInner">
              <div className="inner">
                {recommendation.for_client === "publish" &&
                  <p>Published at {moment(recommendation.created_at).format("MMM DD, YYYY")}</p>
                }
                <div className="top d-flex align-items-start justify-content-between gap-10">
                  <div className="left">
                    <h6 className="m-0 fw-bold py-1 mt-2 text-uppercase">Recommendation Data</h6>
                    <h6 className="m-0 fw-bold py-1 mt-2 text-uppercase">
                      Visit - #{recommendationDetails?.visit_number}
                    </h6>
                  </div>
                  <div className="right">
                    <div className="d-flex gap-10">
                      <Button
                        onClick={() => {
                          handleEditRecommendation(recommendation.todo_id)
                        }}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        <span className="icn me-2">
                          <EditIcon />
                        </span>
                        Edit Form
                      </Button>
                      {client && (client?.last_password_updated === null || client?.is_direct_account === "yes") && (
                        <Button
                          onClick={() => {
                            setShowconfirmSendPoup(true)
                          }}
                          className="d-flex align-items-center justify-content-center commonBtn btnSm"
                        >
                          <span className="icn me-2">
                            <i className="fa fa-envelope"></i>
                          </span>
                          Email to Client
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          togglePublishRecommendation(recommendation.todo_id, recommendation.for_client);
                        }}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        <span className="icn me-2">
                          <CopyIcn />
                        </span>
                        {recommendation.for_client === "publish" ? "Unpublish" : "Publish"}
                      </Button>
                      <Button
                        onClick={toggleDuplicatePopup}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        <span className="icn me-2">
                          <CopyIcn />
                        </span>
                        Duplicate
                      </Button>
                      {/* <Button
                        onClick={() => {
                          downloadReport(rows.nutritional)
                        }}
                        className="d-flex align-items-center justify-content-center commonBtn btnSm"
                      >
                        <span className="icn me-2">
                          <DownloadIcon />
                        </span>
                        Download Report
                      </Button> */}
                      {recommendationDetails &&
                        <DownloadRecommendationPDF
                          nutritionalRows={rows.nutritional}
                          lifestyleRows={rows.lifestyle}
                          supplementRows={rows.supplement}
                          details={recommendationDetails}
                          additionalDetails={additionalRecommendation}
                          client={client}
                          date={recommendation.created_at}
                        />
                      }
                    </div>
                  </div>
                </div>

                {/* Nutritional Recommendation */}
                {rows.nutritional && rows.nutritional.length > 0 &&
                  <div className="py-2">
                    <div className="py-2">
                      <div className="commonContent">
                        <h3 className="m-0 fw-sbold py-1 text-uppercase">
                          Nutritional Recommendations
                        </h3>
                      </div>
                    </div>
                    <div className="commonCardBox">
                      <div className="table-responsive">
                        <table ref={nutriTableRef} className="table table-striped">
                          <thead>
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Nutritional Recommendation
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Benefit / Instructions / Notes
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Per Day
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Upon Waking
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Breakfast
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Mid-morning
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Lunch
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Mid-afternoon
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Dinner
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Before Bed
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <TableRows rows={rows.nutritional} />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }
                {additionalRecommendation.nutritional &&
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Additional Nutritional Recommendations
                      </h3>
                      <p className="m-0 py-1 is-multiline">
                        {additionalRecommendation.nutritional}
                      </p>
                    </div>
                  </div>
                }

                {/* Lifestyle Recommendation */}
                {rows.lifestyle && rows.lifestyle.length > 0 &&
                  <div className="py-2">
                    <div className="py-2">
                      <div className="commonContent">
                        <h3 className="m-0 fw-sbold py-1 text-uppercase">
                          Lifestyle Recommendations
                        </h3>
                      </div>
                    </div>
                    <div className="commonCardBox">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Lifestyle Recommendation
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Benefit / Instructions / Notes
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Per Day
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Upon Waking
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Breakfast
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Mid-morning
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Lunch
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Mid-afternoon
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Dinner
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Before Bed
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <TableRows rows={rows.lifestyle} />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }
                {additionalRecommendation && additionalRecommendation.lifestyle &&
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Additional Lifestyle Recommendations
                      </h3>
                      <p className="m-0 py-1 is-multiline">
                        {additionalRecommendation.lifestyle}
                      </p>
                    </div>
                  </div>
                }

                {/* Supplement Recommendations */}
                {rows.supplement && rows.supplement.length > 0 &&
                  <div className="py-2">
                    <div className="py-2">
                      <div className="commonContent">
                        <h3 className="m-0 fw-sbold py-1 text-uppercase">
                          Supplement Recommendations
                        </h3>
                      </div>
                    </div>
                    <div className="commonCardBox">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Supplement Recommendation
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold">
                                Benefit / Instructions / Notes
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Per Day
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Upon Waking
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Breakfast
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Mid-morning
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Lunch
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Mid-afternoon
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                With Dinner
                              </th>
                              <th className="p-3 fw-normal text-dark fw-sbold thDenotion">
                                Before Bed
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <TableRows rows={rows.supplement} />
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                }
                {additionalRecommendation && additionalRecommendation.supplement &&
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Additional Supplement Recommendations
                      </h3>
                      <p className="m-0 py-1 is-multiline">
                        {additionalRecommendation.supplement}
                      </p>
                    </div>
                  </div>
                }

                {/* Client Goals */}
                {recommendationDetails && recommendationDetails.client_goals &&
                  <div className="py-2">
                    <div className="commonContent">
                      <h3 className="m-0 fw-sbold py-1 text-uppercase">
                        Client Goals
                      </h3>
                      <p className="m-0 py-1 is-multiline">
                        {recommendationDetails && recommendationDetails.client_goals}
                      </p>
                    </div>
                  </div>
                }
              </div>
            </div>
          }

        </Accordion.Body>
      </Accordion.Item>
    </>
  )
}

const TableRows = ({ rows }) => {
  return (
    <>
      {rows && rows.length > 0 &&
        rows.map((row, index) => {
          return (
            <tr key={index}>
              <td className="p-3 border-0">
                <p className="m-0 is-multiline">{decode(row.recommendation)}</p>
              </td>
              <td className="p-3 border-0 ws-normal">
                <p className="m-0 is-multiline">
                  {row.benefit}
                </p>
              </td>
              <td className="p-3 border-0">{row.per_day}</td>
              <td className="p-3 border-0">{row.upon_waking}</td>
              <td className="p-3 border-0">{row.with_breakfast}</td>
              <td className="p-3 border-0">{row.midmorning}</td>
              <td className="p-3 border-0">{row.with_lunch}</td>
              <td className="p-3 border-0">{row.midafternoon}</td>
              <td className="p-3 border-0">{row.with_dinner}</td>
              <td className="p-3 border-0">{row.before_bed}</td>
            </tr>
          )
        })
      }
    </>
  )
}

const mapStateToPros = (state) => {
  return {
    clients: state.Client.clients,
    client: state.Client.client.details,
  };
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(SingleRecommendation);