import { Button } from "react-bootstrap";
import { Document, Page, Text, StyleSheet, View, pdf } from '@react-pdf/renderer';
import { viewMyFormDetails } from "store/services/myFormService";
import { ReactComponent as DownloadIcn } from "../../../../../../Assets/Images/icn/download.svg";
import { useDispatch } from "react-redux";
import RadioButtons from "./Components/RadioButtons";
import InputBoxes from "./Components/InputBox";
import CheckBoxes from "./Components/CheckBoxes";
import { useShowLoader } from "hooks/useShowLoader";
import { ERROR_MESSAGE } from "common/constantMessage";
import { decode } from "html-entities";
import Disclaimer from "./Components/Disclaimer";
import Height from "./Components/Height";
import Weight from "./Components/Weight";
import DatePicker from "./Components/DatePicker";
import Address from "./Components/Address";
import Scale from "./Components/Scale";
import { saveAs } from "helpers/common";

const MyFormsPDF = ({ formID }) => {

  const dispatch = useDispatch();
  const showLoader = useShowLoader();
  const onDownloadFormData = async () => {
    try {
      showLoader(true);
      const res = await viewMyFormDetails(formID);
      console.log("response formdata", res);
      if(!res?.body?.pages?.length) {
        throw "No form pages to render";
      }
      const doc = <MyDoc data={res?.body} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${res?.body?.details?.form_name || "NTA-Form"}.pdf`);
      showLoader(false);
    } catch (err) {
      console.log("Error rendering form pdf", err);
      ERROR_MESSAGE("Something went wrong")
      showLoader(false);
    }

  }

  return (
    <Button
      onClick={() => {
        onDownloadFormData();
      }}
      variant="transparent"
      className="border-0 p-0"
    >
      <DownloadIcn />
    </Button>
  );
}

export const MyDoc = ({ data }) => {
  const formDetails = data?.details;
  const formTitle = formDetails?.form_name;
  const pages = data?.pages || [];

  if (!pages?.length) {
    return "";
  }
  return (
    <Document>
      <Page style={styles.page}>

        <View style={styles.pageHeader} fixed>
          <Text style={[styles.fontBold, { fontSize: "16px", color: "#08415c", maxWidth: "80%"  }]}>
            {formTitle}
          </Text>
          <Text style={[styles.fontBold, { fontSize: "20px", color: "#08415c" }]}>
            Nutri-Q
          </Text>
        </View>
        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: 10 }}  >
          <Text style={{ fontSize: 10, fontWeight: 400 }}>
            Name:_____________
          </Text>
          <Text style={{ fontSize: 10, fontWeight: 400 }}>
            Date:_____________
          </Text>
        </View>
        <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: 10, marginBottom: 10 }}  >
          <Text style={{ fontSize: 10, fontWeight: 400 }}>
            {decode(formDetails?.form_desc)}
          </Text>
        </View>
        {pages && pages.map((page, index) => {
          // if(!page?.questions.length) {
          //   return "";
          // }
          return (
            <View>
              {(page?.title && page?.questions?.length > 0) && (
                <Text style={[styles.fontBold, { fontSize: "13px", marginTop: "10px" }]}>
                  Page {index + 1} : {decode(page?.title)}
                </Text>
              )}
              <View style={{ marginTop: 5, marginBottom: 5 }}>
                {page?.questions?.map((question) => (
                  <QuestionFields question={question} />
                ))}
              </View>
            </View>
          )
        })}
      </Page>
    </Document>
  )
}

export const QuestionFields = ({ question }) => {

  const textFields = ["textTiny", 'textXShort', 'textShort', 'textLong', 'textXLong', 'textXXLong']
  return (
    <View>
      <View style={{ margin: "3px 0px 3px 0x" }}>
        <Text style={[styles.fontBold, { marginRight: 5 }]}>{decode(question?.question)} <Text style={[styles.fontNormal, { fontSize: 10, color: "#4c4c4d" }]}>({`${question?.is_required === "yes" ? "Required" : "Optional"}`})</Text></Text>
      </View>
      <View style={{ margin: "3px 0px 5px 0px" }}>
        {question?.question_type === "radioButtons" ? (
          <RadioButtons question={{ ...question }} />
        ):(question?.question_type === "checkBoxes") ? (
          <CheckBoxes question={{ ...question }} />
        ):(textFields.includes(question?.question_type)) ? (
          <InputBoxes question={{ ...question }} />
        ): question?.question_type === "checkBoxes" ? (
          <CheckBoxes question={question} />
        ) : question?.question_type == "ratingScale" ? (
          <Scale question={question} />
        ) : question?.question_type == "date" ? (
          <DatePicker question={question} />
        ) : question?.question_type == "weight" ? (
          <Weight question={question} />
        ) : question?.question_type == "disclaimer" ? (
          <Disclaimer question={question} />
        ) : question?.question_type == "height" ? (
          <Height question={question} />
        ) : question?.question_type == "address" ? (
          <Address question={question} />
        ) : (
          <TextArea question={question} /> // default
        )}
      </View>
    </View>
  )
  // if (question?.question_type === "radioButtons") {
  //   return (
  //     <View style={{ margin: "10px 0px 10px 0px" }}>
  //       <View style={{ margin: "3px 0px 3px 0px", flexDirection: "row", alignItems: "center", gap: "50px" }}>
  //         <Text style={[styles.fontBold, { marginRight: 50, flexWrap: "wrap", flexFlow: "column wrap" }]}>{question?.question}</Text>
  //         <Text style={{ fontSize: 10, color: "#4c4c4d" }}>{`${question?.is_required === "yes" ? "required" : "optional"}`}</Text>
  //       </View>
  //       <View style={{ margin: "3px 0px 5px 0px" }}>

  //         <RadioButtons question={{ ...question }} />

  //       </View>
  //     </View>
  //   )
  // } else if (question?.question_type === "checkBoxes") {
  //   return (<View style={{ margin: "10px 0px 10px 0px" }}>
  //     <View style={{ margin: "3px 0px 3px 0px" }}>
  //       <Text style={styles.fontBold}>{question?.question}</Text>
  //       {question?.is_required === "yes" ?
  //         <Text style={{ fontSize: 10, color: "#4c4c4d" }}>{`(required)`}</Text>
  //         : <Text style={{ fontSize: 10, color: "#4c4c4d" }}>{`(optional)`}</Text>
  //       }
  //     </View>
  //     <View style={{ margin: "3px 0px 5px 0px" }}>
  //       <CheckBoxes question={{ ...question }} />
  //     </View>
  //   </View>
  //   )
  // }
  // else if (textFields.includes(question?.question_type)) {
  //   return (
  //     <View>
  //       <View style={{ margin: "3px 0px 3px 0x", flexDirection: "row" }}>
  //         <Text style={[styles.fontBold, { marginRight: 5, flexWrap: "wrap", flexFlow: "row wrap", maxWidth: "90%" }]}>{question?.question}</Text>
  //         <Text style={{ fontSize: 10, color: "#4c4c4d" }}>({`${question?.is_required === "yes" ? "required" : "optional"}`})</Text>
  //       </View>
  //       <View style={{ margin: "3px 0px 5px 0px" }}>
  //         <InputBoxes question={{ ...question }} />
  //       </View>
  //     </View>
  //     )
  // }
  // else {
  //   return null;
  // }
}

const styles = StyleSheet.create({
  page: { backgroundColor: '#fff', flexDirection: "column", fontSize: "10px", padding: "20px" },
  pageHeader: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "5px",
    borderBottom: "2px soild lightgrey",
    lineHeight: "1.2px"
  },
  clientName: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "15px",
    backgroundColor: "#ddd",
    padding: "5px",
    borderTop: "2px solid #bbb",
  },
  tableContainer: {
    marginTop: "5px",
    paddingBottom: "15px",
    // borderBottom: "2px solid #ccc",
  },
  disclaimer: {
    fontSize: "8px",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  fontBold: {
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
  },
  fontNormal: {
    fontWeight: "normal",
    fontFamily: "Helvetica",
  }
});

export default MyFormsPDF;